import React, { useEffect } from "react";
import { useDisableBodyScroll } from "../hooks/useDisableBodyScroll";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const Modal = ({
  showModal,
  isClosable = true,
  dismiss,
  children,
  className,
  modalClassName,
}: {
  showModal: boolean;
  isClosable?: boolean;
  dismiss?: () => void;
  children: React.ReactNode;
  className?: string;
  modalClassName?: string;
}) => {
  useDisableBodyScroll(showModal);
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        isClosable && dismiss && dismiss();
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);
  const isMobile = useMediaQuery({ maxWidth: "1150px" });

  return (
    <div
      onClick={(e) => {
        e?.stopPropagation();
      }}
      className={classNames(
        "transition-colors inset-0",
        showModal ? "visible bg-black/25" : "invisible",
        isMobile ? "items-end" : "items-center",
        "flex justify-center overflow-hidden fixed inset-0 z-[52] outline-none focus:outline-none",
        modalClassName
      )}
    >
      {/*content*/}
      <div
        className={classNames(
          "transition-all",
          isMobile
            ? `rounded-t-lg transform ${
                showModal ? "translate-y-0" : "translate-y-full"
              }`
            : `rounded-2xl transform ${
                showModal ? "scale-100 opacity-100" : "scale-125 opacity-0"
              }`,
          "max-md:overflow-auto border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-[90dvh] max-w-[70dvw] max-md:max-w-full max-md:max-h-[90dvh] p-8 max-md:p-4",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
