export const generateCodeVerifierAndChallenge = async () => {
  function generateCodeVerifier() {
    const array = new Uint8Array(128);
    window.crypto.getRandomValues(array);
    return btoa(String.fromCharCode(...Array.from(array))) // Convert Uint8Array to Array
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  async function generateCodeChallenge(codeVerifier: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest("SHA-256", data);
    return btoa(String.fromCharCode(...Array.from(new Uint8Array(digest)))) // Convert Uint8Array to Array
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  return { codeVerifier, codeChallenge };
};
