import React from "react";
import PageTitle from "../../../components/PageTitle";
import Section from "../../../layouts/Section";
import InsidersTable from "./components/InsidersTable";
import AuthSelectors from "../../../modules/auth/selectors";
import NeedSubscription from "../components/NeedSubscription";
import usePermissionHandler from "../hooks/handleActionWithPermission";

const Insiders = () => {
  const { isAdmin } = AuthSelectors();
  const { hiddenElements } = usePermissionHandler();
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"User Management"}
          subTitle={"Manage your members and their mailboxes"}
        />
      </div>
      <div className="relative">
        {!hiddenElements.insidersTable ? (
          <NeedSubscription title="User Management" />
        ) : null}
        <InsidersTable isAdmin={isAdmin} />
      </div>
    </Section>
  );
};

export default Insiders;
