import { useAlertsContext } from "../../hooks/useAlertsContext";
import * as Actions from "./types";
import { GetSubscription } from "./service";

const PermissionActions = () => {
  const { toastError } = useAlertsContext();
  const fetchSubscriptionPermission = (cb = () => {}) => {
    return async (dispatch) => {
      await GetSubscription()
        .then((response) => {
          dispatch({
            type: Actions.SAVE_SUBSCRIPTIONS,
            payload: response?.data,
          });
          cb && cb();
        })
        .catch((e) => {
          console.error("fetchProfile", e);
          toastError(e?.data || "Error fetching profile");
        });
    };
  };
  const openModal = (message) => ({
    type: Actions.OPEN_MODAL,
    payload: { message },
  });

  const closeModal = () => ({
    type: Actions.CLOSE_MODAL,
  });
  return {
    fetchSubscriptionPermission,
    openModal,
    closeModal,
  };
};

export default PermissionActions;
