import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import AuthSelectors from "../../../modules/auth/selectors";
import { LogType } from "../../../utils/types";
const useSync = (isLoggedIn: boolean) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [progress, setProgress] = useState(0);
  const [logs, setLogs] = useState<LogType>(LogType.GettingProfileData);
  const [status, setStatus] = useState("idle");
  const [isVisible, setIsVisible] = useState(false);
  const { user } = AuthSelectors();
  useEffect(() => {
    if (isLoggedIn) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_HUB_ENDPOINT}/Hub/User?UserId=${user.id}`,
          {
            withCredentials: false, // Adjust based on your backend setup
          }
        )
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("Connected!", connection);
          // connection
          //   .invoke("connectionId")
          //   .then((id) => console.log("Connection ID:", id))
          //   .catch((e) => console.log("error get connection id"));
          connection.on("UserSyncStatus", (message: any) => {
            console.log("Received notification:", message);
            setIsVisible(message?.isSyncing);
            setLogs(message?.step);
          });
        })
        .catch((e: any) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  return {
    progress,
    logs,
    status,
    isVisible,
    setIsVisible,
  };
};
export default useSync;
