
import React from "react";
import { ProfileDT, TableParams } from "../../../../utils/types";
import { Pagination, PaginationProps, Table, TableProps } from "antd";
import Actions from "./Actions";
import { FormatHelper } from "../../../../utils/timezone";
import TypeInput from "./TypeInput";
import ActivityStatus from "./ActivityStatus";

type ColumnsType<T> = TableProps<T>["columns"];

const ProfilesTable = ({
  data,
  loading,
  tableParams,
  handleTableChange,
  handlePaginationChange,
  fetchPeople,
}: {
  data?: any[];
  loading: boolean;
  tableParams: TableParams;
  handleTableChange: any;
  handlePaginationChange: any;
  fetchPeople: Function;
}) => {
  const { pagination } = tableParams;

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    handlePaginationChange(page, pageSize);
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "index",
      render: (_, record, index) =>
        ((pagination?.current || 1) - 1) * (pagination?.pageSize || 10) +
        index +
        1,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_: any, record: ProfileDT) => (
        <TypeInput domainId={record.id} type={record?.type} />
      ),
    },
    {
      title: "Last Active",
      dataIndex: "lastActivity",
      sorter: true,
      render: (_: any, record: ProfileDT) => (
        <span>
          {<span>{FormatHelper.DateFormat(record.lastActivity)}</span>}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "activityStatus",
      sorter: true,
      render: (_: any, record: ProfileDT) => (
        <ActivityStatus activity={record.activityStatus} small />
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_: any, record: ProfileDT) => (
        <Actions profile={record} fetchProfiles={fetchPeople} />
      ),
    },
  ];
  return (
    <div className="my-8">
      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        size="small"
        sortDirections={["ascend", "descend"]}
        loading={loading}
        rowClassName={"cursor-pointer editable-row"}
        className="table-top-align"
        onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: (e) => {
        //       e?.stopPropagation();
        //       console.log({ record });
        //       navigateToTop(`${record.id}`, false, {
        //         state: { team: record },
        //       });
        //     },
        //   };
        // }}
      />
      <div className="mt-8">
        <Pagination
          total={pagination?.total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // responsive
          showSizeChanger
          onChange={onChange}
          pageSize={pagination?.pageSize}
          current={pagination?.current}
        />
      </div>
    </div>
  );
};

export default ProfilesTable;
