
import { useRef, useState } from "react";
import { Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import {
  DateType,
  FlowEnum,
  OptionsFilterType,
  ReportTypeEnum,
  ViewEnum,
} from "../../../../utils/types";
import { useMediaQuery } from "react-responsive";
import { LuSearch } from "react-icons/lu";
import { cn } from "../../../../utils/utils";
import Modal from "../../../../components/Modal";
import { IoCloseOutline } from "react-icons/io5";
import Icon from "../../../../components/ui/Buttons/Icon";
import DatePickerChunks from "./DatePickerChunks";
import PermissionsSelectors from "../../../../modules/permissions/selectors";
import usePermissionHandler from "../../hooks/handleActionWithPermission";

const views = [
  {
    label: "All",
    value: ViewEnum.ALL,
  },
  {
    label: "Internal",
    value: ViewEnum.INTERNAL,
  },
  {
    label: "External",
    value: ViewEnum.EXTERNAL,
  },
];
const flows = [
  {
    label: "All",
    value: FlowEnum.ALL,
  },
  {
    label: "Inflow",
    value: FlowEnum.INFLOW,
  },
  {
    label: "OutFlow",
    value: FlowEnum.OUTFLOW,
  },
];

const Filters = ({
  usersOptions,
  filterOption,
  handleFilterChange,
  isCollapsed,
  collapseFilters,
}: {
  usersOptions: any;
  filterOption: OptionsFilterType;
  handleFilterChange: Function;
  isCollapsed: boolean;
  collapseFilters: () => void;
}) => {
  const isMobile = useMediaQuery({ maxWidth: "767px" });
  const { hiddenElements } = usePermissionHandler();
  const { permissions } = PermissionsSelectors();
  const { reports: { reports = [] } = {} } = permissions || {};

  const [filters, setFilters] = useState<OptionsFilterType>(filterOption);
  const isAnalyticsView = filters.report === ReportTypeEnum.ANALYTICS;
  const prevFilterOptionRef = useRef<OptionsFilterType>(filterOption);

  const submitFilter = (updatedFilters: OptionsFilterType) => {
    if (
      JSON.stringify(prevFilterOptionRef.current) !==
      JSON.stringify(updatedFilters)
    ) {
      prevFilterOptionRef.current = updatedFilters;
      handleFilterChange(updatedFilters, true, true);
    } else {
      handleFilterChange(updatedFilters, false);
    }
  };
  const handleFilters = (name: string, value: any) => {
    if (name === "dateRange") {
      setFilters((prev) => ({
        ...prev,
        dateRange: value
          ? {
              from: value[0] ? dayjs(value[0]).format("YYYY-MM-DD") : null,
              to: value[1] ? dayjs(value[1]).format("YYYY-MM-DD") : null,
            }
          : { from: null, to: null },
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const Wrapper = ({ children }: { children: any }) =>
    isMobile ? (
      <Modal
        showModal={!isCollapsed}
        dismiss={collapseFilters}
        className="!w-full !pb-4"
      >
        <div className="flex justify-between items-center">
          <h1 className="font-bold">{"Report Customization"}</h1>
          <Icon className="!bg-transparent" onClick={() => collapseFilters()}>
            <IoCloseOutline color={"#000"} size={20} />
          </Icon>
        </div>
        <div className="gap-4 flex flex-col">{children}</div>
      </Modal>
    ) : (
      <div
        className={cn(
          "transition-all delay-150 duration-300 ease-in-out ",
          isCollapsed
            ? "-translate-y-full opacity-0 "
            : "translate-y-0 opacity-1"
        )}
      >
        <div
          className={cn(
            isCollapsed
              ? "hidden"
              : "mt-4 lg:mt-0 grid w-full lg:grid-cols-7 md:grid-cols-1 p-4 rounded-lg justify-between items-end border-b border-b-gray-150",
            "gap-4 lg:grid-cols-7"
          )}
        >
          {children}
        </div>
      </div>
    );
  const { report, view, userId1, userId2, dateRange, flow } = filters;
  const dates: [Dayjs | null | undefined, Dayjs | null | undefined] = [
    dateRange.from ? dayjs(dateRange.from) : null,
    dateRange.to ? dayjs(dateRange.to) : null,
  ];
  const handleFilterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => {
    try {
      return !!input
        ? (option?.label ?? "").toLowerCase()?.includes(input.toLowerCase())
        : false;
    } catch (e) {
      console.log(e);
    }
    return false;
  };
  return (
    <Wrapper>
      <div>
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">Report</span>
        <Select
          className="h-[2.5rem]"
          value={report}
          style={{ width: "100%" }}
          placeholder={"Select report"}
          onChange={(value) => handleFilters("report", value)}
          // options={reportsLs}
          options={reports}
        />
      </div>
      <div>
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">
          {isAnalyticsView ? "Users" : "User"}
        </span>
        <Select
          showSearch
          disabled={!hiddenElements.usersFilter}
          filterOption={handleFilterOption}
          value={userId1}
          style={{ width: "100%" }}
          placeholder={"Select user"}
          maxTagCount={"responsive"}
          onChange={(value) => handleFilters("userId1", value)}
          options={
            usersOptions?.insiders?.filter(
              (user: { label: string; value: string }) =>
                user?.value !== userId2
            ) || []
          }
          className={"h-[2.5rem]"}
          allowClear
        />
      </div>
      {isAnalyticsView ? (
        <div>
          <Select
            showSearch
            filterOption={handleFilterOption}
            allowClear
            disabled={!hiddenElements.usersFilter}
            value={userId2}
            style={{ width: "100%" }}
            placeholder={"Select user"}
            maxTagCount={"responsive"}
            onChange={(value) => handleFilters("userId2", value)}
            options={
              usersOptions?.outsiders?.filter(
                (user: { label: string; value: string }) =>
                  user?.value !== userId1
              ) || []
            }
            className={"h-[2.5rem]"}
          />
        </div>
      ) : null}

      {isAnalyticsView ? null : (
        <div>
          <span className="mb-1 pl-2 text-placeholder/80 text-sm">View</span>
          <Select
            className="h-[2.5rem]"
            value={view}
            style={{ width: "100%" }}
            placeholder={"Select view"}
            onChange={(value) => handleFilters("view", value)}
            options={views}
          />
        </div>
      )}

      <div>
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">Flow</span>
        <Select
          className="h-[2.5rem]"
          placeholder={"Select flow"}
          value={flow}
          style={{ width: "100%" }}
          onChange={(value) => handleFilters("flow", value)}
          options={flows}
        />
      </div>
      <div className="col-span-2 flex flex-col">
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">Time</span>
        <DatePickerChunks
          selectedDates={dates}
          onChange={(value: DateType) => handleFilters("dateRange", value)}
        />
        {/* <DateRangePickerWithPresets
          selectedDates={dates}
          onChange={(value: DateType) => handleFilters("dateRange", value)}
        /> */}
        {/* <RangePicker
          value={dates}
          presets={rangePresets}
          onChange={(_, dateStrings) => handleFilters("dateRange", dateStrings)}
          size={"small"}
          className="min-h-[2.5rem]"
        /> */}
      </div>
      <button
        disabled={
          filters.report === ReportTypeEnum.ANALYTICS &&
          (!filters.userId1 || !filters.userId2)
        }
        onClick={() => submitFilter(filters)}
        className={cn(
          "flex text-primaryBlue max-md:col-span-2 items-center justify-center gap-2 !px-4 !py-2 !max-md:px-5 border border-primaryBlue rounded-lg hover:bg-primaryBlue/10  hover:border-transparent transition-all duration-300 ease-out",
          filters.report === ReportTypeEnum.ANALYTICS &&
            (!filters.userId1 || !filters.userId2)
            ? "!text-gray-800 bg-gray-300 border border-gray-500 cursor-not-allowed"
            : ""
        )}
      >
        <LuSearch size={20} />
        <span>Search</span>
      </button>
    </Wrapper>
  );
};

export default Filters;
