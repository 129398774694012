import { useAppSelector } from "../../hooks/useStore";

const PermissionsSelectors = () => {
  const permissions = useAppSelector((state) => state.permissions.subscription);
  const isOpen = useAppSelector((state) => state.permissions.isOpen);
  const message = useAppSelector((state) => state.permissions.message);
  const link = useAppSelector((state) => state.permissions.link);
  return {
    permissions,
    isOpen,
    message,
    link,
  };
};

export default PermissionsSelectors;
