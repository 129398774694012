import React from "react";
import SearchBar from "./Inputs/SearchBar";

const TableHeader = ({
  searchTerm,
  handler,
  placeholder,
  rightNode = null,
  leftNode = null,
}: {
  searchTerm: string;
  handler: (e: React.ChangeEvent<HTMLInputElement> | null) => void;
  placeholder: string;
  rightNode?: React.ReactNode;
  leftNode?: React.ReactNode;
}) => {
  return (
    <div className="flex gap-4 w-full lg:justify-between flex-wrap text-base font-medium tracking-wide leading-6 max-md:flex-wrap my-8">
      <div className="flex gap-2 flex-1">
        {rightNode}
        <SearchBar
          searchTerm={searchTerm}
          handler={handler}
          placeholder={placeholder}
        />
      </div>
      {leftNode}
    </div>
  );
};

export default TableHeader;
