import React from "react";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  ICommunicationMetric,
} from "../../../../utils/types";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import {
  PolarAngleAxis,
  PolarGrid,
  Radar as RechartRadar,
  RadarChart,
  PolarRadiusAxis,
} from "recharts";
import CardWrapper from "../../../../components/CardWrapper";

const chartConfig = {
  currentPeriod: {
    label: "Current Period",
  },
  lastPeriod: {
    label: "Last Period",
  },
  yearlyAvg: {
    label: "Yearly Avg",
  },
} satisfies ChartConfig;

const Radar = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<ICommunicationMetric>;
}) => {
  const isOutFlow = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading, needSubscription } = details;
  return (
    <CardWrapper
      className={className}
      title={"Communication Metrics"}
      description={"Understand users patterns of Interactions"}
      tooltip={"Calculated parameters by period selected"}
      innerClassName={"p-0 mb-5"}
      error={error}
      loading={loading}
      needSubscription={needSubscription}
      content={
        <div className="flex justify-center lg:flex-1">
          <ChartContainer
            config={chartConfig}
            className="h-[400px] aspect-square max-md:size-[250px] max-md:w-[100%] lg:flex-1"
          >
            <RadarChart
              data={data}
              // margin={{
              //   top: 20,
              // }}
            >
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="line" />}
              />
              <PolarRadiusAxis
                angle={60}
                orientation="middle"
                axisLine={false}
              />
              <PolarAngleAxis
                dataKey="parameter"
                tick={({ payload, x, y, textAnchor }) => {
                  console.log(payload);
                  return (
                    <text
                      x={x}
                      y={y}
                      textAnchor={textAnchor || "middle"}
                      dy={4} // Adjust vertical alignment
                      // fontSize={12}
                    >
                      {payload.value}
                    </text>
                  );
                }}
              />
              <PolarGrid gridType="circle" />
              <RechartRadar
                dataKey="currentPeriod"
                fill={`var(--chart-current-period-${isOutFlow})`}
                fillOpacity={0.75}
                dot={{
                  r: 2,
                  fillOpacity: 1,
                }}
              />
              <RechartRadar
                dataKey="lastPeriod"
                fill={`var(--chart-last-period-${isOutFlow})`}
                fillOpacity={0.75}
                dot={{
                  r: 2,
                  fillOpacity: 1,
                }}
              />
              <RechartRadar
                dataKey="yearlyAvg"
                fill={`var(--chart-yearly-avg-${isOutFlow})`}
                fillOpacity={0.75}
                dot={{
                  r: 2,
                  fillOpacity: 1,
                }}
              />
              <ChartLegend content={<ChartLegendContent />} />
            </RadarChart>
          </ChartContainer>
        </div>
      }
    />
  );
};

export default Radar;
