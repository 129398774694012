import { useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import AuthSelectors from "../../../../modules/auth/selectors";
import { UpdateProfile } from "../../../../modules/auth/service";
import AuthActions from "../../../../modules/auth/actions";
import { useAppDispatch } from "../../../../hooks/useStore";

const useProfile = () => {
  const { user } = AuthSelectors();
  const { fetchProfile } = AuthActions();
  const dispatch = useAppDispatch();
  const { toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    organization: user?.organization || "",
    country: user?.country || "",
    address: user?.address || "",
  });

  const submitProfile = async () => {
    // if (!form.organization) {
    //   toastError("Please fill the required fields!");
    // } else {
    setLoading(true);
    await UpdateProfile(form)
      .then((response) => {
        dispatch(fetchProfile());
        toastSuccess(response.message);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
    // }
  };

  const formHandler = (key: string, value: string) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  return {
    user,
    loading,
    submitProfile,
    form,
    formHandler,
  };
};
export default useProfile;
