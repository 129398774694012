import { useLocation, Outlet, Navigate } from "react-router-dom";
import Section from "./Section";
import { useEffect } from "react";
import Sidebar from "./Navbar/Sidebar";
import AuthActions from "../modules/auth/actions";
import { useAppDispatch } from "../hooks/useStore";
import { useMediaQuery } from "react-responsive";
import { cn } from "../utils/utils";
import useSubscription from "../pages/Dashboard/hooks/useSubscription";
import useUnauthorizedHandler from "../pages/Dashboard/hooks/useUnauthorizedHandler";
import Loading from "../components/ui/Loading";
import SubscriptionModal from "../pages/Dashboard/components/SubscriptionModal";
import EmailSyncProgress from "./Sync/EmailSyncProgress";
import useSync from "./Sync/hooks/useSync";
import CommonActions from "../modules/common/actions";
import ErrorBoundary from "./ErrorBoundary";
import Fallback from "./Fallback";

const ProtectedRoute = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { logout } = AuthActions();
  const { isLoggedIn, token, loading } = useSubscription();
  const { progress, logs, status, isVisible } = useSync(isLoggedIn);
  useUnauthorizedHandler(() => dispatch(logout()));
  const { GetOutsidersSelection, GetInsidersSelection } = CommonActions();

  useEffect(() => {
    dispatch(GetOutsidersSelection());
    dispatch(GetInsidersSelection());
  }, []);
  const isMobile = useMediaQuery({ maxWidth: "769px" });
  if (!isLoggedIn || !token) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  if (loading) {
    <Loading />;
  }
  return (
    <ErrorBoundary renderError={Fallback}>
      <Section
        id={location?.pathname?.split("/")[1] || ""}
        className="!p-0 !m-0 bg-gray-50"
      >
        <Sidebar />
        <div
          className={cn(
            "lg:mt-0 bg-white min-h-dvh rounded-tl-3xl",
            !isMobile && "ml-[17rem]"
          )}
        >
          {isVisible ? (
            <EmailSyncProgress
              progress={progress}
              logs={logs}
              status={status}
              isVisible={true}
            />
          ) : (
            <Outlet />
          )}
          {/* {loading ? <Loading /> : <Outlet />} */}
        </div>
        <SubscriptionModal />
      </Section>
    </ErrorBoundary>
  );
};

export default ProtectedRoute;
