import { useAlertsContext } from "../../hooks/useAlertsContext";
import * as Actions from "./types";
import { GetPricing } from "./service";
import {
  GetInsidersList,
  GetPeopleList,
} from "../../pages/Dashboard/People/services/service";

const CommonActions = () => {
  const { toastError } = useAlertsContext();
  const GetOutsidersSelection = () => {
    return async (dispatch) => {
      const searchObj = { searchTerm: "", skip: 0, take: -1 };
      await GetPeopleList(searchObj)
        .then((response) => {
          const mappedArray =
            response.data.map((item) => ({
              label: item.name,
              value: item.id,
            })) || [];
          dispatch({
            type: Actions.SAVE_OUTSIDERS_LIST,
            payload: mappedArray,
          });
        })
        .catch((error) => console.log(error));
    };
  };
  const GetInsidersSelection = () => {
    return async (dispatch) => {
      const searchObj = { searchTerm: "", skip: 0, take: -1 };
      await GetInsidersList(searchObj)
        .then((response) => {
          const mappedArray =
            response.data.map((item) => ({
              label: item.name,
              value: item.id,
            })) || [];
          dispatch({
            type: Actions.SAVE_INSIDERS_LIST,
            payload: mappedArray,
          });
        })
        .catch((error) => console.log(error));
    };
  };
  const getPricing = () => {
    return async (dispatch) => {
      await GetPricing()
        .then((response) => {
          dispatch({
            type: Actions.SAVE_PRICING,
            payload: response.data,
          });
        })
        .catch((e) => {
          toastError(e?.data?.message);
          // dispatch(handleRefreshToken());
        });
    };
  };

  return {
    getPricing,
    GetOutsidersSelection,
    GetInsidersSelection,
  };
};

export default CommonActions;
