import React from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import DesktopBar from "./DesktopBar";
import MobileBar from "./MobileBar";
const navItems = [
  { text: "Home", to: "/" },
  { text: "About Us", to: "/about-us" },
  { text: "Security", to: "/Security" },
  { text: "Pricing", to: "/Pricing" },
  { text: "Resources", to: "/Resources" },
];
const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [visible, setVisible] = React.useState(true);
  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(currentScrollPos < 5);
      // setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);
  const isMobile = useMediaQuery({ maxWidth: "768px" });
  const location = useLocation();
  const isHome = location?.pathname === "/";
  return isMobile ? (
    <MobileBar items={navItems} isHome={isHome} />
  ) : (
    <DesktopBar items={navItems} isHome={isHome} visible={visible} />
  );
};

export default Navbar;
