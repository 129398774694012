import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { Link } from "react-router-dom";
import socials from "../../data/configs";

const PrivacyEN = () => {
  return (
    <Section id="Policy" badge={"Privacy Policy"} title="Privacy Policy">
      <SEO title={"Policy | InterSight"} />
      <div className="items-center justify-center flex flex-col">
        <div className="my-16 w-[90%] lg:w-[50%]">
          <p className="font-bold text-primaryBlue text-center">
            Last Update on {socials.updateAt}
          </p>
          <div className="items-center justify-center mt-8">
            <div className="mb-10">
              <div className="my-8 italic pl-4 text-lg font-medium border-l-2 border-l-primaryBlue">
                <p>
                  InterSight ("we," "us," "our") is committed to protecting your
                  privacy. This Privacy Policy explains how we collect, use,
                  disclose, and safeguard your information when you visit our
                  website
                  <Link
                    to="https://www.inter-sight.com"
                    target="_blank"
                    className="font-bold mx-2 text-primaryBlue"
                  >
                    www.inter-sight.com
                  </Link>
                  is committed to being transparent about how we collect, use,
                  disclose, and store your personal information. <br />
                  (the "Site") and use our platform and services (collectively,
                  the "Services"). By accessing or using our Services, you
                  consent to the practices described in this Privacy Policy.
                </p>
                <span className="block mt-2">
                  <strong>{"Company name and identifying number: "}</strong>
                  Intersight LTD 517017349
                </span>
                <span className="block">
                  <strong>{"Phone number: "}</strong>
                  <Link
                    to={`tel:${socials.phone}`}
                    className="mx-2 text-primaryBlue"
                    dir="ltr"
                  >
                    {socials.phone}
                  </Link>
                </span>
                <span className="block">
                  <strong>Address: </strong>
                  {socials.address}
                </span>
              </div>
            </div>
            <div className="mb-10">
              <ul className="start-custom space-y-4 list-inside list-decimal">
                <li className="[&>p]:mb-1">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    {" Information We Collect"}
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2 space-y-4">
                    <li>
                      <strong>Information You Provide to Us</strong>
                      <p>
                        We may collect personal information that you voluntarily
                        provide to us when using our Services, including:
                      </p>
                      <ul className="nested-list list-disc list-inside pl-4 mt-2">
                        <li>
                          <strong>Contact Information: </strong>Name, email
                          address, phone number.
                        </li>
                        <li>
                          <strong>Professional Information: </strong>Job title,
                          workplace name, position in your organization.
                        </li>
                        <li>
                          <strong>Account Information: </strong>Username,
                          password, and any content you upload to the Services.
                        </li>
                        <li>
                          <strong>Payment Information: </strong>Billing address
                          and payment details for purchasing our products or
                          services.
                        </li>
                        <li>
                          <strong>Communications: </strong>Information you
                          provide in communications with us, including customer
                          support inquiries and feedback.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Information We Collect Automatically</strong>
                      <p>
                        When you use our Services, we may automatically collect
                        certain information about your device and usage,
                        including:
                      </p>
                      <ul className="nested-list list-disc list-inside pl-4 mt-2">
                        <li>
                          <strong>Device Information: </strong>IP address,
                          browser type, device type, and operating system.
                        </li>
                        <li>
                          <strong>Usage Data: </strong>Pages viewed, links
                          clicked, and the date and time of your visit.
                        </li>
                        <li>
                          <strong>Metadata: </strong>Information about emails
                          (such as sender, recipient, subject, timestamp) that
                          is necessary for the functionality of the Services.
                          Note: We do not access the content of email messages
                          or attachments.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Information from Third Parties</strong>
                      <p>
                        We may receive information about you from third-party
                        services, such as:
                      </p>
                      <ul className="nested-list list-disc list-inside pl-4">
                        <li>
                          <strong>Social Media Platforms: </strong>If you choose
                          to link your account with us to a social media
                          platform, we may collect information from your profile
                          on that platform.
                        </li>
                        <li>
                          <strong>Service Providers: </strong>Information from
                          service providers to assist with user verification,
                          fraud detection, and payment processing.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    How We Use Your Information
                  </h2>
                  <p>
                    We use the information we collect for the following
                    purposes:
                  </p>
                  <ul className="nested-list list-disc list-inside pl-4 mt-2">
                    <li>
                      <strong>To Provide and Improve Our Services: </strong>
                      Facilitate your use of the Services, process transactions,
                      and improve the overall functionality and user experience.
                    </li>
                    <li>
                      <strong>Communication: </strong>Send you updates,
                      marketing materials, and respond to your inquiries.
                    </li>
                    <li>
                      <strong>Customization: </strong>Personalize your
                      experience by presenting content tailored to your
                      interests.
                    </li>
                    <li>
                      <strong>Analytics and Research: </strong>Analyze usage
                      patterns to improve our Services and develop new features.
                    </li>
                    <li>
                      <strong>Security: </strong>Monitor and enhance the
                      security of our Services and protect against fraudulent
                      activities.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    How We Use User Data
                  </h2>
                  <p>We use user data in strict accordance:</p>
                  <ul className="nested-list list-disc list-inside pl-4 mt-2">
                    <li>
                      <strong>Accessing Data: </strong>We may request access to
                      your account data (such as email metadata) to enhance your
                      experience with our Services.
                    </li>
                    <li>
                      <strong>Limited Use: </strong>We use user data solely for
                      providing the specific functionality you have requested,
                      such as metadata analysis, and do not use it for any
                      unrelated purposes like advertising or profiling.
                    </li>
                    <li>
                      <strong>Data Storage: </strong>We store user data securely
                      and only for as long as necessary to provide our Services.
                    </li>
                    <li>
                      <strong>Data Sharing: </strong>We do not share user data
                      with third parties except as required to provide the
                      requested services or as required by law.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Sharing Your Information
                  </h2>
                  <p>We may share your information with:</p>
                  <ul className="nested-list list-disc list-inside pl-4 mt-2">
                    <li>
                      <strong>Service Providers: </strong>Third-party vendors
                      who assist us with various aspects of our operations, such
                      as hosting, payment processing, and analytics. These
                      service providers are contractually obligated to protect
                      your information and use it only as necessary to provide
                      their services to us.
                    </li>
                    <li>
                      <strong>Business Transfers: </strong>In the event of a
                      merger, acquisition, or sale of assets, your information
                      may be transferred as part of the transaction. We will
                      notify you and provide you with options regarding your
                      information in such circumstances.
                    </li>
                    <li>
                      <strong>Legal Obligations: </strong>We may disclose your
                      information if required by law, regulation, or legal
                      process, or if we believe disclosure is necessary to
                      protect our rights or the safety of others.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Cookies and Tracking Technologies
                  </h2>
                  <p>
                    We use cookies and similar tracking technologies to enhance
                    your experience on our Site. Cookies help us remember your
                    preferences and understand how you interact with our
                    Services. You can manage your cookie preferences through
                    your browser settings.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Data Security
                  </h2>
                  <p>
                    We take the security of your information seriously and
                    implement a variety of industry-standard security measures
                    to protect it. However, no method of transmission over the
                    internet or electronic storage is completely secure, and we
                    cannot guarantee its absolute security.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Data Retention
                  </h2>
                  <p>
                    We will retain your personal information only for as long as
                    necessary to fulfill the purposes for which it was
                    collected, including to comply with legal, accounting, or
                    reporting requirements. Once the retention period expires,
                    we will securely delete or anonymize your information.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Your Rights
                  </h2>
                  <p>
                    Depending on your location, you may have the following
                    rights regarding your personal information:
                  </p>
                  <ul className="nested-list list-disc list-inside pl-4">
                    <li>
                      <strong>Access: </strong>Request access to the personal
                      information we hold about you.
                    </li>
                    <li>
                      <strong>Correction: </strong>Request correction of any
                      inaccurate or incomplete information.
                    </li>
                    <li>
                      <strong>Deletion: </strong>Request deletion of your
                      personal information under certain circumstances.
                    </li>
                    <li>
                      <strong>Restriction: </strong>Request restriction of
                      processing your personal information.
                    </li>
                    <li>
                      <strong>Portability: </strong>Request a copy of your
                      personal information in a machine-readable format.
                    </li>
                    <li>
                      <strong>Objection: </strong>Object to our processing of
                      your personal information based on legitimate interests.
                    </li>
                  </ul>
                  <p>
                    To exercise these rights, please contact us at
                    <Link
                      to={`mailto:${socials.email}`}
                      className="font-bold mx-2 text-primaryBlue"
                    >
                      {socials.email}.
                    </Link>
                    We will respond to your request in accordance with
                    applicable laws.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Children’s Privacy
                  </h2>
                  <p>
                    Our Services are not intended for use by children under the
                    age of 16, and we do not knowingly collect personal
                    information from children under 16. If we become aware that
                    we have collected information from a child under 16, we will
                    take steps to delete such information.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    International Data Transfers
                  </h2>
                  <p>
                    We may transfer your personal information to countries
                    outside of your jurisdiction, including the United States,
                    where data protection laws may differ from those in your
                    country. We will take appropriate steps to ensure that your
                    personal information is treated securely and in accordance
                    with this Privacy Policy.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Changes to This Privacy Policy
                  </h2>
                  <p>
                    We may update this Privacy Policy from time to time. If we
                    make material changes, we will notify you by email or
                    through a notice on our Site. Your continued use of the
                    Services after any changes become effective constitutes your
                    acceptance of the updated Privacy Policy.
                  </p>
                </li>

                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Information Requests:
                  </h2>
                  <p>
                    If you have any questions or concerns regarding the
                    processing of your personal information or would like to
                    exercise your rights, you can contact us here at
                    <Link
                      to={`mailto:${socials.email}`}
                      className="font-bold mx-2 text-primaryBlue"
                    >
                      {socials.email}.
                    </Link>
                    We will promptly respond to your requests and provide you
                    with the information you need.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Contact Us
                  </h2>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or our data practices, please contact us at:
                  </p>
                  <div className="my-8 italic pl-4 text-lg font-medium border-l-2 border-l-primaryBlue">
                    <span className="block">
                      <strong>{"Email: "}</strong>
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}.
                      </Link>
                    </span>
                    <span className="block">
                      <strong>{"Phone number: "}</strong>
                      <Link
                        to={`tel:${socials.phone}`}
                        className="mx-2 text-primaryBlue"
                        dir="ltr"
                      >
                        {socials.phone}
                      </Link>
                    </span>
                    <span className="block">
                      <strong>Address: </strong>
                      {socials.address}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PrivacyEN;
