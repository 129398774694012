import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import Icon from "../../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import { Form, FormInstance, Input } from "antd";
import Button from "../../../../components/Button";
import { Download, UserRoundPlus } from "lucide-react";
import { useMediaQuery } from "react-responsive";
import { DownloadCSV } from "../services/service";

const InviteProfileModal = ({
  form,
  showModal,
  dismiss,
  submit,
  loading,
}: {
  form: FormInstance;
  showModal: boolean;
  dismiss: () => void;
  submit: Function;
  loading: boolean;
}) => {
  const onFinish = (values: any) => {
    submit(values);
  };
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold">Refer a friend</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        We hope you enjoy the results of our work!
      </p>
      <p className="text-placeholder leading-4 text-sm">
        We will be truly grateful for a colleague referral it will help us to
        improve our services and counter-affect your business relatives.
      </p>
      <p className="text-placeholder leading-4 text-sm">
        Your trust and supports means a lot to us.
      </p>
      <div className="mt-8">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            required
            name="email"
            initialValue={""}
            rules={[
              {
                type: "email",
                required: true,
                message: `Email is required!`,
              },
            ]}
          >
            <Input placeholder="example@company.com" className="py-2" />
          </Form.Item>

          <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              disabled={loading}
              type={"button"}
              variant="secondary"
              onClick={() => dismiss()}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Send invite"}
              type={"submit"}
              variant={!submittable ? "disabled" : "primary"}
              loading={loading}
              className={`"justify-center items-center !px-12 !py-2 ${
                !submittable ? "!text-gray-400" : "text-white bg-primaryBlue"
              }   !max-md:px-5"`}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const InviteProfile = ({
  inviteFriend,
  exportingCSV,
  downloadReport,
}: {
  inviteFriend: Function;
  exportingCSV: boolean;
  downloadReport: Function;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: "767px" });

  const AddProfile = ({ email }: { email: string }) => {
    setIsSubmitting(true);
    inviteFriend(email, (isOk: boolean) => {
      if (isOk) {
        setModalVisible(false);
        form.resetFields();
      }
    });
    setIsSubmitting(false);
  };
  const DismissModal = () => {
    setModalVisible(false);
    form.resetFields();
  };
  const ReferAFriend = () => {
    setModalVisible(true);
    // handleActionWithPermission("FREE_TRIAL", () => setModalVisible(true));
  };
  return (
    <div className="flex gap-2 justify-end">
      <Button
        text={isMobile ? undefined : "Refer a friend"}
        onClick={ReferAFriend}
        variant="primary"
        className={`flex items-center gap-2 p-4 rounded-xl cursor-pointer shadow-sm h-full max-md:h-auto !my-0`}
        icon={<UserRoundPlus size={20} />}
      />
      <Button
        text={isMobile ? undefined : "Export to CSV"}
        //   variant={"secondary"}
        loading={exportingCSV}
        onClick={() => downloadReport()}
        icon={<Download size={20} />}
        className={`flex items-center gap-2 p-4 rounded-xl cursor-pointer shadow-sm h-full max-md:h-auto !my-0`}
      />
      {modalVisible ? (
        <InviteProfileModal
          form={form}
          showModal={modalVisible}
          loading={isSubmitting}
          //   loading={isSubmitting && !loading}
          dismiss={DismissModal}
          submit={AddProfile}
        />
      ) : null}
    </div>
  );
};
export default InviteProfile;
