
import React from "react";
import { ChartsTypeEnum, GraphTypeEnum } from "../../../utils/types";
import Volume from "../components/Charts/Volume";
import Timing from "../components/Charts/Timing";
import Radar from "../components/Charts/Radar";
import Scope from "../components/Charts/scope";

const Analytics = (props: any) => {
  const { flow, report, view, charts } = props;
  const filters = { report, view, flow };

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-2">
      <Volume
        className="col-span-1"
        filters={{ graphType: GraphTypeEnum.VOLUME, ...filters }}
        details={charts[ChartsTypeEnum.VOLUME]}
      />
      <Timing
        className="col-span-1"
        filters={{
          graphType: GraphTypeEnum.FREQUENCY_TIME,
          ...filters,
        }}
        details={charts[ChartsTypeEnum.FREQUENCY_TIME]}
      />
      <Radar
        className="col-span-1"
        filters={{
          graphType: GraphTypeEnum.RADAR,
          ...filters,
        }}
        details={charts[ChartsTypeEnum.COMMUNICATION]}
      />
      {/* <Scope className="col-span-1" flow={props.flow} /> */}
      <Scope
        className="col-span-1"
        filters={{
          graphType: GraphTypeEnum.SCOPE,
          ...filters,
        }}
        details={charts[ChartsTypeEnum.TOPICS]}
      />
    </div>
  );
};

export default Analytics;
