import React, { useState } from "react";
import Modal from "../../../components/Modal";
import Icon from "../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import Button from "../../../components/Button";
import classNames from "classnames";
import { Form, FormInstance, Input } from "antd";
import AuthSelectors from "../../../modules/auth/selectors";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import AuthActions from "../../../modules/auth/actions";
import { useAppDispatch } from "../../../hooks/useStore";

const DeleteAccountModal = ({
  showModal,
  dismiss,
  submit,
  loading,
  form,
}: {
  showModal: boolean;
  dismiss: () => void;
  submit: Function;
  loading: boolean;
  form: FormInstance;
}) => {
  const { user } = AuthSelectors();
  const { toastError } = useAlertsContext();
  const values = Form.useWatch([], form);
  const isValidEmail = user?.email === values?.email;
  const onFinish = (values: any) => {
    if (isValidEmail) {
      submit();
    } else {
      toastError("Email not compatible with your account!");
    }
  };
  const [submittable, setSubmittable] = useState(false);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-lg">
          {"Are you sure you want to delete your account?"}
        </h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        Deleting your account is permanent and will also delete all of your
        email statistics associated with it forever.
      </p>
      <div className="mt-8">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            required
            name="email"
            initialValue={""}
            rules={[
              {
                type: "email",
                required: true,
                message: `Please enter your email address!`,
              },
            ]}
          >
            <Input
              placeholder="Type your email address to confirm"
              className="py-2"
            />
          </Form.Item>

          <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              disabled={loading}
              type={"button"}
              variant="secondary"
              onClick={() => dismiss()}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Yes, delete my account"}
              type={"submit"}
              loading={loading}
              className={classNames(
                "justify-center items-center !px-12 !py-2 !max-md:px-5",
                !submittable && "opacity-30 cursor-not-allowed"
              )}
              variant={!isValidEmail ? "disabled" : "danger"}
              //   onClick={() => submittable && submit()}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const DeleteAccount = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { deleteAccount } = AuthActions();
  const [form] = Form.useForm();
  const handleDeleteAccount = () => {
    setIsSubmitting(true);
    dispatch(deleteAccount(() => setIsSubmitting(false)));
  };
  const DismissModal = () => {
    setModalVisible(false);
    setIsSubmitting(false);
    form.resetFields();
  };
  return (
    <>
      <div className="sm:col-span-2 place-self-end">
        <Button
          text={"Delete my account"}
          onClick={() => setModalVisible(true)}
          variant={"danger"}
          className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
        />
      </div>
      <DeleteAccountModal
        form={form}
        showModal={modalVisible}
        loading={isSubmitting}
        dismiss={DismissModal}
        submit={handleDeleteAccount}
      />
    </>
  );
};

export default DeleteAccount;
