import { combineReducers } from "redux";
import commonReducer from "../modules/common/reducer";
import authReducer from "../modules/auth/reducer";
import permissionReducer from "../modules/permissions/reducer";
// import featuresReducer from "../modules/features/reducer";

/**
 * Root reducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducers = combineReducers({
  common: commonReducer,
  auth: authReducer,
  permissions: permissionReducer,
  // features: featuresReducer,
});

export default rootReducers;
