import { INotification } from "./../../../../utils/types";
import Modal from "../../../../components/Modal";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../../components/ui/avatar";
import { Button } from "../../../../components/ui/button";
import { Separator } from "../../../../components/ui/separator";
import { format } from "date-fns";

interface NotificationDisplayProps {
  notification?: INotification;
  showModal: boolean;
  dismiss: () => void;
}

export function NotificationDisplay({
  notification,
  showModal,
  dismiss,
}: NotificationDisplayProps) {
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      <div className="flex h-full flex-col">
        {notification ? (
          <div className="flex flex-1 flex-col">
            <div className="flex items-start max-md:flex-col p-4">
              <div className="flex items-start gap-4 text-sm">
                <Avatar>
                  <AvatarImage alt={notification?.name} />
                  <AvatarFallback>
                    {notification?.name
                      .split(" ")
                      .map((chunk) => chunk[0])
                      .join("")}
                  </AvatarFallback>
                </Avatar>
                <div className="grid gap-1">
                  <div className="font-semibold">{notification?.name}</div>
                  <div className="line-clamp-1 text-xs">
                    {notification?.subject}
                  </div>
                  {/* <div className="lg:line-clamp-1 text-xs">
                    <span className="font-medium max-md:hidden">Reply-To:</span>{" "}
                    {notification.email}
                  </div> */}
                </div>
              </div>
              {notification?.createdAt && (
                <div className="lg:ml-auto max-md:mt-2 text-xs text-muted-foreground">
                  {format(new Date(notification?.createdAt), "PPpp")}
                </div>
              )}
            </div>
            <Separator />
            <div className="flex-1 whitespace-pre-wrap p-4 text-sm">
              {notification?.text}
            </div>
            <Separator className="mt-auto" />
            <div className="p-4">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dismiss();
                }}
                size="sm"
                className="ml-auto"
              >
                Close
              </Button>
            </div>
          </div>
        ) : (
          <div className="p-8 text-center text-muted-foreground">
            No message selected
          </div>
        )}
      </div>
    </Modal>
  );
}
