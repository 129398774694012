const email = "service@inter-sight.com";
const dns =
  "https://www.dunsguide.co.il/C9343c31b8a6d4f86ed914cb8de3ef336_%D7%97%D7%91%D7%A8%D7%95%D7%AA_%D7%94%D7%99%D7%99%D7%98%D7%A7_%D7%95%D7%9E%D7%99%D7%97%D7%A9%D7%95%D7%91/%D7%94%D7%99%D7%A0%D7%AA%D7%A8%D7%A1%D7%90%D7%99%D7%98/";
const linkedin = "https://www.linkedin.com/in/yotam-zakay-7887b0107";
const facebook = "";
const instagram = "";
const youtube = "https://www.youtube.com/intersight";
const address = "Arnon 27, Ramat Gan";
const addressHE = "ארנון 27, רמת גן";
const phone = "+972502000111";
const updateAt = "04-08-2024";

const socials = {
  linkedin,
  facebook,
  instagram,
  youtube,
  email,
  dns,
  address,
  phone,
  addressHE,
  updateAt,
};

export default socials;
