import PermissionsSelectors from "../../../modules/permissions/selectors";
import { cn } from "../../../utils/utils";

const SubscriptionBar = () => {
  const { permissions } = PermissionsSelectors();
  const { searches } = permissions;
  const percentage =
    searches?.searchesPercentageUsed && searches?.searchesPercentageUsed <= 100
      ? searches?.searchesPercentageUsed
      : "0";
  const searchesLeft =
    searches?.searchesLeft && searches?.searchesLeft > 0
      ? searches?.searchesLeft
      : 0;
  return (
    <div className="w-full my-2">
      {/* <span className="font-bold text-base block">Welcome,</span> */}
      <span className=" text-sm block">Trial subscription</span>
      <div className="flex flex-wrap justify-between mt-1">
        <span className="text-xs block font-bold">{percentage}% Used</span>
        <span className="text-xs font-normal block">
          {searchesLeft} searches left
        </span>
      </div>
      <div className="w-full bg-gray-300 rounded-full h-1 mt-1">
        {searches?.searchesPercentageUsed ? (
          <div
            style={{
              width: `${searches?.searchesPercentageUsed}%`,
            }}
            className={cn("bg-primaryBlue rounded-full h-full max-w-full")}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SubscriptionBar;
