import React from "react";
import { VscSaveAs } from "react-icons/vsc";
import Modal from "./Modal";
import Button from "./Button";

const UnsavedChanges = ({
  showModal,
  dismiss,
  confirm,
  title,
  subTitle,
}: {
  showModal: boolean;
  dismiss: () => void;
  confirm: Function;
  title: string;
  subTitle: string;
}) => {
  return (
    <Modal showModal={showModal} dismiss={dismiss} modalClassName="z-[53]">
      {/*body*/}
      <div className="relative p-6 flex flex-col gap-8 items-center">
        <VscSaveAs size={48} color={"rgb(185 28 28)"} />
        <div className="text-center">
          <h1 className="text-lg text-center font-bold">{title}</h1>
          <p className="text-sm text-center text-gray-400">{subTitle}</p>
        </div>
      </div>
      {/*footer*/}
      <div className="flex flex-wrap gap-4 max-md:gap-2 mt-4 justify-center text-lg font-bold px-2">
        <Button
          className="w-[45%] text-sm"
          variant={"secondary"}
          onClick={() => dismiss()}
          text={"Go back"}
          // icon={<IoCloseOutline />}
        />
        <Button
          className="w-[45%] text-sm"
          onClick={() => confirm()}
          text={"Proceed"}
          // icon={<IoCloseOutline />}
        />
      </div>
    </Modal>
  );
};

export default UnsavedChanges;
