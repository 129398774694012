import Modal from "../../components/Modal";
import { LogType } from "../../utils/types";
import images from "../../data/images";
import { CgSpinner } from "react-icons/cg";
import ConfirmNavigation from "../../hooks/useConfirmNavigation";

type IProps = {
  progress: number;
  logs?: LogType;
  status: string;
  isVisible: boolean;
};

const EmailSyncProgress = ({ progress, logs, status, isVisible }: IProps) => {
  // useEffect(() => {}, [logs]);
  let message = "Starting syncing...";
  let image = images.sync1;
  switch (logs) {
    case LogType.GettingProfileData:
      message = "Getting profile data...";
      image = images.sync1;
      break;
    case LogType.GettingMails:
      message = "Getting emails...";
      image = images.sync2;
      break;
    case LogType.SavingMails:
      message = "Saving emails...";
      image = images.sync3;
      break;
    default:
      message = "Starting syncing...";
      break;
  }
  return !isVisible ? null : (
    <>
      <Modal
        showModal={isVisible}
        className="max-md:min-w-full"
        isClosable={false}
      >
        <div className="flex-1">
          <img src={image} alt={message} />
        </div>
        <div className="flex-1">
          <h2 className="text-lg font-bold mb-4">Email Sync Progress</h2>
          <div className="flex gap-2 items-center">
            <CgSpinner className="animate-spin size-6 text-primaryBlue" />
            <p className="animate-shine text-sm text-gray-800">{message}</p>
          </div>
        </div>

        {/* <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
          <div
            className="bg-primary h-4 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p>{progress}% completed</p>
        <div className="text-sm text-gray-600">
          {status === "syncing" ? "Syncing emails..." : status}
        </div> */}
        {/* <Button
          className="text-xs"
          onClick={cancelSync}
          disabled={isCanceled}
          variant="danger"
          text={isCanceled ? "Sync Canceled" : "Cancel Sync"}
        /> */}
      </Modal>
      <ConfirmNavigation isBlocked={true} />
    </>
  );
};

export default EmailSyncProgress;
