import { useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import { CancelSubscription, GetBillings } from "../services/service";
import { IBilling } from "../../../../utils/types";

const useBilling = () => {
  const { toastError, toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [cancelLoad, setCancelLoad] = useState(false);
  const [data, setData] = useState<IBilling[]>([]);

  const cancelSubscribe = async () => {
    setCancelLoad(true);
    await CancelSubscription()
      .then((response) => toastSuccess(response.message))
      .catch((error) => console.log(error))
      .finally(() => setCancelLoad(false));
  };
  const fetchBilling = async () => {
    setLoading(true);
    await GetBillings()
      .then((response) => setData(response.data))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };
  return {
    cancelLoad,
    loading,
    data,
    cancelSubscribe,
    fetchBilling
  };
};

export default useBilling;
