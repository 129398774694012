import logoPrimary from "../assets/images/LogoPrimary.webp";
import logoWhite from "../assets/images/LogoWhite.webp";
import logoSlogan from "../assets/images/logoSlogan.png";
import LogoBadge from "../assets/images/Logobadge.webp";
import LogoBadgeWhite from "../assets/images/Logobadge_white.webp";
import heroSection from "../assets/images/heroSection.webp";
import dashboard from "../assets/images/Dashboard.webp";
import analyticsInterface from "../assets/images/analytics-interface.png";
import analyticsInterface2 from "../assets/images/analytics-interface2.png";
import analyticsInterface3 from "../assets/images/analytics-interface3.png";
import google from "../assets/icons/Google.png";
import microsoft from "../assets/icons/Microsoft.png";
import googleIcon from "../assets/icons/GoogleIcon.webp";
import microsoftIcon from "../assets/icons/MicrosoftIcon.webp";
import feature1 from "../assets/images/feature1.webp";
import feature2 from "../assets/images/feature2.webp";
import feature3 from "../assets/images/feature3.webp";
import dash1 from "../assets/images/dash1.webp";
import dash2 from "../assets/images/dash2.webp";
import CCPA from "../assets/icons/CCPA.webp";
import GDPR from "../assets/icons/GDPR.webp";
import ISO27001 from "../assets/icons/ISO27001.webp";
import youtube from "../assets/icons/youtube.png";
import linkedin from "../assets/icons/linkedin.png";
import dnsG from "../assets/icons/dnsG.png";
import security from "../assets/images/security.webp";
import company1 from "../assets/images/company1.jpg";
import codit from "../assets/icons/codit_logo.png";
import PlanetImg from "../assets/images/planet.webp";
import PlanetOverlayImg from "../assets/images/planet-overlay.svg";
import upgradePlan from "../assets/images/upgradePlan.svg";

import noise from "../assets/images/noise.png";
import heroRing from "../assets/images/hero/ring.png";
import heroAvatar01 from "../assets/images/hero/avatar-01.png";
import heroAvatar02 from "../assets/images/hero/avatar-02.png";
import heroAvatar03 from "../assets/images/hero/avatar-03.png";
import heroAvatar04 from "../assets/images/hero/avatar-04.png";
import heroAvatar05 from "../assets/images/hero/avatar-05.png";
import heroAvatar06 from "../assets/images/hero/avatar-06.png";

import card1 from "../assets/images/cards/card-01.png";
import cardHover1 from "../assets/images/cards/card-01-hover.png";
import card2 from "../assets/images/cards/card-02.png";
import cardHover2 from "../assets/images/cards/card-02-hover.png";
import card3 from "../assets/images/cards/card-03.png";
import cardHover3 from "../assets/images/cards/card-03-hover.png";

import testimonials1 from "../assets/images/testimonials/testimonials1.webp";
import testimonials2 from "../assets/images/testimonials/testimonials2.webp";
import testimonials3 from "../assets/images/testimonials/testimonials3.webp";
import testimonials4 from "../assets/images/testimonials/testimonials4.webp";
import testimonials5 from "../assets/images/testimonials/testimonials5.webp";
import testimonials6 from "../assets/images/testimonials/testimonials6.webp";
import testimonials7 from "../assets/images/testimonials/testimonials7.webp";
import testimonials8 from "../assets/images/testimonials/testimonials8.webp";
import testimonials9 from "../assets/images/testimonials/testimonials9.webp";
import testimonials10 from "../assets/images/testimonials/testimonials10.webp";
import testimonials11 from "../assets/images/testimonials/testimonials11.webp";

import sync1 from "../assets/images/sync1.svg";
import sync2 from "../assets/images/sync2.svg";
import sync3 from "../assets/images/sync3.svg";

import creditCard from "../assets/images/creditCard.svg";
import usApart from "../assets/images/us_apart.svg";

const images = {
  logoPrimary,
  logoWhite,
  LogoBadge,
  LogoBadgeWhite,
  heroSection,
  dashboard,
  analyticsInterface,
  analyticsInterface2,
  analyticsInterface3,
  google,
  microsoft,
  googleIcon,
  microsoftIcon,
  feature1,
  feature2,
  feature3,
  dash1,
  dash2,
  CCPA,
  GDPR,
  ISO27001,
  security,
  company1,
  codit,
  youtube,
  linkedin,
  dnsG,
  PlanetImg,
  PlanetOverlayImg,
  noise,
  upgradePlan,
  heroRing,
  heroAvatar01,
  heroAvatar02,
  heroAvatar03,
  heroAvatar04,
  heroAvatar05,
  heroAvatar06,
  card1,
  cardHover1,
  card2,
  cardHover2,
  card3,
  cardHover3,
  logoSlogan,
  testimonials1,
  testimonials2,
  testimonials3,
  testimonials4,
  testimonials5,
  testimonials6,
  testimonials7,
  testimonials8,
  testimonials9,
  testimonials10,
  testimonials11,
  sync1,
  sync2,
  sync3,
  creditCard,
  usApart,
};
export default images;
