import BorderBeam from "./BorderBeam";
import Button from "./Button";
import { cn } from "../utils/utils";
import { useNavigate } from "react-router-dom";

const GetStart = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        "mx-auto cursor-pointer max-w-7xl relative bg-primaryBlue flex group px-6 py-12 flex-col items-center justify-center overflow-hidden rounded-2xl border",
        className
      )}
      onClick={() => navigate("/get-started/sign-up")}
    >
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b transition-all duration-300 ease-in-out group-hover:scale-75 from-white to-white/5 bg-clip-text text-center text-4xl lg:text-8xl font-semibold leading-none text-transparent">
        Getting Started
      </span>
      <BorderBeam
        size={250}
        duration={12}
        delay={9}
        borderWidth={4}
        colorFrom={"#adffd8"}
        colorTo={"#669CF4"}
      />
      <p className="lg:max-w-[50%] text-center text-wrap mt-8 lg:text-lg text-[1rem] text-white">
        <span className="block">Try intersight with your team for free</span>
        <span className="block">Let yourself be surprised</span>
      </p>
      <Button
        variant="secondary"
        text="Start a Free Trial"
        className="mt-8 !bg-white"
        onClick={() => navigate("/get-started/sign-up")}
      />
    </div>
  );
};

export default GetStart;
