import BaseService from "../../../../../services/BaseService";
import { API_ENDPOINTS } from "../../../../../utils/api-endpoints";
import { RequestMethod } from "../../../../../utils/types";

export const GetNotifications = async () =>
  await BaseService(API_ENDPOINTS.GET_NOTIFICATIONS, RequestMethod.GET);

export const ReadNotification = async (id: string) =>
  await BaseService(
    `${API_ENDPOINTS.READ_NOTIFICATION}/${id}`,
    RequestMethod.POST
  );
export const ReadAllNotifications = async () =>
  await BaseService(API_ENDPOINTS.READ_ALL_NOTIFICATIONS, RequestMethod.POST);
