
import { useEffect, useState } from "react";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import {
  ChartsTypeEnum,
  FlowEnum,
  OptionsFilterType,
  ReportTypeEnum,
  StatProp,
  ViewEnum,
} from "../../../utils/types";
import type { SelectProps } from "antd";
import {
  DownloadCSVReport,
  GetCommunicationMetrics,
  GetConnectionsAnalysis,
  GetConnectivityRanks,
  GetConnectivityScope,
  GetFrequencyTime,
  GetInvolvementRecipients,
  GetMessagesVolume,
  GetStatistics,
  GetTopicsMenu,
} from "../api/service";
import { IChartData, initData, initStatisticsParameters } from "./data";
import { useAppDispatch } from "../../../hooks/useStore";
import dayjs from "dayjs";
import PermissionActions from "../../../modules/permissions/actions";
import usePermissionHandler from "./handleActionWithPermission";
import AuthSelectors from "../../../modules/auth/selectors";
import CommonSelectors from "../../../modules/common/selectors";

const useActions = () => {
  const { toastError, toastPromise } = useAlertsContext();
  const { fetchSubscriptionPermission } = PermissionActions();
  const { user } = AuthSelectors();
  const initialFilterOption = {
    flow: FlowEnum.ALL,
    view: ViewEnum.ALL,
    report: ReportTypeEnum.STATISTICS,
    userId1: user.id,
    userId2: undefined,
    dateRange: {
      from: dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
      to: dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
    },
    isSearch: false,
  };
  const { handleActionWithPermission } = usePermissionHandler();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [exportingReport, setExportingReport] = useState(false);
  const [filterOption, setFilterOption] =
    useState<OptionsFilterType>(initialFilterOption);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const collapseFilters = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [chartStates, setChartStates] =
    useState<Record<ChartsTypeEnum, IChartData>>(initData);
  const { insidersList, outsidersList } = CommonSelectors();
  const usersOptions: {
    outsiders: SelectProps["options"];
    insiders: SelectProps["options"];
  } = {
    outsiders: outsidersList,
    insiders: [{ label: user?.email, value: user?.id }, ...insidersList],
  };

  const [statisticsParameters, setStatisticsParameters] = useState<StatProp[]>(
    initStatisticsParameters[filterOption.report]
  );
  const fetchReportData = async (filters = filterOption) => {
    const chartEndpoints: Record<
      ReportTypeEnum,
      { type: ChartsTypeEnum; call: Function }[]
    > = {
      [ReportTypeEnum.STATISTICS]: [
        { type: ChartsTypeEnum.VOLUME, call: GetMessagesVolume },
        {
          type: ChartsTypeEnum.FREQUENCY_TIME,
          call: GetFrequencyTime,
        },
        {
          type: ChartsTypeEnum.CONNECTIVITY_SCOPE,
          call: GetConnectivityScope,
        },
        {
          type: ChartsTypeEnum.INVOLVEMENT_RECIPIENTS,
          call: GetInvolvementRecipients,
        },
      ],
      [ReportTypeEnum.CONNECTIONS]: [
        {
          type: ChartsTypeEnum.CONNECTIVITY_RANKS,
          call: GetConnectivityRanks,
        },
        {
          type: ChartsTypeEnum.CONNECTIVITY_ANALYSIS,
          call: GetConnectionsAnalysis,
        },
      ],
      [ReportTypeEnum.ANALYTICS]: [
        { type: ChartsTypeEnum.TOPICS, call: GetTopicsMenu },
        { type: ChartsTypeEnum.VOLUME, call: GetMessagesVolume },
        {
          type: ChartsTypeEnum.FREQUENCY_TIME,
          call: GetFrequencyTime,
          // call: GetRespondTimeDistribution,
        },
        {
          type: ChartsTypeEnum.COMMUNICATION,
          call: GetCommunicationMetrics,
        },
      ],
    };
    const reportTypeEndpoints = chartEndpoints[filters.report];
    reportTypeEndpoints.forEach(({ type }) => {
      setChartStates((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          loading: true,
          error: { isOccurred: false, message: "" },
        },
      }));
    });
    reportTypeEndpoints.forEach(async ({ type, call }) => {
      try {
        const customFilters =
          filters.report === ReportTypeEnum.ANALYTICS
            ? filters
            : { ...filters, user2: undefined };
        const result = await call(customFilters);
        setChartStates((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            loading: false,
            data: result.data,
          },
        }));
      } catch (error: any) {
        setChartStates((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            loading: false,
            error: {
              isOccurred: true,
              message: error?.message || "Error occurred",
            },
          },
        }));
      }
    });
    // Promise.allSettled(reportTypeEndpoints.map(({ call }) => call(filters)))
    //   .then((results) => {
    //     results.forEach((result, index) => {
    //       const { type } = reportTypeEndpoints[index];
    //       console.log({ result, index });
    //       if (result.status === "fulfilled") {
    //         setChartStates((prevState) => ({
    //           ...prevState,
    //           [type]: {
    //             ...prevState[type],
    //             loading: false,
    //             data: result.value.data,
    //           },
    //         }));
    //       } else {
    //         setChartStates((prevState) => ({
    //           ...prevState,
    //           [type]: {
    //             ...prevState[type],
    //             loading: false,
    //             error: {
    //               isOccurred: true,
    //               message: result.reason?.message || "Error occurred",
    //             },
    //           },
    //         }));
    //       }
    //     });
    //   })
    //   .catch((err) => toastError(err?.data?.message || "Error Occurred"));
  };
  const fetchStatistics = async (filters = filterOption) => {
    setLoading(true);
    const customFilters =
      filters.report === ReportTypeEnum.ANALYTICS
        ? filters
        : { ...filters, user2: undefined };
    await GetStatistics(customFilters)
      .then((stats) => {
        setStatisticsParameters(stats.data);
        dispatch(fetchSubscriptionPermission());
      })
      .catch((err) => toastError(err?.data?.message || "Error Occurred"))
      .finally(() => setLoading(false));
  };
  const handleFilterChange = (
    filters: OptionsFilterType,
    hasChanges = true,
    isSearch = false
  ) => {
    if (hasChanges) {
      handleActionWithPermission("CAN_SEARCH_REPORT", () => {
        setFilterOption(filters);
        fetchStatistics({ ...filters, isSearch: isSearch });
        fetchReportData(filters);
      });
    }
    setIsCollapsed(!isCollapsed);
  };
  const changeReportType = (report: ReportTypeEnum) => {
    setFilterOption({ ...filterOption, report });
  };
  // const fileNameFormatter = () => {
  //   let reportName = "";
  //   switch (filterOption.report) {
  //     case ReportTypeEnum.STATISTICS:
  //       reportName = "Statistics";
  //       break;
  //     case ReportTypeEnum.CONNECTIONS:
  //       reportName = "Connections";
  //       break;
  //     case ReportTypeEnum.ANALYTICS:
  //       reportName = "Analytics";
  //       break;
  //   }
  //   const fromDate = dayjs(filterOption.dateRange.from);
  //   const toDate = dayjs(filterOption.dateRange.to);

  //   const formattedString = `Intersight ${fromDate.format("D")}-${toDate.format(
  //     "D"
  //   )}.${fromDate.format("MM.YYYY")}`;
  //   return formattedString;
  // };
  const downloadReport = () => {
    setExportingReport(true);
    // ajax request after empty completing
    const promise = DownloadCSVReport(filterOption)
      .then((response) => {
        let fileName = "";
        const disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            // console.log(decodeURIComponent(matches[1].replace(/['"]/g, "")));
            fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
          }
        }
        // const fileName = fileNameFormatter();
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        toastError(e?.data);
      })
      .finally(() => setExportingReport(false));
    toastPromise(
      promise,
      `File exported successfully.`,
      `Error occurred during exporting the file.`
    );
  };
  const ExportReport = () => {
    handleActionWithPermission("EXPORT_CSV", downloadReport);
  };

  useEffect(() => {
    fetchStatistics();
    fetchReportData();
  }, []);

  return {
    statisticsParameters,
    loading,
    changeReportType,
    usersOptions,
    exportingReport,
    ExportReport,
    filterOption,
    setFilterOption,
    handleFilterChange,
    isCollapsed,
    collapseFilters,
    chartStates,
  };
};
export default useActions;
