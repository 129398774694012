import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { Link } from "react-router-dom";

const TermsEN = () => {
  return (
    <Section id="TermsUse" badge={"Terms of Use"} title="Terms of Use">
      <SEO title={"Terms of Service | InterSight"} />
      <div className="items-center justify-center flex flex-col">
        <div className="my-16 w-[90%] lg:w-[50%]">
          <p className="font-bold text-primaryBlue text-center max-md:text-start">
            Last Update on 19-12-2024
          </p>
          <div className="items-center justify-center mt-8">
            <div className="mb-10">
              <ul className="start-custom space-y-4 list-inside list-decimal">
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    The purpose of the system
                  </h2>
                  <p>
                    InterSight system is a digital platform that enables the
                    production of management communication reports, digitally.
                    The website and the system itself will be called
                    <span className="font-bold text-black">{` "the system" `}</span>
                    below.
                  </p>
                  <p>
                    The owner of the system may add services and/or products
                    with free access or for a fee at his discretion.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Definitions
                  </h2>
                  <p>
                    <strong>The user</strong> – any person and/or legal entity
                    that uses the system.
                  </p>
                  <div className="my-8 [&>p]:!mb-1 italic text-lg font-medium pl-4 border-l-2 border-l-primaryBlue">
                    <p>
                      <strong>The system</strong> – A system and reporting
                      system whose domain address is –
                      <Link
                        to="https://www.inter-sight.com"
                        target="_blank"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        www.inter-sight.com,
                      </Link>
                      including all its sub-domains
                    </p>
                    <p>
                      <strong>The owner and/or the service provider</strong> –
                      InterSight LTD 517017349
                    </p>
                    <p>
                      E-mail:
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com
                      </Link>
                    </p>
                    <p>
                      Phone:
                      <Link
                        dir="ltr"
                        to="tel:+972502000111"
                        className="mx-2 text-primaryBlue"
                      >
                        +972502000111
                      </Link>
                    </p>
                    <p>Address: Arnon St. 27 Ramat Gan</p>
                  </div>
                  <p>
                    <strong>Terms of use</strong> – This document on all its
                    instructions and sections.
                  </p>
                </li>
                <li className="[&>p]:!mb-1">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    General
                  </h2>
                  <p>
                    The headings in this document are for convenience only and
                    will not be used to interpret the Agreement.
                  </p>
                  <p>
                    These terms of use include, among other things, instructions
                    regarding privacy and instructions regarding copyright and
                    intellectual property. Please read this document carefully
                    before using the system.
                  </p>
                  <p>
                    The user agrees, in the act of browsing the system, to all
                    the terms of use. If the user does not fully and/or
                    partially agree to the terms of use, he must avoid any use
                    of the system.
                  </p>
                  <p className="font-bold">
                    The user declares that he/she is over the age of 18, and if
                    he/she is under the age of 18, then declares that the use of
                    the system is made with the consent of the person
                    responsible for it as required by law.
                  </p>
                  <p>
                    The terms of use are worded in male form for convenience but
                    are aimed at both genders. In this document singular also
                    means plural.
                  </p>
                  <p>
                    It is clarified and emphasized that these terms and
                    conditions, in their entirety, will bind any person who uses
                    the system, and/or purchases services or products through
                    it, and/or a user who purchased a product and/or service for
                    a third party.
                  </p>
                  <p>
                    The terms of use apply to any use of the system, by any
                    means and/or device whatsoever and in any geographic
                    location.
                  </p>
                </li>
                <li className="[&>p]:!mb-1">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Terms of privacy
                  </h2>
                  <p>
                    The system owner respects the privacy of the users. To read
                    the full
                    <Link
                      to="https://www.inter-sight.com/privacy-policy"
                      className="font-bold mx-2 text-primaryBlue"
                      target="_blank"
                    >
                      "privacy policy",
                    </Link>
                    please go to the privacy policy page.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Intellectual property
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The intellectual property in the system - including but
                      not limited to - trademarks, copyrights, patents, models
                      and/or designs, logos, databases, texts, images, videos,
                      sound clips, images, trade secrets, and/or any other
                      intellectual property, belong to the system owner, and/or
                      to a third party who allowed their use or presentation.
                      This is unless otherwise stated.
                    </li>
                    <li>
                      If you believe there has been a copyright infringement, or
                      infringement of another intellectual property right,
                      please contact us at
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com
                      </Link>
                      and we will settle the matter within a reasonable time of
                      receiving the reference.
                    </li>
                    <li>
                      The Company grants the user, through the system, a
                      non-exclusive license to perform, among other things, the
                      production of statistical, analytical, or contextual
                      management reports at the click of a button.
                    </li>
                    <li>
                      <p className="mb-1">
                        The license will be granted to users who conduct
                        business activities and are legally registered in
                        Israel.
                      </p>
                      <p>
                        The license will be granted according to the usage
                        package that will be purchased by the user ("
                        <strong>usage package</strong>") It is clarified that
                        each usage package allows a different scope of
                        permissions to perform operations. For more information
                        about the usage packages, the scope of operations
                        included in the license attached to them and their
                        costs, see
                        <Link
                          to="https://www.inter-sight.com/Pricing"
                          className="font-bold mx-2 text-primaryBlue"
                        >
                          https://www.inter-sight.com/Pricing
                        </Link>
                      </p>
                    </li>
                    <li>
                      The user of the system does not have permission to make
                      commercial use of the intellectual property in the system,
                      and/or any use that is contrary to the provisions of the
                      regulations unless he has received written permission for
                      this from the owner of the system.
                    </li>
                    <li>
                      Users undertake to not use the name of the system and/or
                      the software in any way that can mislead, harm the
                      reputation, create plagiarism, or in any other way that
                      can cause any damage to the system, the owner of the
                      system and/or to a third party.
                    </li>
                    <li>
                      The system may include hyperlinks that refer to external
                      systems and/or applications (hereinafter '
                      <strong>external systems</strong>') that are not owned by
                      the owner of the system and are not under his control. The
                      intellectual property on external systems is owned by a
                      third party and the owner of the system has no right in
                      them. The embedment of the links in the system does not
                      allow, or approve any action in relation to intellectual
                      property assets on the external sites. If you found the
                      external systems to be offensive or violating the law,
                      please let the system owner know as soon as possible at
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com.
                      </Link>
                    </li>
                    <li>
                      In addition, advertisements may appear in the system
                      originating from companies and/or businesses that are not
                      owned and/or under the responsibility of the owner of the
                      system. The owner of the system has no responsibility
                      regarding the content of these advertisements. If you come
                      across an offensive ad and/or an ad that violates the law,
                      please contact the owner of the system at
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com.
                      </Link>
                    </li>
                    <li>
                      Users undertake to not make any use of the works that
                      appear in the system, without obtaining permission in
                      advance.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Use of the system
                  </h2>
                  <p className="mb-1">
                    The user can make certain uses of the system, in a limited
                    way, some services are for free and at no cost and some
                    services require registration and payment.
                  </p>
                  <p>
                    The owner of the system may at any time, at his discretion,
                    change the methods and scope of use and/or registration to
                    the system, including the free and/or paid access options.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Limitation of Liability
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The system is stored on a secure server by an external
                      company. The owner of the system is not responsible for
                      any damage caused as a result of external hacking into the
                      system - among others - distribution/sale of information
                      as a result of hacking into the system, disruption of
                      information, infringement of copyright or other
                      intellectual property, or any other damage caused as a
                      result of tampering with the system and its contents.
                    </li>
                    <li>
                      It is clarified that it is the sole responsibility of the
                      user to protect and secure the end devices in his
                      possession and/or the communication networks, and/or his
                      servers and/or any hardware or software, against
                      information security incidents, and this is customary.
                    </li>
                    <li>
                      It is the user's responsibility to check, before using the
                      system, that the system is indeed suitable for his needs,
                      and the user will not have any claim due to the system not
                      being suitable for his needs.
                    </li>
                    <li>
                      The owner of the system is not responsible for the content
                      of external ads (images/links) that may appear/be
                      integrated into the system or for the content/information
                      that appears on websites to which the links lead. The
                      owner of the system and/or anyone on his behalf does not
                      monitor or check all the content on the websites to which
                      the links refer.
                    </li>
                    <li>
                      If you come across an offensive ad/link and/or an
                      offensive image and/or one that refers to a system with
                      offensive content, please notify the system owner at the
                      e-mail address
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com.
                      </Link>
                      and we will work to settle the matter as soon as possible.
                    </li>
                    <li className="font-bold">
                      The owner of the system publishes publications, content,
                      and shares professional knowledge, etc. in the system from
                      time to time (hereinafter "Publications"). What is said in
                      the publications is at the sole discretion of the system
                      owner and for review, and marketing purposes only. The
                      aforementioned publications should not be considered
                      professional advice, accounting advice, management advice,
                      strategic advice, and/or any other advice, and/or
                      recommendation, and/or guidance and they are not a
                      substitute for those and should not be relied upon as
                      such. In addition, there may be errors and/or inaccuracies
                      in the system as a result of good faith, and/or a
                      situation where the content of the publication will not be
                      current. The owner of the system will not be held
                      responsible for any damage caused to the user due to
                      reliance on what is stated in the publication and will not
                      be liable to the user for such reliance.
                    </li>
                    <li className="font-bold">
                      To the extent that a user of the system relies on what is
                      stated therein, he does so at his own choice and under his
                      sole responsibility and after being told that he must
                      consult with professionals and consultants relevant to the
                      field. A user who relied on the contents of the system
                      hereby waives any claim and/or demand and/or claim against
                      the owner of the system. The company will be exempt from
                      any compensation and/or charge and/or indemnification in
                      relation to damages caused to the user.
                    </li>
                    <li>
                      If any jurisdictions do not allow exclusions or
                      limitations as stated above, the exclusions and
                      limitations mentioned above will not apply in full, but
                      only to the maximum extent permitted by applicable law.
                    </li>
                    <li>
                      The owner of the system is not responsible, and the user
                      indemnifies the owner of the system from any damage -
                      monetary and/or otherwise, that may be caused to the user
                      and/or to a third party as a result of using the system's
                      services.
                    </li>
                    <li>
                      <strong>Limitation of liability</strong> on the part of
                      the system owner - in any case where it is determined by
                      an authorized party according to the law that the website
                      owner owes compensation, it is hereby agreed that the
                      liability of the website owner, towards the user and/or
                      towards any third party, will be limited to the amount of
                      the purchase made by the user, at most. For the avoidance
                      of doubt, it is clarified that the system owner's
                      liability is limited to direct damages only, and it will
                      not apply to indirect damages such as loss of profit,
                      damage to reputation, loss of customers, etc.
                    </li>
                    <li>
                      <strong>Force majeure</strong> - in the event that the
                      owner of the system cannot perform one and/or more of his
                      obligations towards the user, due to force majeure, the
                      date of performance of the obligation will be postponed to
                      another date, to be determined by the parties. Force
                      majeure in this agreement is a security incident, a
                      strike, natural disasters, epidemics and diseases such as
                      the coronavirus, and/or constraints due to a health
                      condition. To the extent that the system owner's services
                      cannot be postponed to an alternative date, this will not
                      be considered a violation, and the user will not be
                      entitled to make any claims and/or make any demands
                      against the system owner.
                    </li>
                    <li>
                      <strong>
                        The system is not responsible for creating and/or saving
                        backups for the user.
                      </strong>
                      The user has the sole responsibility to save and back up,
                      other than through the system, all materials produced by
                      him in the system or created while using it. In this
                      context, it is clarified that the system should not be
                      seen as a tool for fulfilling the obligation to preserve
                      information, insofar as such applies to another
                      representative on his behalf.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Use of the Services
                  </h2>
                  <p className="text-placeholder italic underline font-bold !mb-2">
                    Use of the report services and/or documents and/or forms of
                    the system In this section "
                    <strong className="text-black">content</strong>" - is any "
                    <strong className="text-black">work</strong>", as defined in
                    the Copyright Law 2007 and/or text, graphic, and/or other
                    content.
                  </p>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      As part of the use of the system services, the users may
                      share and/or allow the system access to data, and/or
                      contents and/or works (such as texts, graphic images,
                      logos, etc.). A user of the system hereby grants the owner
                      of the system an irrevocable license, which is not limited
                      in time and/or is not limited in geographical location, to
                      perform the actions necessary for the provision of system
                      services (including - copying the works, making them
                      available to the public, backing them up).
                    </li>
                    <li>
                      As part of the use of the system services, the users
                      hereby undertake that they have legal permission to share
                      the content with the system.
                    </li>
                    <li>
                      Users undertake to make only legal use of the system, and
                      also hereby undertake not to share content with the
                      system, and/or make any use of the system's services that
                      violates any copyright of a third party, and/or content
                      that causes any infringement of intellectual property
                      rights and/or content that causes any wrongdoing or other
                      offense, including - causing defamation, invasion of
                      privacy, incitement, incitement to racism.
                    </li>
                    <li>
                      The owner of the system is not responsible for the nature
                      and/or content of the content shared by the users, nor is
                      it responsible for their examination and/or control.
                    </li>
                    <li>
                      The users hereby undertake not to share and/or give access
                      to the system to humiliating, and/or offensive and/or
                      violent and/or inappropriate sexual content.
                    </li>
                    <li>
                      The owner of the system may block a user from using the
                      system's services, at his discretion, if he finds that he
                      violates one and/or more of the provisions of the rules of
                      this regulation, without giving the user any prior notice.
                      The user will not have any right to compensation and/or
                      indemnification for non-approval and/or removal of content
                      from the system.
                    </li>
                    <li>
                      A user who violates the terms of these regulations shall
                      compensate and indemnify the owner of the system for any
                      damage he may incur due to a claim and/or claim and/or
                      demand related to these contents, including legal expenses
                      that involve handling the matter, and this within 14 days
                      from the day he received the demand for compensation
                      and/or indemnification.
                    </li>
                    <li>
                      In the event that the user uses the system within the
                      framework of the workplace, it is the sole responsibility
                      of the user of the system to obtain permission from the
                      workplace for the use of the system services. The owner of
                      the system is not responsible for checking and/or
                      verifying the authority of a user to use the system on
                      behalf of his workplace.
                    </li>
                    <li>
                      The user hereby agrees and confirms that representatives
                      of the company will be allowed to log into the system
                      user's account at any time, among other things (but not
                      only) to perform technical tests and/or assist with any
                      questions and/or malfunctions in the system and/or its
                      operation.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Availability of the system
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The system owner does his best to ensure that it operates
                      properly and continuously. Nevertheless, there is no
                      commitment that it will work at all times. There may be
                      disruptions in availability/viewing/browsing, which may be
                      due, among other things, to updates and/or infrastructure
                      works, and/or malfunctions in the user's personal device,
                      and/or because of his Internet provider, an external
                      server, or due to malfunctions in his browsing
                      infrastructure. There may also be malfunctions resulting
                      from the system storage service, such as hacking or other
                      factors. The system owner will try to notify in advance of
                      planned interruptions in use but is not obligated to do
                      so.
                    </li>
                    <li>
                      The system owner reserves the right, at his discretion, to
                      stop and/or change the mode of operation of the system,
                      and/or to change these terms of use. The system owner will
                      not be liable to any third party for any monetary or other
                      damage, which may be caused to him as a result of stopping
                      the operation of the system and/or changing the way it is
                      operated.
                    </li>
                    <li>
                      Also, part of the system activity is based on external
                      third-party platforms, and there is no commitment that it
                      will work at all times, therefore certain
                      applications/services in the system may be damaged as a
                      result of the activity of the external platforms.
                    </li>
                    <li>
                      The system owner reserves, at his discretion, the right to
                      prevent and/or limit any user from using the system
                      without any notice. Restriction and/or prevention of use
                      will be taken against any user who violates one and/or
                      more of the provisions of the terms of use. The system
                      owner will not be responsible for any damage, monetary or
                      other, which may be caused to the user as a result of
                      limiting and/or preventing his ability to use the system.
                    </li>
                    <li>
                      It is the sole responsibility of the user to ensure that
                      the device he owns and its operating system are
                      appropriate and suitable for running the system.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Contacting and availability of the system owner
                  </h2>
                  <p>
                    <span className="block">
                      You can contact the owner of the system in the following
                      ways:
                    </span>
                    <Link
                      to="mailto:service@inter-sight.com"
                      className="font-bold mr-2 text-primaryBlue"
                    >
                      service@inter-sight.com.
                    </Link>
                    <span className="block">
                      The system owner will make an effort to respond to
                      inquiries no later than 5 business days from the date of
                      receipt of the inquiry.
                    </span>
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Prohibited Uses
                  </h2>
                  <ul className="nested-list list-inside pl-4">
                    <li>
                      User shall not perform any action directly or by a third
                      party, which may damage the system, and/or its browsing
                      availability, and/or the content presented in it, and/or
                      disrupt its operation. The user undertakes to not use
                      software code and/or any other electronic/digital and/or
                      automatic operation, including - a virus and/or a 'worm'
                      and/or a 'bot' for the purpose of damaging the system.
                    </li>
                    <li>
                      User undertakes to not perform any action in the system
                      that has the purpose of reverse assembly, and/or action
                      that aims to reveal the system code and/or other
                      components therein. Users undertake to not perform actions
                      in the system designed to discover the source code for the
                      purpose of competing in the system activity.
                    </li>
                    <li>
                      User undertakes to not perform in the system any operation
                      aimed at collecting and/or extracting data from the
                      system, with the exception of operations that are normal
                      and acceptable use of the system.
                    </li>
                    <li>
                      The system is intended for non-commercial use only. The
                      user and/or third party must not perform any action that
                      is intended to use the content of the system for a
                      commercial purpose and/or any other purpose. In this
                      section "content of the system" – text content, images,
                      designs, graphics, video clips and/or sound and/or
                      personal details of the users, statistical information
                      about the users' activity, software code.
                    </li>
                    <li>
                      The user undertakes not to make any use that is illegal,
                      and/or that is contrary to the terms of use of the system.
                    </li>
                    <li>
                      Without derogating from the aforementioned in the other
                      sections of the terms of use, the system owner reserves
                      the right to block a user and/or limit his access
                      immediately, to one and/or more of the services of the
                      system, in the event that the user has violated one and/or
                      more of the provisions of these terms of use.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    User Responsibility
                  </h2>
                  <p>
                    The user alone is responsible for any damage caused as a
                    result of a violation of the terms of use, and he will
                    indemnify the system owner for any financial or other damage
                    caused as a result of a violation of the terms of use,
                    including legal and/or other expenses that will be related
                    to the violation of the terms of use.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Terms of Digital product sales
                  </h2>
                  <ul className="nested-list list-disc list-inside pl-4">
                    <li>
                      The purchase of digital products through the system is
                      subject to what is stated in this document.
                    </li>
                    <li>Prices on the system include VAT.</li>
                    <li>
                      The system owner may update the prices of the products
                      and/or services from time to time, without prior notice.
                      The updated prices will appear on the system.
                    </li>
                    <li>
                      The buyer declares that he is purchasing the system
                      services for
                      <strong>personal and non-commercial use</strong>. Resale
                      of the services and/or their marketing is prohibited.
                      Also, any other commercial and/or marketing use of the
                      products is prohibited, unless permission has been
                      received from the system owner.
                    </li>
                    <li>
                      Inquiries to the system owner on various topics, customer
                      service, questions and inquiries will be possible by
                      contacting the email address
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mr-2 text-primaryBlue"
                      >
                        service@inter-sight.com.
                      </Link>
                      or by phone during business hours.
                    </li>
                    <li>
                      A user's account, who does not pay the consideration on
                      time, will be blocked by the system owner, and the user
                      will not be able to use the system until after the payment
                      is settled.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Registering and gaining access to the services:
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      Access to perform certain operations will only be possible
                      for a user who has registered in the system and provided
                      the company with certain details, which will be determined
                      by the company ("<strong>user account</strong>" or "
                      <strong>the account</strong>").
                    </li>
                    <li>
                      The user may order a number of sub-users under the same
                      user account, according to the usage package he purchased
                      from the company, provided that each sub-user has a
                      different registration number (ID). It is hereby clarified
                      that it is not possible to establish in the system under
                      the same user account with the same registration number.
                    </li>
                    <li>
                      When registering and/or purchasing, the user undertakes to
                      provide accurate and up-to-date identification details.
                    </li>
                    <li>
                      It is forbidden to create an account for a person who is
                      not present in front of the computer monitor at the time
                      of registration or who does not approve the terms of use.
                      Regarding these instructions, it should be emphasized that
                      impersonation is a criminal offense;
                    </li>
                    <li>
                      Regarding a user who is a corporation, the contact person
                      who actually opened the account on behalf of the user who
                      is a corporation ("the original authorized person") will
                      be considered by the company as a user for all intents and
                      purposes.
                    </li>
                    <li>
                      A user who is a corporation, who wishes to replace the
                      original license with a new license, will submit to the
                      system owner a confirmation form detailing the new user,
                      who is authorized to act on behalf of the corporation. The
                      user will attach a lawyer's verification that the new
                      licensee is legally authorized to act on behalf of the
                      corporation regarding the use of the system. ("Authorized
                      form") Upon receipt of an authorized form, the owner of
                      the system will update the new licensee and will consider
                      him as a user for all intents and purposes, and the
                      original licensee will have all privileges and rights in
                      connection with the system revoked.
                    </li>
                    <li>
                      <p>
                        The user undertakes to observe the following rules in
                        relation to the password that will be used to access the
                        user's account and to perform operations in the system
                        that are only possible for the user's account holders
                        (the "password")
                        <span className="block font-bold">
                          The password must contain 8-16 characters.
                        </span>
                        <span className="block font-bold">
                          The password must not be revealed to another or
                          entered in an e-mail message.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Trial Period
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The system will be entitled to offer the user, upon
                      registration to the system, a free trial period for 14
                      days, which begins with the activation of the account and
                      confirmation of the email address by the system (the "
                      <strong>trial period</strong>") In the event that such a
                      trial period is offered, the following conditions will
                      apply.
                    </li>
                    <li>
                      During the trial period, the system will be open for use
                      according to the basic usage package and the production of
                      statistical reports only will be possible. The trial
                      period is given to the user without any conditions,
                      including the lack of commitment to the absence of bugs or
                      unavailability of the system during the trial period. For
                      the avoidance of doubt, it is clarified that during the
                      trial period, the operations included in the basic route
                      will be open for execution, and additional operations may
                      be opened in accordance with the company's sole
                      discretion, as will be updated from time to time.
                    </li>
                    <li>
                      Each user will have the right reserved for only one trial
                      period, and for this matter, the third party acting in
                      favor of that user will also be considered as having
                      exhausted the right.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Purchase Process
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The payment for the license to use the system is according
                      to the usage package desired by the user and according to
                      the monthly billing routes.
                    </li>
                    <li>
                      The registration for obtaining a license will be carried
                      out as part of a usage package with a monthly billing
                      route for an unlimited period. The billing date of the
                      package applies every month (for that month, in advance),
                      until canceled by the user below ("
                      <strong>Monthly usage package</strong>").
                    </li>
                    <li>
                      A transaction to purchase a usage package, add-on, or any
                      other service will be charged immediately. (unless the
                      system chose, at its sole discretion, to charge
                      retroactively at the end of the relevant period or the end
                      of the consumption of the relevant service). The immediate
                      (first) charge may include both the expected consideration
                      for the remainder of the calendar month in which the
                      transaction was made and the advance consideration for the
                      full calendar month following the month in which the
                      transaction was made. The periodic charges for the
                      following periods after the first charge will be made at
                      the beginning of each period, in advance.
                    </li>
                    <li>
                      <span className="font-bold">
                        The system will be entitled, at any time:
                      </span>
                      <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                        <li>
                          cancel an existing usage package/existing add-on, for
                          any reason;
                        </li>
                        <li>
                          increase the price of a usage/add-on package; Provided
                          that the user will be sent a notice at least 30 days
                          in advance in writing prior to the cancellation or
                          price increase (as applicable) (hereinafter: "the
                          advance notice") and the cancellation or price
                          increase (as applicable) will take effect at the end
                          of the advance notice period.
                        </li>
                        <li>
                          The company will allow the user to choose whether to
                          agree to the update or terminate the contract with
                          respect to the relevant usage/add-on package. If the
                          user has paid in advance for a period after the end of
                          the advance notice period, that user will be entitled
                          to a proportional refund for the excess consideration
                          in the event of termination of the aforementioned
                          contract
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Payment methods
                  </h2>
                  <span className="font-bold block">
                    {"Transactions can be made in the system as follows:"}
                  </span>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      Credit card payment - through a monthly credit card
                      payment. The subscription is automatically renewed every
                      month, as long as no cancellation notice has been received
                      from the user.
                    </li>
                    <li>
                      A transaction carried out on credit is subject to the
                      approval of the credit companies. The owner of the system
                      may change, add or subtract payment methods and/or means
                      for any reason and without prior notice.
                    </li>
                    <li>
                      The buyer declares that he is the owner of the payment
                      method, or that he has received permission to use the card
                      or the payment method from its owner.
                    </li>
                    <li>
                      The buyer will mark and select the subscription he wishes
                      to purchase. The buyer will enter identifying information,
                      contact information, and credit information. The buyer
                      will confirm the correctness of the details and confirm
                      that he has read the regulations of the system. The
                      transaction will be approved after receiving approval from
                      the credit company, the clearing company, and/or receiving
                      a reference regarding the receipt of a bank transfer.
                    </li>
                    <li>
                      It is hereby clarified that the buyer's credit information
                      is not kept by the owner of the system. The credit card
                      details will be processed and/or saved by the clearing
                      company only.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Subscription Cancellations
                  </h2>
                  <span className="font-bold block">
                    Cancellation of a monthly billing plan usage package: A user
                    wishing to cancel a monthly billing plan usage package may
                    do so at any time, by notifying the company in one of the
                    ways specified in the section below, and the following rules
                    will apply:
                  </span>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The cancellation will take effect at the end of the
                      calendar month in which the notice was given, the user
                      will not be entitled to any credit, but he will be able to
                      use the services that the usage package allows him until
                      the end of that calendar month, subject to the terms of
                      the usage package.
                    </li>
                    <li>
                      A user who wishes to cancel a transaction made with the
                      company, who has the right to cancel as stated above, In
                      relation to all types of usage packages and services, may
                      do so in any of the following ways:
                      <ul className="list-inside list-disc pl-4 mt-2">
                        <li>
                          by e-mail, to the email address
                          <Link
                            to="mailto:service@inter-sight.com"
                            className="font-bold mx-2 text-primaryBlue"
                          >
                            service@inter-sight.com
                          </Link>
                        </li>
                        <li>by representative support system</li>
                        <li>
                          by clicking the "cancel service" button in the system
                          on the user's profile page
                        </li>
                      </ul>
                    </li>
                    <li>
                      The provisions of the law will apply to the cancellation
                      of a transaction through the system, and to the extent
                      that there is a contradiction between what is stated in
                      the regulations and the applicable law, then the
                      provisions of the law will prevail.
                    </li>
                    <li>
                      Cancellation of a transaction - according to the Consumer
                      Protection Law, there is no right to cancel a transaction
                      on "information", as defined in the Computer Law 1995, and
                      on goods that can be recorded for reproduction or
                      duplication.
                    </li>
                    <li>
                      It is clarified that to the extent that the purchaser has
                      gained access and/or used and/or viewed some and/or all of
                      the digital content, he will not be entitled to a refund.
                    </li>
                    <li>
                      Along with the above, a buyer may submit a request to
                      cancel a transaction, in special circumstances, he must
                      submit the request to the owner of the system, using the
                      contact methods detailed in these regulations, and she
                      will consider them according to the circumstances of the
                      matter.
                    </li>
                    <li>
                      A notification regarding the cancellation of a transaction
                      must be sent to the system owner in one of the following
                      ways:
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com,
                      </Link>
                      It is clarified that the system is intended for business
                      customers, thus the cancellation conditions according to
                      the Consumer Protection Law do not apply to transactions
                      cancellations.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Updates and changes
                  </h2>
                  <ul className="nested-list list-decimal list-inside pl-4 mt-2">
                    <li>
                      The system owner reserves the right to update and/or
                      change from time to time and as necessary the system terms
                      of use.
                    </li>
                    <li>
                      The validity of the changes will be from the day the
                      change was made.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Jurisdiction
                  </h2>
                  <p>
                    The law applicable to any dispute regarding these terms of
                    use shall be Israeli law and the exclusive jurisdiction in
                    all matters relating to these terms of use shall be to a
                    court having jurisdiction in Tel Aviv or Jerusalem, Israel.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default TermsEN;
