import BaseService from "../../services/BaseService";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import {
  ISubscription,
  IUserSettings,
  RequestMethod,
  ResponseType,
  UserType,
} from "../../utils/types";

export const RefreshToken = async (
  accessToken: string
): Promise<{ token: string; expiresAt: string }> =>
  await BaseService(API_ENDPOINTS.REFRESH_TOKEN, RequestMethod.POST, {
    accessToken,
  });

export const GetProfile = async () =>
  await BaseService(API_ENDPOINTS.GET_PROFILE, RequestMethod.GET);

export const GoogleLogIn = async (
  code: string
): Promise<ResponseType<UserType>> =>
  await BaseService(API_ENDPOINTS.GOOGLE_LOG_IN, RequestMethod.POST, {
    code,
    redirect_uri: `${process.env.REACT_APP_SITE_URL}google-callback`,
  });

export const MSLogIn = async (
  code: string,
  codeVerifier: string
): Promise<ResponseType<UserType>> =>
  await BaseService(API_ENDPOINTS.MS_LOG_IN, RequestMethod.POST, {
    code,
    codeVerifier,
    redirect_uri: `${process.env.REACT_APP_SITE_URL}ms-callback`,
  });

export const DeleteMyAccount = async (): Promise<ResponseType<string>> =>
  await BaseService(API_ENDPOINTS.DELETE_ACCOUNT, RequestMethod.POST);

export const GetSettings = async (): Promise<ResponseType<IUserSettings>> =>
  await BaseService(API_ENDPOINTS.GET_SETTINGS, RequestMethod.GET);

export const UpdateSettings = async (
  data: IUserSettings
): Promise<ResponseType<any>> =>
  await BaseService(API_ENDPOINTS.UPDATE_SETTINGS, RequestMethod.POST, data);

export const UpdateProfile = async (data: {
  organization: string;
  country: string;
  address: string;
}): Promise<ResponseType<any>> =>
  await BaseService(API_ENDPOINTS.UPDATE_PROFILE, RequestMethod.POST, data);

export const GetSubscription = async (): Promise<ResponseType<ISubscription>> =>
  await BaseService(API_ENDPOINTS.GET_SUBSCRIPTION, RequestMethod.GET);
