import { useLayoutEffect, useRef } from "react";
import Button from "../../../components/Button";
import { FcUnlock } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
// const useMutationObserver = (
//   ref: any,
//   callback: any,
//   options = {
//     attributes: true,
//     characterData: true,
//     childList: true,
//     subtree: true,
//   }
// ) => {
//   useEffect(() => {
//     if (ref.current) {
//       const observer = new MutationObserver(callback);
//       observer.observe(ref.current, options);
//       return () => observer.disconnect();
//     }
//   }, [callback, options, ref]);
// };
const NeedSubscription = ({ title }: { title?: string }) => {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const overlay = document.getElementById("upgrade-overlay");
    if (!overlay) {
      console.error("Overlay element not found.");
      return;
    }
    // Prevent right-click on the overlay
    overlay.addEventListener("contextmenu", (e) => e.preventDefault());
    // Disable F12 and Ctrl+Shift+I to prevent dev tools
    overlay.addEventListener("keydown", (e) => {
      if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
        e.preventDefault();
      }
    });
    // Create the MutationObserver to track changes to the overlay
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // Check if the overlay is removed
        if (!document.body.contains(overlay)) {
          window.location.reload();
        }
        // Check for style changes to the overlay
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          const computedStyle = window.getComputedStyle(overlay);

          if (
            computedStyle.display === "none" ||
            computedStyle.visibility === "hidden" ||
            computedStyle.opacity === "0"
          ) {
            window.location.reload();
          }
        }
        if (mutation.attributeName === "class") {
          const overlayClasses = overlay.className.split(" ");
          // List of Tailwind classes that indicate the overlay is hidden
          const unauthorizedClasses = [
            "hidden",
            "opacity-0",
            "invisible",
            "z-[-1]", // Low z-index that hides the element
          ];

          if (overlayClasses.some((cls) => unauthorizedClasses.includes(cls))) {
            window.location.reload();
          }
        }
      });
    });
    // Observe changes on the body, including the overlay
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true, // Enable attribute observation
      attributeFilter: ["style", "class"], // Watch only the "style" attribute
    });
    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const mutationRef = useRef(null);
  // useMutationObserver(mutationRef, () => console.log("MutationObserver"));
  return (
    <div
      ref={mutationRef}
      id="upgrade-overlay"
      className="absolute size-full z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center bg-black/15 rounded-lg"
    >
      <div className="bg-white gap-2 flex flex-col justify-center items-center lg:max-w-[50%] w-[80%] rounded-lg p-6">
        <FcUnlock size={32} />
        <span className="text-placeholder text-center">
          Unlock <span className="font-bold">{title}</span>, by upgrading
          InterSight Plan
        </span>
        <Button
          text="Upgrade"
          className="min-w-[60%]"
          onClick={() => navigate("/Dashboard/plans")}
        />
      </div>
    </div>
  );
};

export default NeedSubscription;
