import * as React from "react";
import NavItem from "../../components/ui/Navbar/NavItem";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import BorderBeam from "../../components/BorderBeam";
import AnimatedLogo from "./AnimatedLogo";

const DesktopBar = ({
  items,
  isHome,
  visible,
}: {
  items: { text: string; to: string }[];
  isHome: boolean;
  visible: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`box-border shrink-0 w-full transition-all duration-300 group self-center z-40 items-center justify-center fixed top-0 ${
        visible
          ? "bg-transparent"
          : "backdrop-filter backdrop-blur-lg bg-white/35"
      } ${!isHome && "bg-white"}`}
    >
      <header
        className={`flex relative gap-4 w-full justify-between items-center px-12 max-md:px-5`}
      >
        <div className="flex-1">
          <AnimatedLogo />
        </div>
        <nav
          className={
            "flex flex-[2] gap-2 justify-center my-auto text-center max-md:flex-wrap"
          }
        >
          {items.map((item) => (
            <NavItem key={item.text} text={item.text} to={item.to} />
          ))}
        </nav>

        <div className="flex gap-4 items-center justify-end flex-1">
          <Button
            text={"Sign In"}
            variant={"secondary"}
            onClick={() => navigate("/get-started/Login")}
            className="!px-4 !py-2 !max-md:px-5"
          />
          <div className="relative">
            <Button
              text={"Try for free"}
              onClick={() => navigate("/get-started/sign-up")}
              className="justify-center items-center !px-8 !py-2 !my-0 !border-none text-white bg-primaryBlue max-md:text-xs"
            />
            <BorderBeam
              size={50}
              duration={8}
              delay={9}
              colorFrom={"#adffd8"}
              colorTo={"#669CF4"}
              className="rounded-lg"
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default DesktopBar;
