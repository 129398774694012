import React from "react";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
} from "recharts";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  IInvolvement,
} from "../../../../utils/types";
import Wrapper from "./Wrapper";
const chartConfig = {
  currentPeriod: {
    label: "Current Period",
  },
  lastPeriod: {
    label: "Last Period",
  },
  yearlyAvg: {
    label: "Yearly Avg",
  },
} satisfies ChartConfig;
const Involvement = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<IInvolvement>;
}) => {
  const isOutFlow = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading, needSubscription } = details;
  return (
    <Wrapper
      className={className}
      cardFilters={filters}
      loading={loading}
      error={error}
      needSubscription={needSubscription}
      content={
        <ChartContainer config={chartConfig} className="w-full">
          <ComposedChart
            accessibilityLayer
            data={data}
            margin={{
              left: -20,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              // tickFormatter={(value) => value.slice(0, 3)}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickCount={3}
            />
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Area
              dataKey="currentPeriod"
              type="natural"
              fill={`var(--chart-current-period-${isOutFlow})`}
              fillOpacity={0.85}
              stroke={`var(--chart-current-period-${isOutFlow})`}
              stackId="a"
            />
            <Area
              dataKey="lastPeriod"
              type="natural"
              fill={`var(--chart-last-period-${isOutFlow})`}
              fillOpacity={0.85}
              stroke={`var(--chart-last-period-${isOutFlow})`}
              stackId="b"
            />
            <Line
              type="monotone"
              dataKey="yearlyAvg"
              stroke={`var(--chart-yearly-avg-${isOutFlow})`}
              strokeWidth={1.5}
              // hide
              dot={false}
            />
            {/* <Area
              dataKey="yearlyAvg"
              type="natural"
              fill={`var(--chart-yearly-avg-${isOutFlow})`}
              fillOpacity={0.85}
              stroke={`var(--chart-yearly-avg-${isOutFlow})`}
              stackId="c"
            /> */}
            <ChartLegend content={<ChartLegendContent />} />
          </ComposedChart>
        </ChartContainer>
      }
    />
  );
};

export default Involvement;
