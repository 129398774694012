
import { useAppSelector } from "../../hooks/useStore";

const CommonSelectors = () => {
  const pricingTiers = useAppSelector((state) => state.common.pricing);
  const outsidersList = useAppSelector((state) => state.common.outsidersList);
  const insidersList = useAppSelector((state) => state.common.insidersList);
  return {
    pricingTiers,
    outsidersList,
    insidersList,
  };
};

export default CommonSelectors;
