import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import Icon from "../../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import { Form, FormInstance, Input } from "antd";
import Button from "../../../../components/Button";
import useActions from "../hooks/useActions";
import { useParams } from "react-router-dom";
import { UserRoundPlus } from "lucide-react";
import usePermissionHandler from "../../hooks/handleActionWithPermission";
import { useMediaQuery } from "react-responsive";

const InviteMemberModal = ({
  form,
  showModal,
  dismiss,
  submit,
  loading,
}: {
  form: FormInstance;
  showModal: boolean;
  dismiss: () => void;
  submit: Function;
  loading: boolean;
}) => {
  const onFinish = (values: any) => {
    submit(values);
  };
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold">{"Invite members to your team"}</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        We will send a confirmation email to each member you invite. Once they
        accept, you will be able to see their email statistics.
      </p>
      <div className="mt-8">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            required
            name="email"
            initialValue={""}
            rules={[
              {
                type: "email",
                required: true,
                message: `Email is required!`,
              },
            ]}
          >
            <Input placeholder="example@company.com" className="py-2" />
          </Form.Item>

          <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              disabled={loading}
              type={"button"}
              variant="secondary"
              onClick={() => dismiss()}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Send invite"}
              type={"submit"}
              variant={!submittable ? "disabled" : "primary"}
              loading={loading}
              className={`"justify-center items-center !px-12 !py-2 ${
                !submittable ? "!text-gray-400" : "text-white bg-primaryBlue"
              }   !max-md:px-5"`}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const InviteMember = ({ fetchTeams }: { fetchTeams: Function }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { InviteMember, loading } = useActions();
  const { teamId } = useParams();
  const [form] = Form.useForm();
  const { handleActionWithPermission } = usePermissionHandler();

  const AddTeam = ({ email }: { email: string }) => {
    setIsSubmitting(true);
    InviteMember(email, teamId || "", (isOk: boolean) => {
      if (isOk) {
        fetchTeams();
        setModalVisible(false);
        form.resetFields();
      }
      setIsSubmitting(false);
    });
  };
  const DismissModal = () => {
    setModalVisible(false);
    form.resetFields();
  };
  const inviteMember = () => {
    handleActionWithPermission("CAN_INVITE_INSIDERS", () =>
      setModalVisible(true)
    );
    
  };
  const isMobile = useMediaQuery({ maxWidth: "767px" });

  return (
    <>
      <Button
        text={isMobile ? undefined : "Invite member"}
        onClick={inviteMember}
        variant="primary"
        className={`flex items-center gap-2 p-4 rounded-xl cursor-pointer shadow-sm h-full max-md:h-auto !my-0`}
        icon={<UserRoundPlus />}
      />
      {modalVisible ? (
        <InviteMemberModal
          form={form}
          showModal={modalVisible}
          loading={isSubmitting && !loading}
          dismiss={() => DismissModal()}
          submit={AddTeam}
        />
      ) : null}
    </>
  );
};
export default InviteMember;
