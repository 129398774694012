
import { ActivityStatus as ActivityStatusEnum } from "../../../../utils/types";
import { cn } from "../../../../utils/utils";
import { Badge, BadgeAlert, BadgeCheck, BadgeInfo, BadgeX } from "lucide-react";
import React from "react";

const ActivityStatus = ({
  activity,
  small,
}: {
  activity: { id: ActivityStatusEnum; name: string };
  small?: boolean;
}) => {
  const defaultClass =
    "inline-flex gap-2 items-center px-4 whitespace-nowrap py-1 border rounded-full w-fit";

  const activityConfig = {
    [ActivityStatusEnum.Active]: {
      bg: "bg-green-100 text-green-800 border-green-400",
      icon: <BadgeCheck color={"#166534"} size={small ? 12 : 18} />,
      label: "Active",
    },
    [ActivityStatusEnum.RecentlyActive]: {
      bg: "bg-blue-100 text-blue-800 border-blue-400",
      icon: <BadgeInfo color={"#2563eb"} size={small ? 12 : 18} />,
      label: "Recently Active",
    },
    [ActivityStatusEnum.LowActive]: {
      bg: "bg-yellow-100 text-yellow-800 border-yellow-400",
      icon: <Badge color={"#ca8a04"} size={small ? 12 : 18} />,
      label: "Low Active",
    },
    [ActivityStatusEnum.RarelyActive]: {
      bg: "bg-orange-100 text-orange-800 border-orange-400",
      icon: <Badge color={"#ea580c"} size={small ? 12 : 18} />,
      label: "Rarely Active",
    },
    [ActivityStatusEnum.Inactive]: {
      bg: "bg-red-100 text-red-600 border-red-300",
      icon: <BadgeAlert color={"#dc2626"} size={small ? 12 : 18} />,
      label: "Inactive",
    },
    [ActivityStatusEnum.Deactive]: {
      bg: "bg-red-100 text-red-600 border-red-300",
      icon: <BadgeAlert color={"#dc2626"} size={small ? 12 : 18} />,
      label: "Deactive",
    },
    [ActivityStatusEnum.None]: {
      bg: "bg-gray-100 text-gray-500 border-gray-300",
      icon: <BadgeX color={"#6b7280"} size={small ? 12 : 18} />,
      label: "None",
    },
  };

  const config = activityConfig[activity.id];

  if (!config) return null;

  return (
    <div className={cn(defaultClass, config.bg, small && "text-xs !px-2")}>
      {config.icon}
      <span className="font-medium">{config.label}</span>
    </div>
  );
};

export default ActivityStatus;
