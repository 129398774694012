
import {
  ChartsTypeEnum,
  FlowEnum,
  ICommunicationMetric,
  IConnectivityAnalysis,
  IConnectivityRanks,
  IScope,
  ITopics,
  IVolume,
  PercentageEnum,
  ReportTypeEnum,
} from "../../../utils/types";
export const initData = {
  [ChartsTypeEnum.VOLUME]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as IVolume[],
  },
  [ChartsTypeEnum.FREQUENCY_TIME]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as IVolume[],
  },
  [ChartsTypeEnum.INVOLVEMENT_RECIPIENTS]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as IVolume[],
  },
  [ChartsTypeEnum.CONNECTIVITY_SCOPE]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as IScope[],
  },
  [ChartsTypeEnum.COMMUNICATION]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as ICommunicationMetric[],
  },
  [ChartsTypeEnum.TOPICS]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as ITopics[],
  },
  [ChartsTypeEnum.CONNECTIVITY_RANKS]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as IConnectivityRanks[],
  },
  [ChartsTypeEnum.CONNECTIVITY_ANALYSIS]: {
    loading: true,
    needSubscription: false,
    error: { isOccurred: false, message: "" },
    data: [] as IConnectivityAnalysis[],
  },
};
export const chartData = {
  [ChartsTypeEnum.VOLUME]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      { date: "January", currentPeriod: 186, lastPeriod: 80, yearlyAvg: 266 },
      {
        date: "February",
        currentPeriod: 305,
        lastPeriod: 200,
        yearlyAvg: 305,
      },
      { date: "March", currentPeriod: 237, lastPeriod: 120, yearlyAvg: 157 },
      { date: "April", currentPeriod: 73, lastPeriod: 190, yearlyAvg: 263 },
      { date: "May", currentPeriod: 209, lastPeriod: 130, yearlyAvg: 239 },
      { date: "June", currentPeriod: 214, lastPeriod: 140, yearlyAvg: 154 },
    ] as IVolume[],
  },
  [ChartsTypeEnum.FREQUENCY_TIME]: {
    loading: false,
    error: { isOccurred: true, message: "sss" },
    data: [
      { date: "January", currentPeriod: 186, lastPeriod: 80, yearlyAvg: 266 },
      {
        date: "February",
        currentPeriod: 305,
        lastPeriod: 200,
        yearlyAvg: 205,
      },
      { date: "March", currentPeriod: 237, lastPeriod: 120, yearlyAvg: 157 },
      { date: "April", currentPeriod: 73, lastPeriod: 190, yearlyAvg: 206 },
      { date: "May", currentPeriod: 209, lastPeriod: 130, yearlyAvg: 301 },
      { date: "June", currentPeriod: 214, lastPeriod: 140, yearlyAvg: 280 },
    ] as IVolume[],
  },
  [ChartsTypeEnum.INVOLVEMENT_RECIPIENTS]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      { date: "January", currentPeriod: 186, lastPeriod: 80, yearlyAvg: 266 },
      { date: "February", currentPeriod: 305, lastPeriod: 200, yearlyAvg: 305 },
      { date: "March", currentPeriod: 237, lastPeriod: 120, yearlyAvg: 157 },
      { date: "April", currentPeriod: 73, lastPeriod: 190, yearlyAvg: 263 },
      { date: "May", currentPeriod: 209, lastPeriod: 130, yearlyAvg: 239 },
      { date: "June", currentPeriod: 214, lastPeriod: 140, yearlyAvg: 154 },
    ] as IVolume[],
  },
  [ChartsTypeEnum.CONNECTIVITY_SCOPE]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      {
        id: 1,
        title: "Current Period",
        parameter: "currentPeriod",
        total: 25,
        percentage: 49,
      },
      {
        id: 2,
        title: "Last Period",
        parameter: "lastPeriod",
        total: 23,
        percentage: 41,
      },
      {
        id: 3,
        title: "Yearly Avg",
        parameter: "yearlyAvg",
        total: 18,
        percentage: 36,
      },
    ] as IScope[],
  },
  [ChartsTypeEnum.COMMUNICATION]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      {
        parameter: "Subjects",
        currentPeriod: 186,
        lastPeriod: 80,
        yearlyAvg: 90,
      },
      {
        parameter: "Inflow",
        currentPeriod: 209,
        lastPeriod: 130,
        yearlyAvg: 150,
      },
      {
        parameter: "Response",
        currentPeriod: 73,
        lastPeriod: 190,
        yearlyAvg: 120,
      },
      {
        parameter: "Involvement",
        currentPeriod: 305,
        lastPeriod: 200,
        yearlyAvg: 80,
      },
      {
        parameter: "Outflow",
        currentPeriod: 237,
        lastPeriod: 120,
        yearlyAvg: 190,
      },
    ] as ICommunicationMetric[],
  },
  [ChartsTypeEnum.TOPICS]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      {
        id: 1,
        name: "Welcome to our service",
        count: 22,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "lucas.brown@example.com",
          "sophia.smith@example.com",
          "ethan.wilson@example.com",
          "isabella.jackson@example.com",
          "mia.clark@example.com",
          "mason.lee@example.com",
          "layla.harris@example.com",
          "william.anderson@example.com",
          "ella.white@example.com",
          "james.thomas@example.com",
        ],
      },
      {
        id: 2,
        name: "Account verification required",
        count: 29,
        emails: [
          "mia.clark@example.com",
          "mason.lee@example.com",
          "layla.harris@example.com",
          "william.anderson@example.com",
          "ella.white@example.com",
          "james.thomas@example.com",
          "harper.lewis@example.com",
          "benjamin.moore@example.com",
          "aria.hall@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 3,
        name: "Your password has been updated",
        count: 30,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "sophia.smith@example.com",
          "ethan.wilson@example.com",
          "isabella.jackson@example.com",
          "mason.lee@example.com",
          "layla.harris@example.com",
          "william.anderson@example.com",
          "ella.white@example.com",
          "james.thomas@example.com",
          "harper.lewis@example.com",
          "aria.hall@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 4,
        name: "Security alert",
        count: 14,
        emails: [
          "william.anderson@example.com",
          "ella.white@example.com",
          "harper.lewis@example.com",
          "benjamin.moore@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 5,
        name: "Your subscription is ending soon",
        count: 32,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
        ],
      },
      {
        id: 6,
        name: "Security alert",
        count: 14,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "lucas.brown@example.com",
          "sophia.smith@example.com",
          "ethan.wilson@example.com",
          "isabella.jackson@example.com",
          "mia.clark@example.com",
          "mason.lee@example.com",
          "layla.harris@example.com",
          "william.anderson@example.com",
          "ella.white@example.com",
          "james.thomas@example.com",
          "harper.lewis@example.com",
          "benjamin.moore@example.com",
          "aria.hall@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 7,
        name: "Security alert",
        count: 14,
        emails: ["emma.johnson@example.com"],
      },
      {
        id: 8,
        name: "Important account notice",
        count: 24,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "lucas.brown@example.com",
          "sophia.smith@example.com",
          "ethan.wilson@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 9,
        name: "Weekly newsletter",
        count: 17,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "lucas.brown@example.com",
          "sophia.smith@example.com",
          "ethan.wilson@example.com",
          "isabella.jackson@example.com",
          "mia.clark@example.com",
          "mason.lee@example.com",
          "layla.harris@example.com",
          "william.anderson@example.com",
          "ella.white@example.com",
          "james.thomas@example.com",
          "harper.lewis@example.com",
          "benjamin.moore@example.com",
          "aria.hall@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 10,
        name: "Special offer just for you",
        count: 26,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "lucas.brown@example.com",
          "sophia.smith@example.com",
          "ethan.wilson@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 11,
        name: "Account verification required",
        count: 29,
        emails: [
          "emma.johnson@example.com",
          "liam.wilson@example.com",
          "olivia.davis@example.com",
          "noah.martinez@example.com",
          "ava.taylor@example.com",
          "lucas.brown@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
      {
        id: 12,
        name: "Important account notice",
        count: 24,
        emails: [
          "aria.hall@example.com",
          "henry.turner@example.com",
          "scarlett.adams@example.com",
        ],
      },
    ] as ITopics[],
  },
  [ChartsTypeEnum.CONNECTIVITY_RANKS]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      {
        title: "Primary",
        color: "hsl(216, 84%, 47%)",
        graph: {
          nodes: [
            { id: 1, label: "Ar", title: "Aria Ra" },
            { id: 2, label: "Me", title: "Mario Eq" },
            { id: 3, label: "Se", title: "Send Eass" },
            { id: 4, label: "AQ", title: "Asddd Qdas" },
            { id: 5, label: "SS", title: "Seqwr Seqwsad" },
            { id: 6, label: "SS", title: "Seqwr Seqwsad" },
          ],
          edges: [
            { from: 1, to: 2 },
            { from: 1, to: 3 },
            { from: 1, to: 4 },
            { from: 1, to: 5 },
            { from: 1, to: 6 },
          ],
        },
      },
      {
        title: "Secondary",
        color: "hsl(204, 100%, 64%)",
        graph: {
          nodes: [
            { id: 1, label: "Ar", title: "Aria Ra" },
            { id: 2, label: "Me", title: "Mario Eq" },
            { id: 3, label: "Se", title: "Send Eass" },
            { id: 4, label: "AQ", title: "Asddd Qdas" },
            { id: 5, label: "SS", title: "Seqwr Seqwsad" },
            { id: 6, label: "TT", title: "Test Test" },
            { id: 7, label: "ET", title: "Edas Test" },
            { id: 8, label: "YQ", title: "Ydas Qsad" },
            { id: 9, label: "QQ", title: "Ydas Qsad" },
            { id: 10, label: "RQ", title: "Ydas Qsad" },
            { id: 11, label: "QW", title: "Y23 Qsad" },
          ],
          edges: [
            { from: 1, to: 2 },
            { from: 1, to: 3 },
            { from: 1, to: 4 },
            { from: 1, to: 5 },
            { from: 1, to: 6 },
            { from: 1, to: 7 },
            { from: 1, to: 8 },
            { from: 1, to: 9 },
            { from: 1, to: 10 },
            { from: 1, to: 11 },
          ],
        },
      },
      {
        title: "Others",
        color: "hsl(203, 82%, 13%)",
        graph: {
          nodes: [
            { id: 1, label: "Ar", title: "Aria Ra" },
            { id: 2, label: "Me", title: "Mario Eq" },
            { id: 3, label: "Se", title: "Send Eass" },
            { id: 4, label: "AQ", title: "Asddd Qdas" },
            { id: 5, label: "SS", title: "Seqwr Seqwsad" },
            { id: 6, label: "TT", title: "Test Test" },
            { id: 7, label: "ET", title: "Edas Test" },
            { id: 8, label: "YQ", title: "Ydas Qsad" },
            { id: 9, label: "QQ", title: "Ydas Qsad" },
            { id: 10, label: "RQ", title: "Ydas Qsad" },
            { id: 11, label: "Ar", title: "Aria Ra" },
            { id: 12, label: "Me", title: "Mario Eq" },
            { id: 13, label: "Se", title: "Send Eass" },
            { id: 14, label: "AQ", title: "Asddd Qdas" },
            { id: 15, label: "SS", title: "Seqwr Seqwsad" },
            { id: 16, label: "TT", title: "Test Test" },
            { id: 17, label: "ET", title: "Edas Test" },
            { id: 18, label: "YQ", title: "Ydas Qsad" },
            { id: 19, label: "QQ", title: "Ydas Qsad" },
            { id: 20, label: "RQ", title: "Ydas Qsad" },
            { id: 21, label: "ZQ", title: "Zdas Zsad" },
          ],
          edges: [
            { from: 1, to: 2 },
            { from: 1, to: 3 },
            { from: 1, to: 4 },
            { from: 1, to: 5 },
            { from: 1, to: 6 },
            { from: 1, to: 7 },
            { from: 1, to: 8 },
            { from: 1, to: 9 },
            { from: 1, to: 10 },
            { from: 1, to: 11 },
            { from: 1, to: 12 },
            { from: 1, to: 13 },
            { from: 1, to: 14 },
            { from: 1, to: 15 },
            { from: 1, to: 16 },
            { from: 1, to: 17 },
            { from: 1, to: 18 },
            { from: 1, to: 19 },
            { from: 1, to: 20 },
            { from: 1, to: 21 },
          ],
        },
      },
    ] as IConnectivityRanks[],
  },
  [ChartsTypeEnum.CONNECTIVITY_ANALYSIS]: {
    loading: false,
    error: { isOccurred: false, message: "" },
    data: [
      {
        index: "0",
        rank: "20",
        name: "Edward King",
        email: "edward@example.com",
        receive: {
          value: "20",
          percent: 3,
          isDesc: true,
        },
        messages: {
          value: "15",
          percent: 4,
          isDesc: false,
        },
        involvement: {
          value: "4",
          percent: 23,
          isDesc: true,
        },
        sent: {
          value: "42",
          percent: 40,
          isDesc: true,
        },
        frequencyTime: {
          value: "3h 49m",
          percent: 15,
          isDesc: true,
        },
      },
      {
        index: "1",
        rank: "1",
        name: "Jacob Lion",
        email: "jacobo@example.com",
        receive: {
          value: "10",
          percent: 13,
          isDesc: true,
        },
        sent: {
          value: "15",
          percent: 55,
          isDesc: true,
        },
        messages: {
          value: "8",
          percent: 8,
          isDesc: false,
        },
        involvement: {
          value: "14",
          percent: 15,
          isDesc: false,
        },
        frequencyTime: {
          value: "2h 12m",
          percent: 13,
          isDesc: false,
        },
      },
    ] as IConnectivityAnalysis[],
  },
};
export const initStatisticsParameters = {
  [ReportTypeEnum.STATISTICS]: [
    {
      title: "Messages",
      info: {
        flow: FlowEnum.ALL,
        title: "Messages",
        value: "0",

        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Frequency Time",
      info: {
        flow: FlowEnum.ALL,
        title: "Frequency Time",
        value: "0",

        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Participants",
      info: {
        flow: FlowEnum.ALL,
        title: "Participants",
        value: "0",

        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Involvement",
      info: {
        flow: FlowEnum.ALL,
        title: "Involvement",
        value: "0",

        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Subjects",
      info: {
        flow: FlowEnum.ALL,
        title: "Subjects",
        value: "0",

        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
  ],
  [ReportTypeEnum.CONNECTIONS]: [
    {
      title: "Participants",
      info: {
        flow: FlowEnum.ALL,
        title: "Participants",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Messages",
      info: {
        flow: FlowEnum.ALL,
        title: "Messages",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Frequency Time",
      info: {
        flow: FlowEnum.ALL,
        title: "Frequency Time",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },

    {
      title: "Involvement",
      info: {
        flow: FlowEnum.ALL,
        title: "Involvement",
        value: "0",

        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
  ],
  [ReportTypeEnum.ANALYTICS]: [
    {
      title: "Messages",
      info: {
        flow: FlowEnum.ALL,
        title: "Messages",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Frequency Time",
      info: {
        flow: FlowEnum.ALL,
        title: "Frequency Time",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Involvement",
      info: {
        flow: FlowEnum.ALL,
        title: "Involvement",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
    {
      title: "Subjects",
      info: {
        flow: FlowEnum.ALL,
        title: "Subjects",
        value: "0",
        percent: 0,
        percentageType: PercentageEnum.STABLE,
      },
    },
  ],
};
// export type IStatisticsData = (typeof chartData)[ReportTypeEnum];
export type IChartData = (typeof chartData)[ChartsTypeEnum];
export type IChartsData = typeof chartData;
