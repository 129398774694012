
import { useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import {
  CreateTeamForm,
  PeopleForm,
  ProfileDT,
  TableParams,
} from "../../../../utils/types";
import { extractOrderAndField } from "../../../../utils/table-sorter";
import {
  ActivatePeople,
  DeletePeople,
  DownloadCSV,
  EditPeople,
  GetPeople,
  ReferFriend,
} from "../services/service";
import { SelectProps, TableProps } from "antd";
import AuthSelectors from "../../../../modules/auth/selectors";
import CommonSelectors from "../../../../modules/common/selectors";

const useActions = () => {
  const { toastError, toastSuccess, toastPromise } = useAlertsContext();
  const [exportingCSV, setExportingCSV] = useState(false);

  const { user } = AuthSelectors();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { insidersList } = CommonSelectors();
  const domainsOptions: SelectProps["options"] = [
    { label: user?.email, value: user?.id },
    ...insidersList,
  ];
  const [selectedDomain, setSelectedDomain] = useState<string | undefined>(
    user?.id
  );
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [data, setData] = useState<ProfileDT[]>([]);
  const Create = async (form: CreateTeamForm, cb = () => {}) => {
    setLoading(true);
    setTimeout(() => {
      toastSuccess("Profile Created Successfully");
      setLoading(false);
    }, 2000);
  };
  const Edit = async (form: PeopleForm, cb = (close: boolean) => {}) => {
    setLoading(true);
    await EditPeople(form)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        toastError(error);
        cb && cb(false);
      })
      .finally(() => setLoading(false));
  };
  const Delete = async (id: string, cb = (close: boolean) => {}) => {
    setLoading(true);
    await DeletePeople(id)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        cb && cb(false);
        toastError(error);
      })
      .finally(() => setLoading(false));
  };
  const FetchPeople = async () => {
    setLoading(true);
    const { pagination, sorter } = tableParams;
    const sorterData = extractOrderAndField(sorter);
    // Initialize columns array and order array
    const columns: any = [];
    const orderArr: any = [];

    // Fill in columns array with name and orderable properties
    sorterData.forEach(({ field }, index) => {
      if (field) {
        columns.push({ name: field, orderable: true });
      }
    });

    // Fill in order array with column and dir properties
    sorterData.forEach(({ order }, index) => {
      if (order) {
        orderArr.push({ column: index, dir: order || undefined });
      }
    });
    const filters = {
      start: pagination?.current
        ? (pagination?.current - 1) * (pagination?.pageSize || 10)
        : 0,
      length: pagination?.pageSize || 10,
      customSearch: {
        searchTerm,
        active: tableParams?.filters?.active || null,
        teammateId: selectedDomain,
      },
      columns,
      order: orderArr,
    };
    await GetPeople(filters)
      .then((response) => {
        setData(response.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.recordsTotal,
          },
        });
      })
      .catch((e) => {
        if (e?.status && e?.status === 401) {
          toastError(e?.data || "Token Expired");
          // TODO
          //   setTimeout(() => {
          //     dispatch(logout());
          //   }, 1500);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearch = (search: string) => {
    console.log({ search });
    setSearchTerm(search);
  };
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };
  const handlePaginationChange = (current: number, pageSize: number) => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination: { pageSize, current },
    }));
    // `dataSource` is useless since `pageSize` changed
    if (pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };

  const ReferAFriend = async (email: string, cb = (error: boolean) => {}) => {
    setLoading(true);
    await ReferFriend(email)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        cb && cb(false);
        toastError(error?.data?.message);
      })
      .finally(() => setLoading(false));
  };
  const downloadReport = () => {
    setExportingCSV(true);
    // ajax request after empty completing
    const promise = DownloadCSV(selectedDomain)
      .then((response) => {
        let fileName = "";
        const disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            // console.log(decodeURIComponent(matches[1].replace(/['"]/g, "")));
            fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
          }
        }
        // const fileName = fileNameFormatter();
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        toastError(e?.data);
      })
      .finally(() => setExportingCSV(false));
    toastPromise(
      promise,
      `File exported successfully.`,
      `Error occurred during exporting the file.`
    );
  };
  const ActivateProfile = async (id: string, cb: (close: boolean) => void) => {
    setLoading(true);
    await ActivatePeople(id)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        toastError(error);
        cb && cb(false);
      })
      .finally(() => setLoading(false));
  };

  // const GetUsersSelection = async (searchTerm = "") => {
  //   // TODO get from selectors
  //   const searchObj = { searchTerm, skip: 0, take: 50 };
  //   await GetInsidersList(searchObj)
  //     .then((response) => {
  //       const mappedArray = response.data.map(
  //         (item: { name: string; id: string }) => ({
  //           label: item.name,
  //           value: item.id,
  //         })
  //       );
  //       setDomainsOptions([
  //         { label: user?.email, value: user?.email },
  //         ...mappedArray,
  //       ]);
  //     })
  //     .catch((error) => console.log(error));
  // };

  return {
    Delete,
    Create,
    Edit,
    data,
    loading,
    searchTerm,
    setSearchTerm,
    FetchPeople,
    tableParams,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
    ReferAFriend,
    domainsOptions,
    selectedDomain,
    setSelectedDomain,
    exportingCSV,
    downloadReport,
    ActivateProfile,
  };
};

export default useActions;
