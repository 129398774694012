import { useEffect, useState } from "react";
import { notifications } from "../data";
import { INotification } from "../../../../../utils/types";
import {
  GetNotifications,
  ReadAllNotifications,
  ReadNotification,
} from "../services/services";

type Config = {
  selected: INotification["id"] | null;
  allNotifications: INotification[];
  unreadCount: number;
};
// check here
const useNotification = () => {
  const [notification, setNotification] = useState<Config>({
    selected: null,
    allNotifications: [],
    unreadCount: 0,
  });
  const getNotifications = async () => {
    await GetNotifications()
      .then((response) => {
        const notificationsLs = {
          selected: null,
          allNotifications: response.data,
          unreadCount: response.data.filter((n: INotification) => !n.read)
            .length,
        };
        setNotification(notificationsLs);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const openNotification = async (notificationId: string) => {
    setNotification((prev) => ({
      ...prev,
      selected: notificationId,
      allNotifications: prev.allNotifications.map((n) =>
        n.id === notificationId ? { ...n, read: true } : n
      ),
      unreadCount: prev.allNotifications.filter(
        (n) => !n.read && n.id !== notificationId
      ).length,
    }));
    await ReadNotification(notificationId).catch((e) => console.log(e));
  };
  const markAsRead = async () => {
    setNotification((prev) => ({
      ...prev,
      allNotifications: prev.allNotifications.map((n) => ({
        ...n,
        read: true,
      })),
      unreadCount: 0,
    }));
    await ReadAllNotifications().catch((e) => console.log(e));
  };
  const closeModal = () => {
    setNotification((prev) => ({
      ...prev,
      selected: null,
    }));
  };
  return {
    markAsRead,
    notification,
    setNotification,
    openNotification,
    closeModal,
  };
};
export default useNotification;
