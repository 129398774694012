import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthActions from "../../modules/auth/actions";
import { useAppDispatch } from "../../hooks/useStore";
import { CgSpinner } from "react-icons/cg";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import { getData } from "../../utils/get-token";

const MSCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { msLogIn } = AuthActions();
  const dispatch = useAppDispatch();
  const { toastError } = useAlertsContext();

  const handleMSLogin = async (code: string) => {
    // console.log("handleMSLogin", { code });
    try {
      const codeVerifier = getData("codeVerifier") || "";
      dispatch(msLogIn(code, codeVerifier, () => navigate("/Dashboard")));
    } catch (error) {
      // console.error("Error in handleMSLogin:", error);
      toastError("Failed to login!");
      navigate("/get-started/Login");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code) {
      handleMSLogin(code);
    }
    // Redirect back to your main app page
    else navigate("/get-started/Login");
  }, []);

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center">
        <CgSpinner className="animate-spin size-8 text-primaryBlue" />
        <h1 className="animate-shine text-lg font-bold">Redirecting...</h1>
      </div>
      <p>Please wait a while. You are being redirected.</p>
    </div>
  );
};
export default MSCallback;
