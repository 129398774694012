import React from "react";
import { cn } from "../../../../utils/utils";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  IScope,
} from "../../../../utils/types";
import Wrapper from "./Wrapper";
import { Tooltip } from "antd";

const WaffleChart = ({
  percentage,
  total,
  color,
  rows = 12,
  columns = 10,
  title,
}: {
  percentage: number;
  total: number;
  color: string;
  rows?: number;
  columns?: number;
  title: string;
}) => {
  const totalSquares = rows * columns;
  const filledSquares =
    total <= 0 ? 0 : Math.round((percentage / total) * totalSquares);
  return (
    <Tooltip
      color={"#fff"}
      overlayInnerStyle={{
        color: "#000",
        borderLeftWidth: 4,
        borderLeftColor: color,
      }}
      placement={"topLeft"}
      arrow={false}
      title={`${title}: ${percentage} (${total})`}
    >
      <div className="flex flex-col items-center text-center">
        <div className="text-xl font-bold mb-2">{percentage}</div>
        {/* <div className="text-xl font-bold mb-2">{percentage}%</div> */}
        <div
          className={`grid grid-cols-${columns} gap-1 rotate-180`}
          style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
        >
          {[...Array(totalSquares)].map((_, index) => (
            <div
              key={index}
              className={cn("size-4 rounded-sm")}
              style={{
                backgroundColor: index < filledSquares ? color : "#E6E6E6",
              }}
            />
          ))}
        </div>
      </div>
    </Tooltip>
  );
};
const Connectivity = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<IScope>;
}) => {
  const isOutFlow = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading, needSubscription } = details;
  const colorMap: Record<string, string> = {
    currentPeriod: `var(--chart-current-period-${isOutFlow})`,
    lastPeriod: `var(--chart-last-period-${isOutFlow})`,
    yearlyAvg: `var(--chart-yearly-avg-${isOutFlow})`,
  };
  return (
    <Wrapper
      className={className}
      cardFilters={filters}
      loading={loading}
      error={error}
      needSubscription={needSubscription}
      content={
        <div className="flex flex-col gap-2 w-full items-center">
          <div className="grid grid-cols-3 gap-4">
            {data.map((item, index) => (
              <WaffleChart
                key={index}
                percentage={item.percentage}
                total={item.total}
                color={colorMap[item.parameter]}
                title={item.title}
              />
            ))}
          </div>
          <div className="flex gap-4 mt-6">
            {data.map((item, index) => (
              <div className="text-xs mt-2 flex gap-2 items-center" key={index}>
                <div
                  className={"size-2 rounded-sm"}
                  style={{
                    backgroundColor: colorMap[item.parameter],
                  }}
                />
                <span className="max-md:text-[0.5rem]">{item.title}</span>
              </div>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default Connectivity;
