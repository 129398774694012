import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { Link } from "react-router-dom";
import socials from "../../data/configs";

const TermsHE = () => {
  return (
    <Section id="TermsUse" badge={"Terms of Use"} title="תקנון ותנאי שימוש">
      <SEO title={"Terms of Service | InterSight"} />
      <div className="items-center justify-center flex flex-col">
        <div className="my-16 w-[90%] lg:w-[50%]">
          <p className="font-bold text-primaryBlue text-center max-md:text-start">
            תאריך עדכון אחרון: {socials.updateAt}
          </p>
          <div className="items-center justify-center mt-8">
            <div className="mb-10">
              <ul className="start-custom space-y-4 list-inside list-decimal">
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    מטרת המערכת
                  </h2>
                  <p>
                    המערכת "InterSight" היא פלטפורמה דיגיטלית המאפשרת הפקה של
                    דוחות תקשורת ניהוליים, באופן דיגיטלי. האתר והמערכת עצמה
                    יקראו להלן
                    <span className="font-bold text-black">{` "המערכת" `}</span>
                    .
                  </p>
                  <p>
                    בעל המערכת רשאי להוסיף שירותים ו/או מוצרים בגישה חופשית או
                    בתשלום לפי שיקול דעתו.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    הגדרות
                  </h2>
                  <p>
                    המשתמש/ת ו/או הגולש/ת– כל אדם, או ישות משפטית אשר
                    גולשים/מבקרים במערכת.
                  </p>
                  <div className="my-8 [&>p]:!mb-1 italic pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                    <p>
                      הבעלים – שם החברה : הינתרסאיט בע"מ מספר מזהה:
                      <strong>{" 517017349 "}</strong>
                    </p>
                    <p>
                      שכתובת הדואר האלקטרוני שלהם היא
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                    </p>
                    <p>
                      טלפון –
                      <Link
                        dir="ltr"
                        to={`tel:${socials.phone}`}
                        className="mx-2 text-primaryBlue"
                      >
                        {socials.phone}
                      </Link>
                    </p>
                    <p>כתובת: {socials.address}</p>
                    <p>
                      המערכת – אתר ומערכת דיווח שכתובת הדומיין שלו היא -
                      <Link
                        to="https://www.inter-sight.com"
                        target="_blank"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        www.inter-sight.com,
                      </Link>
                      לרבות כל תת-הדומיינים שלו
                    </p>
                  </div>
                  <p>
                    <strong>התקנון</strong> – מסמך זה על כל הוראותיו וסעיפיו
                  </p>
                </li>
                <li className="[&>p]:!mb-1">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    כללי
                  </h2>
                  <p>
                    תקנון זה, כולל בין היתר תנאי שימוש, הוראות בדבר שמירה על
                    פרטיות, תקנון מכירות, הוראות בנושא שמירה על קניין רוחני. אנא
                    קרא/י בעיון את כל תנאי השימוש. אם אינך מסכים לאחת ו/או יותר
                    מהוראות התקנון, עליך להימנע מכל שימוש במערכת.
                  </p>
                  <p>
                    המשתמש/ת מסכים, בעצם פעולת הכניסה והגלישה במערכת, לכל הוראות
                    התקנון.
                  </p>
                  <p>
                    המשתמש/ת מצהיר כי הוא מעל גיל 18, ואם הוא מתחת לגיל 18 אזי
                    מצהיר כי הגלישה במערכת נעשית בהסכמת האחראי עליו כנדרש בדין.
                  </p>
                  <p>התקנון מנוסח בלשון זכר, אולם מכוון לשני המינים.</p>
                  <p>
                    מובהר ומודגש כי תקנון זה על מלוא הוראותיו, יחייב כל אדם אשר
                    משתמש במערכת, ו/או רוכש דרכה שירותים או מוצרים ו/או משתמש
                    אשר רכש מוצר ו/או שירות עבור צד ג'.
                  </p>
                  <p>
                    תנאי התקנון חלים ומחייבים על אדם אשר עושה שימוש במערכת, צופה
                    בה, ו/או מבצע בה כל פעולה אחרת, בכל מכשיר ובכל מדיה שהיא,
                    וללא הגבלת מקום גיאוגרפי.
                  </p>
                  <p>
                    במקרה של סתירה בין הוראות התקנון לבין הוראות הסכמים אחרים
                    ו/או הוראות פרסומים אחרים המתייחסות למערכת, יגברו תנאי
                    התקנון.
                  </p>
                </li>
                <li className="[&>p]:!mb-1">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    פרטיות
                  </h2>
                  <p>
                    בעל המערכת מכבד את פרטיות המשתמשים ופועל לפי כל דין. לקריאת
                    מדיניות הפרטיות המלאה יש להיכנס לקישור
                    <Link
                      to="https://www.inter-sight.com/privacy-policy"
                      className="font-bold mx-2 text-primaryBlue"
                      target="_blank"
                    >
                      "מדינות פרטיות"
                    </Link>
                    בעמוד הבית של המערכת.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    קניין רוחני במערכת ורישיון השימוש
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      בעל המערכת מתייחס בחומרה ובכובד ראש להפרת זכויות יוצרים
                      ו/או זכויות קניין רוחני אחרות. בעל המערכת אוכף בקפידה את
                      זכויותיו בכל האמצעים החוקיים אשר עומדים לרשותם.
                    </li>
                    <li>
                      הקניין הרוחני במערכת – לרבות אך לא רק - סימני מסחר, זכויות
                      יוצרים, פטנטים, מדגמים ו/או עיצובים, לוגואים, מאגרי מידע,
                      שם הדומיין, טקסטים, תמונות, דימויים, סודות מסחריים, מידע
                      עסקי ו/או כל נכס/קניין רוחני אחר, שייכים לבעל המערכת, או
                      לצד ג' אשר הרשה את שימושם או הצגתם במערכת וזאת אלא אם צוין
                      אחרת. אם הנך מזהה הפרה, אנא פנה אלינו בכתובת דוא"ל
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                      ובעל המערכת יברר ויסדיר את העניין תוך זמן סביר מקבלת
                      הפניה.
                    </li>
                    <li>
                      החברה מעניקה למשתמש, באמצעות המערכת, רישיון לבצע בין היתר
                      פעולות הפקה של דוחות הנהלה
                      <span className="font-bold mx-2">
                        סטטיסטים, אנליטים או הקשרים בלחיצת כפתור.
                      </span>
                    </li>
                    <li>
                      הרישיון יינתן למשתמשים אשר מנהלים פעילות עסקית, ורשומים
                      כדין בישראל. הרישיון יינתן בהתאם לחבילת השימוש שתירכש על
                      ידי המשתמש
                      <span className="font-bold">{' "(חבילת שימוש)" '}</span>
                      מובהר כי כל חבילת שימוש מאפשרת היקף שונה של הרשאות לביצוע
                      פעולות. למידע נוסף על חבילות השימוש, היקף הפעולות הכלול
                      ברישיון הצמוד להם ועלויותיהן ראה
                      <Link
                        to="https://www.inter-sight.com/Pricing"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        www.inter-sight.com/Pricing
                      </Link>
                    </li>
                    <li>
                      כמפורט בסעיף 13.1, אין למשתמש במערכת אישור או רשות לעשות
                      שימוש מסחרי בקניין הרוחני במערכת, ו/או כל שימוש שעומד
                      בניגוד להוראות התקנון, אלא אם כן קיבל לכך אישור בכתב מבעל
                      המערכת.
                    </li>
                    <li>
                      אין לעשות שימוש בשם המערכת ו/או בשם הדומיין, או בתוכנו בכל
                      דרך אשר יכולה להטעות, לפגוע במוניטין, ליצור גניבת עין ו/או
                      הטעיה, או בכל דרך אחרת אשר יכולה לגרום לנזק ממוני או אחר
                      לבעלי המערכת ו/או לצד ג'.
                    </li>
                    <li>
                      ייתכן ובמערכת יהיו קישורים (hyperlinks) אשר מפנים לאתרים
                      חיצוניים (להלן 'אתרים חיצוניים') שאינם בבעלות בעל המערכת
                      ואינם בשליטתו. הקניין הרוחני באתרים חיצוניים הינו בבעלות
                      צד ג' ואין לבעל המערכת כל זכות בהם. אין בעצם הטמעת
                      הקישורים במערכת כדי להרשות, או לאשר כל פעולה שהיא ביחס
                      לנכסי קניין רוחני באתרים החיצוניים. אם מצאת את האתרים
                      אליהם מפנים הקישורים כפוגעניים או ככאלה אשר מפרים את הדין
                      אנא ידע את בעלי המערכת בהקדם בכתובת
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                      והוא יפעל לבירור התלונה וטיפול בה.
                    </li>
                    <li>
                      בנוסף, במערכת עשויות להופיע פרסומות שמקורן בחברות ו/או
                      עסקים שאינם בבעלות ו/או באחריות בעל המערכת. אין לבעל
                      המערכת כל אחריות בנוגע לתוכן פרסומות אלו. אם נתקלתם
                      בפרסומת פוגענית ו/או פרסומת אשר מפרה את החוק אנא פנו אל
                      בעלי המערכת בכתובת
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                      בבקשה להסרת הפרסומת.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    זמינות המערכת
                  </h2>
                  <p>
                    בעל המערכת עושה כמיטב יכולתו כדי שהמערכת תפעל באופן תקין
                    ויהיה זמין לגלישה רציפה. יחד עם זאת, אין התחייבות מצד בעל
                    המערכת כי המערכת תפעל בכל עת. יתכנו שיבושים בזמינות/ צפייה /
                    גלישה במערכת אשר עשויים לנבוע מעדכוני אתר/מערכת ו/או עבודות
                    תשתית, ו/או תקלות במחשב האישי של המשתמש, ספק האינטרנט שלו,
                    שרת חיצוני, או בתשתית הגלישה שלו. כמו כן יתכנו תקלות הנובעות
                    משירות האחסון של המערכת, פריצה או גורמים אחרים. בעל המערכת
                    ישתדל להודיע מראש על הפרעות מתוכננות בגלישה אולם אינו מחויב
                    לכך. בעל המערכת שומר את הזכות להפסיק או לשנות את פעילות
                    המערכת וזאת ללא הודעה מוקדמת. המשתמש פוטר בזאת את בעל המערכת
                    מכל נזק – ממוני ו/או אחר- אשר עלול להיגרם כתוצאה מאי-זמינות
                    של שירותי המערכת.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    הוראות שחרור מאחריות
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      המערכת מאוחסנת בשרת מאובטח על ידי חברה חיצונית. בעל המערכת
                      אינו אחראי לנזק אשר יגרם כתוצאה מפריצה חיצונית למערכת –
                      בין היתר – הפצת/מכירת מידע כתוצאה מפריצה למערכת, שיבוש
                      במידע, פגיעה בזכויות יוצרים או קניין רוחני אחר, או כל נזק
                      אחר שיגרם כתוצאה מחבלה במערכת ובתכניה.
                    </li>
                    <li>
                      מובהר כי באחריותו הבלעדית של המשתמש להגן ולאבטח את מכשירי
                      הקצה שברשותו ו/או רשתות התקשורת, ו/או שרתיו ו/או כל חומרה
                      או תוכנה, מפני אירועי אבטחת מידע וזאת כמקובל וכנהוג.
                    </li>
                    <li>
                      באחריות המשתמש לבחון, טרם השימוש במערכת, כי המערכת אכן
                      מתאימה לצרכיו, ולמשתמש לא תהיה כל טענה בשל אי התאמת המערכת
                      לצרכיו.
                    </li>
                    <li>
                      בעל המערכת אינו אחראי לתוכן מודעות חיצוניות
                      (תמונות/קישורים) אשר עשויות להופיע /להיטמע במערכת או
                      לתוכן/מידע אשר מופיע באתרים אליהם הקישורים מובילים. בעל
                      המערכת ו/או מי מטעמו אינו מפקח או בודק את כל התוכן באתרים
                      אליהם מפנים הקישורים.
                    </li>
                    <li>
                      אם נתקלת במודעה/קישור פוגעני ו/או תמונה פוגענית ו/או כזה
                      אשר מפנה למערכת בעל תוכן פוגעני אנא הודע/י לבעל המערכת
                      בכתובת דואר אלקטרוני
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                      ונפעל להסדיר את העניין בהקדם האפשרי.
                    </li>
                    <li>
                      <strong>
                        בעל המערכת מפרסם במערכת מעת לעת פרסומים, תכנים ומשתף ידע
                        מקצועי וכיוב' (להלן "פרסומים"). האמור בפרסומים הינו על
                        דעת בעל המערכת בלבד ולמטרות סקירה, העשרה ושיווק בלבד.
                        אין לראות באמור בפרסומים יעוץ מקצועי, ייעוץ חשבונאי,
                        ייעוץ ניהולי, ייעוץ אסטרטגי, ו/או כל ייעוץ אחר, ו/או
                        המלצה, ו/או הנחיה והם אינם מהווים תחליף לאלו ואין להסתמך
                        עליהם ככאלו. בנוסף, ייתכן ויהיו במערכת שגיאות ו/או
                        אי-דיוקים כתוצאה מתום לב, ו/או מצב שבו תוכן בפרסום לא
                        יהיה עדכני. בעל המערכת לא ישא באחריות בגין כל נזק שנגרם
                        למשתמש בשל הסתמכות על האמור בפרסום ולא יחוב כלפי המשתמש
                        בגין הסתמכות כזו.
                      </strong>
                    </li>
                    <li>
                      <strong>
                        ככל שמשתמש במערכת מסתמך על האמור בה, הוא עושה זאת
                        מבחירתו ועל אחריותו הבלעדית ולאחר שנאמר לו כי עליו
                        להתייעץ עם אנשי מקצוע ויועצים רלוונטיים לתחום. משתמש אשר
                        הסתמך על תכני המערכת, מוותר בזאת על כל טענה ו/או דרישה
                        ו/או תביעה כלפי בעל המערכת. והחברה תהיה פטורה מכל פיצוי
                        ו/או חיוב ו/או שיפוי ביחס לנזקים שנגרמו למשתמש.
                      </strong>
                    </li>
                    <li>
                      היה וסמכויות שיפוט כלשהן אינן מאפשרות החרגות או הגבלות
                      כאמור לעיל, ההחרגות וההגבלות הנזכרות לעיל לא תחולנה
                      במלואן, אלא רק במידה המקסימאלית המותרת על פי הדין החל.
                    </li>
                    <li>
                      בעל המערכת אינו אחראי, והמשתמש פוטר את בעל המערכת מכל נזק
                      – ממוני ו/או אחר, שעשויים להיגרם למשתמש ו/או לצד ג' כתוצאה
                      משימוש בשירותי המערכת.
                    </li>
                    <li>
                      <strong>הגבלת אחריות מצד בעל המערכת</strong> – בכל מקרה
                      שבו ייקבע על ידי גורם מוסמך לפי דין כי בעל האתר חייב
                      בפיצוי, מוסכם בזאת כי חבותו של בעל האתר, כלפי המשתמש ו/או
                      כלפי כל צד ג', תוגבל לסכום הרכישה שביצע המשתמש, לכל היותר.
                      למען הסר ספק מובהר כי חבותו של בעל האתר מוגבלת לנזקים
                      ישירים בלבד, והיא לא תחול על נזקים עקיפים כגון אבדן רווח,
                      פגיעה במוניטין, אבדן לקוחות וכיוב'.
                    </li>
                    <li>
                      <strong>כוח עליון</strong>- במקרה שבעל המערכת לא יוכל לבצע
                      אחת ו/או יותר מהתחייבויותיו כלפי המשתמש, בשל כוח עליון,
                      מועד ביצוע ההתחייבות יידחה למועד אחר, שייקבע על ידי
                      הצדדים. כוח עליון בהסכם זה הינו- אירוע בטחוני, שביתה, נזקי
                      טבע, מגפות ומחלות כדוגמת הקורונה, ו/או אילוצים עקב מצב
                      בריאותי. ככל ששירותיו של בעל המערכת לא יוכלו להידחות למועד
                      חלופי, לא ייחשב הדבר כהפרה, והמשתמש לא יהיה רשאי לטעון כל
                      טענה ו/או לבוא בכל דרישה כלשהי כלפי בעל המערכת.
                    </li>
                    <li>
                      <strong>
                        המערכת אינה אחראית על יצירת ו/או שמירת גיבויים עבור
                        המשתמש
                      </strong>
                      . על המשתמש חלה האחריות הבלעדית לשמור ולגבות, שלא באמצעות
                      המערכת, את כל החומרים המופקים על ידו במערכת או הנוצרים תוך
                      כדי השימוש בה. בהקשר זה מובהר, כי אין לראות במערכת ככלי
                      למימוש חובת שמירת מידע, ככל שחלה כזו על גורם מייצג אחר
                      מטעמו.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    שימוש בשירותים
                  </h2>
                  <p className="text-placeholder italic underline font-bold !mb-2">
                    שימוש בשירותי הדוחות ו/או מסמכים ו/או טפסים של המערכת בסעיף
                    זה
                    <span className="text-black">
                      {' "תוכן"- כל "יצירה", '}
                    </span>
                    , כהגדרתה בחוק זכויות יוצרים התשס"ח 2007 ו/או תוכן טקסט,
                    גרפי ו/או אחר.
                  </p>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      במסגרת השימוש בשירותי המערכת, רשאים המשתמשים לשתף ו/או
                      לאפשר למערכת גישה לנתונים, ו/או לתכנים ו/או ליצירות (כגון
                      טקסטים, דימויים גרפיים, לוגואים וכיוב'). משתמש במערכת
                      מעניק בזאת לבעל המערכת רישיון בלתי חוזר, שאינו מוגבל בזמן
                      ו/או אינו מוגבל במקום גיאוגרפי, לבצע את הפעולות הדרושות
                      לצורך אספקת שירותי המערכת (לרבות – העתקת היצירות, העמדתן
                      לרשות הציבור, גיבוי שלהן).
                    </li>
                    <li>
                      במסגרת השימוש בשירותי המערכת, מתחייבים בזאת המשתמשים כי יש
                      להם אישור כדין לשתף את התוכן למערכת.
                    </li>
                    <li>
                      משתמשים מתחייבים לעשות במערכת שימוש חוקי בלבד, וכמו כן
                      מתחייבים בזאת שלא לשתף למערכת תוכן, ו/או לעשות כל שימוש
                      בשירותי המערכת אשר מפר כל זכות יוצרים של צד ג', ו/או תוכן
                      אשר גורם לכל הפרת זכות קניין רוחני ו/או תוכן שגורם לכל
                      עוולה או עבירה אחרת, לרבות – גרימת לשון הרע, פגיעה
                      בפרטיות, הסתה, קריאה לגזענות.
                    </li>
                    <li>
                      בעל המערכת אינו אחראי לאופי ו/או תוכן התכנים אשר משותפים
                      על ידי המשתמשים, וכן אינו אחראי לבחינה ו/או לבקרה שלהם.
                    </li>
                    <li>
                      המשתמשים מתחייבים בזאת שלא לשתף ו/או לתת גישה למערכת לתוכן
                      משפיל, ו/או פוגעני ו/או אלים ו/או בעל תוכן מיני שאינו
                      הולם.
                    </li>
                    <li>
                      בעל המערכת רשאי לחסום משתמש מלעשות שימוש בשירותי המערכת,
                      לפי שיקול דעתו, אם מצא שהוא מפרים את אחד ו/או יותר מהוראות
                      כללי תקנון זה, וזאת מבלי לתת למשתמש כל הודעה מוקדמת.
                      למשתמש לא תהיה כל זכות לפיצוי ו/או לשיפוי בגין אי-אישור
                      ו/או הסרת תכנים מהמערכת.
                    </li>
                    <li>
                      משתמש אשר הפר את תנאי תקנון זה, יפצה וישפה את בעל המערכת
                      בגין כל נזק אשר יגרם לו בשל תביעה ו/או טענה ו/או דרישה
                      בזיקה לתכנים אלו, לרבות הוצאות משפטיות אשר כרוכות בטיפול
                      בעניין, וזאת תוך 14 ימים מהיום שבו קיבל את הדרישה לפיצוי
                      ו/או לשיפוי.
                    </li>
                    <li>
                      במקרה בו המשתמש עושה שימוש במערכת במסגרת מקום העבודה,
                      באחריותו הבלעדית של המשתמש במערכת לקבל את אישור מקום
                      העבודה עבור השימוש בשירותי המערכת. בעל המערכת אינו אחראי
                      לבדוק ו/או לוודא את סמכותו של משתמש לעשות שימוש במערכת בשם
                      מקום עבודתו.
                    </li>
                    <li>
                      המשתמש מסכים ומאשר בזאת כי נציגים מטעם החברה יהיו רשאים
                      להיכנס בכל עת לחשבון המשתמש במערכת, בין היתר (אך לא רק)
                      כדי לבצע בדיקות טכניות ו/או לסייע בכל שאלה ו/או תקלה
                      במערכת ו/או בתפעולה.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    איסור פגיעה במערכת
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      אין לבצע כל פעולה באופן ישיר או על ידי צד ג', אשר עשויה
                      לפגוע במערכת, בזמינות הגלישה שבו, או בתוכן המוצג בו, או
                      לשבש את פעולתו. אין לעשות שימוש בקוד תוכנה או כל פעולה
                      אלקטרונית/דיגיטלית ו/או אוטומטית אחרת לרבות - וירוס ו/או
                      'תולעת' ו/או 'בוט' למטרת פגיעה כאמור במערכת.
                    </li>
                    <li>
                      אין לבצע כל פעולה אשר עשויה לשבש ו/או להכביד את פעולתם
                      התקינה של מחשבי ושרתי בעל המערכת.
                    </li>
                    <li>
                      אין לבצע כל פעולה אשר עשויה למחוק ו/או לשבש ו/או לגרום
                      לשינוי במידע של בעל המערכת.
                    </li>
                    <li>
                      אין לחדור לחומרי מחשב ו/או מידע אשר שייכים לבעל המערכת.
                    </li>
                    <li>אין לעשות שימוש בשירותי המערכת למטרות שאינן חוקיות.</li>
                    <li>
                      למשתמש ו/או לצד ג' אסור לבצע כל פעולה אשר נועדה לעשות
                      שימוש במערכת ו/או בתכניו למטרה מסחרית ו/או למטרת לימוד
                      מכונה ו/או לכל מטרה אחרת, מבלי שקיבל לכך אישור מבעל
                      המערכת. בסעיף זה <strong>'תכני המערכת'</strong> – תכני
                      טקסט, תמונות, עיצובים, גרפיקות, קטעי וידאו ו/או סאונד ו/או
                      פרטים אישיים של המשתמשים, מידע סטטיסטי אודות פעילות
                      המשתמשים, קוד תוכנה.
                    </li>
                    <li>
                      בעל המערכת רשאי על פי שיקול דעתו, לחסום ו/או להגביל גישה,
                      כל משתמש במערכת אשר הפר אחת או יותר מהוראות תקנון זה.
                      למשתמש שנחסם ו/או שגישתו הוגבלה לא תהיה כל זכות לפיצוי
                      ו/או שיפוי מצד בעל המערכת.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    אחריות המשתמש
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      המשתמש/ת והוא בלבד אחראי לכל נזק שיגרם כתוצאה מהפרת הוראות
                      התקנון.
                    </li>
                    <li>
                      מבלי לגרוע מהאמור, המשתמש ישפה את בעל המערכת בגין כל נזק,
                      כלכלי או אחר, ו/או תביעה או דרישה של צד ג' כלשהו שיגרמו
                      כתוצאה מכל אחד מאלה: (1) הפרה של אחת או יותר מהוראות
                      התקנון (2) שימוש במערכת ו/או בתכנים שלא בהתאם לתקנון זה;
                      (3) הפרה מצד המשתמש של כל זכות של צד שלישי, לרבות (אך לא
                      רק), זכויות קניין רוחני או הזכות לפרטיות; (4) כל נזק מכל
                      סוג שהוא, בין אם נזק ישיר, עקיף, מיוחד או תוצאתי, שגרם לצד
                      שלישי אשר קשור לשימושו במערכת או בתכנים. מבלי לגרוע
                      מהאמור, המשתמש ישא גם בהוצאות משפטיות, עלויות שכר טרחת
                      עו"ד, ו/או בעלי מקצוע אחרים, ו/או הוצאות אחרות שיהיו בזיקה
                      לנזק שנגרם, כתוצאה מכל הסיבות שנמנו לעיל.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    עדכונים ושינויים
                  </h2>
                  <ul className="nested-list list-inside pr-4">
                    <li>
                      בעל המערכת שומר את הזכות לעדכן מעת לעת ולפי הצורך את תקנון
                      המערכת.
                    </li>
                    <li>
                      על בעל המערכת לא תחול החובה לעדכן את המשתמשים בשינויים
                      בתקנון תנאי השימוש.
                    </li>
                    <li>
                      תוקפם של השינויים יחול מרגע שינויים/עדכונם, והנוסח החדש
                      יהיה זה אשר מחייב את המשתמשים.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    סמכות שיפוט
                  </h2>
                  <p className="font-bold">
                    הדין החל על כל סכסוך או מחלוקת בנוגע לתקנון זה יהיה הדין
                    הישראלי וסמכות השיפוט הבלעדית והייחודית בכל עניין הנוגע
                    לתקנון תנאי שימוש זה יהיה לבית משפט אשר לו הסמכות העניינית
                    בתל אביב או ירושלים.
                  </p>
                  <p className="font-bold">
                    תקנון זה מהווה יצירה מוגנת על ידי חוק זכויות יוצרים. אין
                    להעתיק, לפרסם ו/או לעשות כל שימוש ללא אישור מבעל הזכויות.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    תקנון רכישת מוצרים ושירותים מהמערכת
                  </h2>
                  <ul className="nested-list list-disc list-inside pr-4">
                    <li>
                      רכישת מוצרים או שירותים דרך המערכת כפופה לאמור בסעיפי
                      התקנון.
                    </li>
                    <li>
                      בעל המערכת רשאי לעדכן את מחירי המוצרים ו/או השירותים מעת
                      לעת, ללא הודעה מוקדמת. המחירים המעודכנים יופיעו במערכת.
                    </li>
                    <li>
                      הרוכש מצהיר כי הוא רוכש את שירותי המערכת לצורך אישי ולא
                      מסחרי (בסעיף זה משמעות המונח
                      <strong>{` "שימוש מסחרי" `}</strong> היא שיווק ו/או מכירה
                      ו/או הפצה חוזרת של שירותי המערכת). מכירה חוזרת של השירותים
                      ו/או שיווקם אסורה. כמו כן אסור כל שימוש מסחרי ו/או שיווקי
                      אחר במוצרים, וזאת אלא אם התקבל לכך אישור מבעלי המערכת.
                    </li>
                    <li>המחירים במערכת כוללים מע"מ.</li>
                    <li>
                      חשבון של משתמש, אשר לא ישלם את התמורה במועד, ייחסם על ידי
                      בעל המערכת, והמשתמש לא יוכל לעשות שימוש במערכת עד לאחר
                      הסדרת התשלום.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    חשבון המשתמש
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      הגישה לביצוע פעולות מסוימות תתאפשר רק למשתמש אשר נרשם
                      במערכת ומסר לחברה פרטים מסוימים, אשר ייקבעו על-ידי החברה
                      <strong>{` ("חשבון משתמש" או " החשבון").`}</strong>
                    </li>
                    <li>
                      המשתמש רשאי להזמין תחת אותו חשבון משתמש מספר תתי משתמשים,
                      בהתאם לחבילת השימוש אותה רכש מהחברה, ובלבד שכל תת משתמש
                      הוא בעל מספר רישום (ID) שונה. מובהר בזאת כי לא ניתן להקים
                      במערכת תחת אותו חשבון משתמשים עם אותו מספר רישום.
                    </li>
                    <li>
                      בעת ההרשמה ו/או הרכישה, המשתמש מתחייב למסור פרטים מזהים
                      מדויקים ועדכניים.
                    </li>
                    <li>
                      אין ליצור חשבון עבור אדם אשר אינו נוכח מול צג המחשב בעת
                      ההרשמה או אשר אינו מאשר את תנאי השימוש. לעניין הוראות אלו
                      יודגש כי התחזות היא עבירה פלילית;
                    </li>
                    <li>
                      לגבי משתמש שהוא תאגיד, איש הקשר אשר פתח את החשבון בפועל
                      בשם המשתמש שהוא תאגיד
                      <strong>{` ("המורשה המקורי") `}</strong> יחשב על ידי החברה
                      כמשתמש לכל דבר ועניין.
                    </li>
                    <li>
                      משתמש שהוא תאגיד, המעוניין להחליף את המורשה המקורי במורשה
                      חדש, יעביר לבעל המערכת טופס מורשה המפרט את המורשה (אחד או
                      יותר) החדש המוסמך לפעול בשם התאגיד, בתוספת אימות עורך דין
                      בדבר היותו של המורשה החדש האמור מורשה על פי דין לפעול בשם
                      התאגיד לעניין השימוש במערכת ("טופס מורשה(" עם קבלת טופס
                      מורשה, יעדכן בעל המערכת את המורשה החדש תחת חשבון המשתמש,
                      ויראה בו כמשתמש לכל דבר ועניין (עד קבלת טופס מורשה מעודכן
                      מהמשתמש שהוא תאגיד המעיד על מורשה אחר), ולמורשה המקורי
                      יתבטלו כל ההרשאות והזכויות בקשר עם המערכת, ולא תישמע כל
                      טענה של המורשה המקורי לעניין זה
                    </li>
                    <li>
                      המשתמש מתחייב לשמור על הכללים הבאים ביחס לסיסמה אשר תשמש
                      לגישה לחשבון המשתמש ולביצוע פעולות במערכת המתאפשרות אך ורק
                      לבעלי חשבון המשתמש
                      <span className="font-bold text-black">
                        {' ("הסיסמה") '}
                      </span>
                      <div className="space-y-2 font-bold [&>p]:!m-1">
                        <p>על הסיסמה להכיל 8-16 תווים.</p>
                        <p>
                          אסור לגלות את הסיסמה לאחר או להזינה בהודעת דואר
                          אלקטרוני.
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    תקופת ניסיון
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      המערכת תהיה רשאית להציע למשתמש, עם ההרשמה למערכת, תקופת
                      ניסיון ללא עלות למשך 14 יום, אשר מתחילה עם הפעלת החשבון
                      ואישור כתובת הדוא"ל על ידי המערכת
                      <strong>{` ("תקופת הניסיון") `}</strong> במקרה שתוצע תקופת
                      ניסיון כאמור, יחולו התנאים הבאים.
                    </li>
                    <li>
                      בתקופת הניסיון, המערכת תהיה פתוחה לשימוש בהתאם לחבילת
                      השימוש הבסיסית ותתאפשר הפקה דוחות סטטיסטים בלבד. תקופת
                      הניסיון ניתנת למשתמש ללא תניות כלשהן, לרבות העדר התחייבות
                      לאי קיומם של באגים או אי-זמינות של המערכת במהלך תקופת
                      הניסיון. למען הסר ספק, מובהר כי בתקופת הניסיון, יהיו
                      פתוחות לביצוע הפעולות הנכללות במסלול הבסיסי וכן ייתכן
                      ויפתחו פעולות נוספות בהתאם לשיקול דעתה הבלעדי של החברה,
                      כפי שיתעדכנו מעת לעת
                    </li>
                    <li>
                      לכל משתמש תהיה שמורה הזכות לתקופת ניסיון אחת בלבד, ולעניין
                      זה יראו גם את צד ג' הפועל לטובת אותו משתמש כמי שמיצה את
                      הזכות.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    תהליך הרכישה
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      התשלום עבור רישיון השימוש במערכת הינו בהתאם לחבילת השימוש
                      הרצויה על ידי המשתמש ובהתאם למסלולי החיוב החודשיים.
                    </li>
                    <li>
                      ההרשמה לקבלת רישיון תבוצע במסגרת חבילת שימוש במסלול חיוב
                      חודשי לתקופה בלתי מוגבלת, אשר מועד החיוב שלה חל מדי חודש
                      (בגין אותו חודש, מראש), עד לביטולה על ידי המשתמש להלן
                      <strong>{` ("חבילת שימוש חודשית") `}</strong>.
                    </li>
                    <li>
                      עסקה לרכישת חבילת שימוש, תוסף או כל שירות אחר תחויב מיידית
                      (אלא אם בחרה המערכת, לפי שיקול דעתה הבלעדי, לבצע חיוב
                      בדיעבד בתום התקופה הרלוונטית או בתום צריכת השירות
                      הרלוונטי). ככלל החיוב המיידי (הראשון) עשוי לכלול הן את
                      התמורה הצפויה בגין יתרת החודש הקלנדרי בו בוצעה העסקה והן
                      את התמורה מראש עבור מלוא החודש הקלנדרי העוקב לחודש בו
                      בוצעה העסקה. החיובים התקופתיים בגין התקופות העוקבות שלאחר
                      החיוב הראשון יבוצעו בתחילת כל תקופה, מראש.
                    </li>
                    <li>
                      <span className="font-bold">
                        המערכת תהיה רשאית, בכל עת:
                      </span>
                      <div className="pr-4">
                        <span className="block">
                          (1) לבטל חבילת שימוש קיימת/תוסף קיים, מכל סיבה שהיא;
                        </span>
                        <span className="block">
                          (2) או להעלות את מחירם של חבילת שימוש/תוסף; ובלבד
                          שלמשתמש תישלח הודעה של 30 ימים לפחות מראש ובכתב טרם
                          הביטול או העלאת המחיר (לפי העניין) (להלן: "
                          <strong>ההודעה המוקדמת</strong>") והביטול או העלאת
                          המחיר (לפי העניין) ייכנסו לתוקף בתום תקופת ההודעה
                          המוקדמת.
                        </span>
                      </div>
                      החברה תאפשר למשתמש לבחור אם להסכים לעדכון או לסיים את
                      ההתקשרות ביחס לחבילת השימוש/התוסף הרלוונטיים. שילם המשתמש
                      מראש בגין תקופה שלאחר תום תקופת ההודעה המוקדמת, יהיה אותו
                      משתמש זכאי להחזר יחסי בגין התמורה העודפת במקרה של סיום
                      ההתקשרות כאמור.
                    </li>
                    <li>
                      <span className="font-bold">
                        ניתן לבצע במערכת עסקאות באופן הבא:
                      </span>
                      <div className="pr-4">
                        <span className="block">
                          (1) תשלום בכרטיס אשראי - באמצעות תשלום חודשי בכרטיס
                          אשראי. המנוי מתחדש באופן אוטומטי מדי חודש, כל עוד לא
                          התקבלה הודעת ביטול מהמשתמש.
                        </span>
                        <span className="block">
                          (2) עסקה שבוצעה באשראי, כפופה לאישור חברות האשראי. בעל
                          המערכת רשאי לשנות, להוסיף או לגרוע דרכי ו/או אמצעי
                          תשלום וזאת מכל סיבה שהיא וללא הודעה מראש.
                        </span>
                        <span className="block">
                          (3) הרוכש מצהיר כי הוא הבעלים של אמצעי התשלום, או
                          שקיבל אישור להשתמש בכרטיס או באמצעי התשלום מבעליו.
                        </span>
                      </div>
                    </li>
                    <li>
                      <span className="font-bold">אופן הרכישה</span>
                      <div className="pr-4">
                        <p>
                          הרוכש יסמן ויבחר את מנוי הטפסים שברצונו לרכוש. הרוכש
                          יזין פרטים מזהים, פרטי יצירת קשר ופרטי אשראי. הרוכש
                          יאשר את נכונות הפרטים ויאשר כי קרא את תקנון המערכת.
                          אישור העסקה יתבצע לאחר קבלת האישור מחברת האשראי, חברת
                          הסליקה ו/או קבלת אסמכתא בדבר קבלת העברה בנקאית.
                        </p>
                        <p>
                          מובהר בזאת כי פרטי האשראי של הרוכש אינם נשמרים אצל בעל
                          המערכת. פרטי כרטיס האשראי יעובדו ו/או יישמרו על ידי
                          חברת הסליקה בלבד.
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    ביטול מנוי
                  </h2>
                  <p>
                    משתמש המעוניין לבטל חבילת שימוש במסלול חיוב חודשי רשאי לעשות
                    זאת בכל עת, בהודעה לחברה באחד האופנים המפורטים בסעיף, להלן,
                    ויחולו הכללים הבאים:
                  </p>
                  <p>
                    הביטול ייכנס לתוקף בתום החודש הקלנדרי בו ניתנה ההודעה,
                    המשתמש לא יהיה זכאי לזיכוי כלשהו, אך הוא יוכל לעשות שימוש
                    בשירותים שמאפשרת לו חבילת השימוש עד לתום אותו חודש קלנדרי,
                    בכפוף לתנאי חבילת השימוש.
                  </p>
                  <p>
                    משתמש המעוניין לבטל עסקה שבוצעה עם החברה, אשר יש לו זכות
                    לבטלה כאמור לעיל ביחס לכל סוגי חבילות השימוש והשירותים ,
                    יוכל לעשות זאת בכל אחד מן האופנים הבאים:
                  </p>

                  <ul className="mr-2 pr-4 space-y-2 list-inside list-disc">
                    <li>
                      בדואר אלקטרוני, לכתובת המייל,
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                    </li>
                    <li>באמצעות תמיכה מול נציג המערכת</li>
                    <li>
                      באמצעות לחיצה על כפתור "ביטול שירות" במערכת, בעמוד פרופיל
                      המשתמש
                    </li>
                  </ul>
                  <p>
                    מובהר כי המערכת מיועדת ללקוחות עסקיים, ובכך תנאי הביטול לפי
                    חוק הגנת הצרכן אינם חלים על ביטולי עסקה.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    שירות לקוחות
                  </h2>
                  <div className="mb-8 italic pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                    בכל פניה, בירור תלונה או שאלה, לקוחות המערכת מוזמנים לפנות
                    לשירות הלקוחות של המערכת באמצעות -
                    <Link
                      to={`mailto:${socials.email}`}
                      className="block text-primaryBlue"
                    >
                      {socials.email}
                    </Link>
                  </div>
                  <p>
                    טופס יצירת קשר, המופיע בעמוד
                    <Link
                      to="/contact"
                      className="font-bold mx-2 text-primaryBlue"
                    >
                      "צרו קשר"
                    </Link>
                    באתר החברה.
                  </p>
                  <p>
                    שירותי התמיכה ניתנים במהלך השעות 9:00 ל-18:00 בימי העסקים
                    הרגילים. מענה לכל פנייה יינתן תוך 24 שעות בימי העסקים ובשעות
                    הפעילות האמורות.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default TermsHE;
