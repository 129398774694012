import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "../../../../../../components/Modal";
import Icon from "../../../../../../components/ui/Buttons/Icon";

const Emails = ({
  item,
  dismiss,
  isVisible,
}: {
  item?: any;
  dismiss: () => void;
  isVisible: boolean;
}) => {
  const { emails, name, count } = item || { name: "", count: 0, emails: [] };
  return (
    <Modal showModal={isVisible} dismiss={dismiss} className="!w-full !pb-4">
      <div className="flex justify-between items-center">
        <h1 className="font-bold">{`(${count}) ${name}`}</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <div className="w-full divide-y divide-gray-100 max-h-450px overflow-y-auto">
        {emails?.map((email: string, index: number) => (
          <div className="flex flex-col gap-y-2 mb-2 py-2" key={index}>
            <p className="text-sm text-gray-900">{email}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default Emails;
