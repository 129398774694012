import React, { ErrorInfo } from "react";
import Section from "./Section";
import Button from "../components/Button";

// const Fallback = () => {
//   return (
//     <p className="w-full h-full flex items-center justify-center text-center font-semibold">
//       {"Something went wrong"}
//     </p>
//   );
// };
const Fallback = (error: Error, errorInfo: ErrorInfo) => {
  return (
    <Section className="" title="Something went wrong" badge="Error">
      <div className="mt-10 flex flex-col items-center justify-center">
        <div style={{ whiteSpace: "pre-wrap" }}>
          {error && JSON.stringify(error)}
        </div>
        {errorInfo.componentStack}
        <Button
          text={"Reload"}
          onClick={() => window.location.reload()}
          className="justify-center items-center !px-12 !py-3 mt-8 !font-black text-white bg-primaryBlue !max-md:px-5"
        />
      </div>
    </Section>
  );
};
export default Fallback;
