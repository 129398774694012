import React from "react";
import { BsCcCircle } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import { GoPeople } from "react-icons/go";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { PiSubtitles, PiTrendUpBold } from "react-icons/pi";
import {
  FlowEnum,
  PercentageEnum,
  StatProp,
  ViewEnum,
} from "../../../../utils/types";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../../../components/ui/card";
import { Tooltip } from "antd";
import { getCardParameter } from "../Charts/WrapperInfoHandler";
import { TbArrowWaveRightUp } from "react-icons/tb";
import RenderPercentage from "./RenderPercentage";
import convertMinutesToHoursMinutes from "../../../../utils/hour-formatter";
import { cn } from "../../../../utils/utils";

const iconMap: Record<string, JSX.Element> = {
  Messages: <HiOutlineMail size={20} color={"#2e72f3"} />,
  "Frequency Time": <FiClock size={20} color={"#2e72f3"} />,
  Participants: <GoPeople size={20} color={"#2e72f3"} />,
  Subjects: <PiSubtitles size={20} color={"#2e72f3"} />,
  Involvement: <BsCcCircle size={20} color={"#2e72f3"} />,
};
const titleMap: Record<FlowEnum, JSX.Element> = {
  [FlowEnum.INFLOW]: <div className="w-1 h-6 rounded-md bg-chart-current-in" />,
  [FlowEnum.OUTFLOW]: (
    <div className="w-1 h-6 rounded-md bg-chart-current-out" />
  ),
  [FlowEnum.ALL]: <></>,
};
const percentageMap: Record<PercentageEnum, (percent: number) => JSX.Element> =
  {
    [PercentageEnum.ASC]: (percent: number) =>
      RenderPercentage(<PiTrendUpBold size={15} />, "text-green-700", percent),
    [PercentageEnum.DESC]: (percent: number) =>
      RenderPercentage(
        <PiTrendUpBold size={15} className="-rotate-180" />,
        "text-red-700",
        percent
      ),
    [PercentageEnum.STABLE]: (percent: number) =>
      RenderPercentage(
        <TbArrowWaveRightUp size={15} />,
        "text-gray-400",
        percent
      ),
  };

const StatCard = ({
  stat,
  view,
  flow,
  index,
}: {
  stat: StatProp;
  view: ViewEnum;
  flow: FlowEnum;
  index: number;
}) => {
  const { tooltip } = getCardParameter(stat.title, view, flow);
  const percentageComponent = percentageMap[stat.info.percentageType](
    stat.info.percent
  );
  const isFrequency = stat.title === "Frequency Time";
  return (
    <Card x-chunk={`dashboard-01-chunk-${index}`}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          <Tooltip
            placement={"topLeft"}
            color={"#fff"}
            overlayInnerStyle={{ color: "#000" }}
            arrow={false}
            title={tooltip}
            className="cursor-pointer flex gap-1 items-center"
          >
            {stat.title}
            <div>
              <IoMdInformationCircleOutline color={"#707070 "} />
            </div>
          </Tooltip>
        </CardTitle>
        {iconMap[stat.title]}
      </CardHeader>
      <CardContent>
        <div className="space-y-1 divide-y divide-gray-200">
          {flow === stat.info.flow && (
            <div key={index} className="flex flex-wrap items-end gap-2">
              <div className="flex items-baseline gap-2">
                {titleMap[stat.info.flow]}
                <span
                  className={cn(
                    "font-bold text-4xl tabular-nums",
                    isFrequency && "text-3xl"
                  )}
                >
                  {isFrequency
                    ? convertMinutesToHoursMinutes(stat.info.value)
                    : stat.info.value}
                </span>
              </div>
              {percentageComponent}
            </div>
          )}
        </div>
        {/* 
            <p className="text-xs text-muted-foreground">
              +20.1% from last month
            </p> */}
      </CardContent>
    </Card>
  );
};
export default StatCard;
