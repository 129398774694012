
import React from "react";
import Volume from "../components/Charts/Volume";
import Timing from "../components/Charts/Timing";
import Connectivity from "../components/Charts/Connectivity";
import Involvement from "../components/Charts/Involvement";
import { ChartsTypeEnum, GraphTypeEnum } from "../../../utils/types";

const Statistics = (props: any) => {
  const { flow, report, view, charts } = props;
  const filters = { report, view, flow };
  return (
    <div className="grid gap-4 md:grid-cols-1 md:gap-8 lg:grid-cols-4">
      <Volume
        className="col-span-2"
        filters={{ graphType: GraphTypeEnum.VOLUME, ...filters }}
        details={charts[ChartsTypeEnum.VOLUME]}
      />
      <Timing
        className="col-span-2"
        filters={{
          graphType: GraphTypeEnum.FREQUENCY_TIME,
          ...filters,
        }}
        details={charts[ChartsTypeEnum.FREQUENCY_TIME]}
      />
      <Involvement
        className="col-span-2"
        filters={{
          graphType: GraphTypeEnum.INVOLVEMENT_RECIPIENTS,
          ...filters,
        }}
        details={charts[ChartsTypeEnum.INVOLVEMENT_RECIPIENTS]}
      />
      <Connectivity
        className="col-span-2"
        filters={{
          graphType: GraphTypeEnum.CONNECTIVITY_SCOPE,
          ...filters,
        }}
        details={charts[ChartsTypeEnum.CONNECTIVITY_SCOPE]}
      />
    </div>
  );
};

export default Statistics;
