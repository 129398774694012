import { useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import {
  GetPaymentForm,
  GetPlanPrice,
} from "../../../../modules/common/service";
import { useLocation } from "react-router-dom";

const usePayment = () => {
  const { toastError } = useAlertsContext();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const { plan, pricingId, paymentType } = location.state;
  const [priceWithVat, setPriceWithVat] = useState(plan.pricingMonthly);
  const [billingDetails, setBillingDetails] = useState(undefined);

  const [activeStep, setActiveStep] = useState("step-1");

  const fetchPlanPrice = async (country: string = "") => {
    setLoading(true);
    setPaymentURL("");
    await GetPlanPrice({ pricingId, paymentType, country })
      .then((response) => {
        setPriceWithVat(response.data.price);
        setBillingDetails(response.data);
      })
      .catch((e) => {
        console.log("GetDashboardPricing", e?.data?.message);
        toastError(e?.data?.message || "Error loading payment form");
      })
      .finally(() => setLoading(false));
  };
  const getIframe = async (values: any) => {
    const { address = "", country = "", organization = "" } = values || {};
    setLoading(true);
    await GetPaymentForm({
      pricingId,
      paymentType,
      country,
      address,
      organization,
    })
      .then((response) => {
        setPaymentURL(response?.data?.url);
        // setActiveStep("");
        // toastSuccess("Payment form loaded successfully");
      })
      .catch((e) => {
        console.log("GetDashboardPricing", e?.data?.message);
        toastError(e?.data?.message || "Error loading payment form");
      })
      .finally(() => setLoading(false));
  };

  return {
    getIframe,
    loading,
    paymentURL,
    planState: { plan, pricingId, paymentType },
    activeStep,
    setActiveStep,
    fetchPlanPrice,
    priceWithVat,
    billingDetails,
  };
};

export default usePayment;
