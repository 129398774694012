import { BlogProps } from "../data";
import { useNavigate } from "react-router-dom";

const ArticleCard = ({ post }: { post: BlogProps }) => {
  const navigate = useNavigate();
  const { id, datetime, date, image, title } = post;
  return (
    <article
      onClick={() => navigate("/Article", { state: { id } })}
      key={`${id}-blog`}
      className="cursor-pointer flex max-w-xl flex-col rounded-xl overflow-clip relative"
    >
      <div className="flex-1 flex flex-col gradient bg-gradient-to-t from-black/90 to-transparent z-10 pb-4 px-4 aspect-[9/10] justify-end ">
        <div className="flex flex-col gap-y-2 text-xs gap-x-2 text-gray-500">
          {/* <div className="flex items-center gap-x-2">
            <img
              // loading="lazy"
              alt={title}
              src={author.imageUrl}
              className="size-8 rounded-full bg-gray-50"
            />
            <span>{author.name}</span>
          </div> */}
          <time dateTime={datetime}>{date}</time>
        </div>
        <h3 className="text-white text-lg font-semibold mt-2">{title}</h3>
      </div>
      <img
        src={image}
        alt={title}
        className="object-cover absolute z-0 inset-0 size-full"
      />
    </article>
  );
};

export default ArticleCard;
