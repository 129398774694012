import React from "react";
import images from "../data/images";

const Planet = () => {
  return (
    <div className="w-full px-20 max-md:hidden">
      <div className="relative inline-flex rounded-full before:absolute before:inset-0 before:-z-10 before:scale-[.85] before:animate-[pulse_4s_cubic-bezier(.4,0,.6,1)_infinite] before:bg-gradient-to-b before:from-blue-900/20 before:to-sky-700/50 before:blur-3xl after:absolute after:inset-0 after:rounded-[inherit] after:[background:radial-gradient(closest-side,theme(colors.blue.500),transparent)]">
        <img
          className="rounded-full size-[250px]"
          src={images.PlanetImg}
          alt="Planet"
        />
        <div className="pointer-events-none" aria-hidden="true">
          <img
            className="absolute -right-40 -top-20 z-10 max-w-none size-[600px]"
            src={images.PlanetOverlayImg}
            alt="Planet decoration"
          />
          <div>
            <img
              className="absolute max-md:size-4 size-16 right-8 max-md:left-14 max-md:-top-4 top-0 z-10 animate-[float_4s_ease-in-out_infinite_both]"
              src={images.heroAvatar01}
              alt="Avatar 01"
            />
            <img
              className="absolute opacity-80 max-md:size-2 size-8 left-16 bottom-7 z-10 animate-[float_4s_ease-in-out_infinite_1s_both]"
              src={images.heroAvatar02}
              alt="Avatar 02"
            />
            <img
              className="absolute max-md:size-4 size-16 right-10 max-md:right-12 bottom-5 max-md:bottom-2 z-10 animate-[float_4s_ease-in-out_infinite_2s_both]"
              src={images.heroAvatar03}
              alt="Avatar 03"
            />
            <img
              className="absolute opacity-40 max-md:size-8 size-16 top-12 left-8 z-10 animate-[float_4s_ease-in-out_infinite_3s_both]"
              src={images.heroAvatar04}
              alt="Avatar 04"
            />
            <img
              className="absolute max-md:size-2 size-8 -top-4 right-32 max-md:right-24 z-10 animate-[float_4s_ease-in-out_infinite_3s_both]"
              src={images.heroAvatar05}
              alt="Avatar 05"
            />
            <img
              className="absolute opacity-60 max-md:size-2 size-8 bottom-16 max-md:bottom-8 right-64 max-md:right-4 z-10 animate-[float_4s_ease-in-out_infinite_3s_both]"
              src={images.heroAvatar06}
              alt="Avatar 06"
            />
          </div>
          {/* <div>
            <img
              className="absolute size-12 -left-4 top-16 z-10 animate-[float_4s_ease-in-out_infinite_2s_both] opacity-80 transition-opacity duration-500"
              src={images.heroAvatar01}
              alt="Tag 01"
            />
            <img
              className="absolute size-12 left-56 top-7 z-10 animate-[float_1s_ease-in-out_infinite_1s_both] opacity-30 transition-opacity duration-500"
              src={images.heroAvatar03}
              alt="Tag 02"
            />
            <img
              className="absolute size-8 -left-4 bottom-12 z-10 animate-[float_2s_ease-in-out_infinite_2s_both] opacity-80 transition-opacity duration-500"
              src={images.heroAvatar06}
              alt="Tag 03"
            />
            <img
              className="absolute size-8 bottom-24 -right-8 z-10 animate-[float_3s_ease-in-out_infinite_3s_both] opacity-70 transition-opacity duration-500"
              src={images.heroAvatar05}
              alt="Tag 04"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Planet;
