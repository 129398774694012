import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { Link } from "react-router-dom";
import socials from "../../data/configs";

const AccessibilityHE = () => {
  return (
    <Section
      id="AccessibilityStatement"
      badge={"Accessibility"}
      title="הצהרת נגישות"
    >
      <SEO title={"Accessibility Statement | InterSight"} />
      <div className="items-center justify-center flex flex-col text-right">
        <div className="my-16 w-[90%] lg:w-[50%]">
          <p className="font-bold text-primaryBlue text-center">
            תאריך עדכון אחרון: 14-08-2024
          </p>
          <div className="items-center justify-center mt-8">
            <div className="mb-10">
              <ul className="start-custom space-y-4 list-inside list-decimal">
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    {"מבוא"}
                  </h2>
                  <p className="italic pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                    בעל האתר ומערכת, "
                    <strong className="underline">הינתרסאיט בע"מ</strong> ",
                    מספר מזהה 517017349 ,פועל למתן שוויון הזדמנויות למבקרים
                    וגולשים בעלי מוגבלויות באמצעות הנגשת האתר והתאמות נדרשות, על
                    מנת שקהלים שונים יוכלו לקרוא את התכנים המפורסמים בו, וכן
                    לקבל מידע נוסף.
                  </p>
                  <p>
                    לפיכך פעל בעל האתר להנגיש את המערכת ואתר האינטרנט, שכתובת
                    הדומיין שלו:
                    <Link
                      to="https://www.inter-sight.com"
                      target="_blank"
                      className="font-bold mx-2 text-primaryBlue"
                    >
                      www.inter-sight.com
                    </Link>
                  </p>
                </li>
                <li className="[&>p]:!mb-1 [&>p]:!mt-2">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    {"התאמות הנגישות שבין היתר בוצעו באתר:"}
                  </h2>
                  <p className="font-bold">התאמות התצוגה באתר:</p>
                  <ul className="nested-list list-disc list-inside pr-4">
                    <li>הקראת טקסט</li>
                    <li>ניווט קולי</li>
                    <li>שינוי ניגודיות</li>
                    <li>הדגשת קישורים</li>
                    <li>הגדלת והקטנת טקסט</li>
                    <li>ריווח טקסט</li>
                    <li>השהיית אנימציות</li>
                    <li>הסתרת תמונות</li>
                    <li>ריווח שורות</li>
                    <li>יישור טקסט</li>
                  </ul>
                  <p className="font-bold">התאמות לבעלי מוגבלות מוטורית:</p>
                  <p>ניתן לשלוט ולגלוש באתר באמצעות המקלדת.</p>
                  <p>
                    בעל האתר עושה מאמצים להנגיש ולהתאים את האתר לפי דרישות התקן
                    והדין הישראלי. יחד עם זאת, ייתכן ותמצאו רכיבים מסוימים באתר
                    שאינם מונגשים בצורה מלאה.
                  </p>
                  <p>
                    אם נתקלתם בכך, אנא צרו קשר באמצעות אחת מדרכי יצירת הקשר
                    המפורטות מטה, ונעשה מאמץ לתקן זאת תוך זמן סביר ממועד קבלת
                    הפניה.
                  </p>
                  <p className="font-bold">
                    כדי לזרז את הטיפול, כדאי לצרף לפנייתכם פרטים מלאים ככל הניתן
                    על:
                  </p>
                  <ul className="nested-list list-disc list-inside pr-4 mt-2">
                    <li>הפעולה שניסיתם לבצע</li>
                    <li>העמוד הרלוונטי באתר</li>
                    <li>והבעיה שנתקלתם בה</li>
                  </ul>
                  <p className="font-bold">וכן פרטים טכניים כגון:</p>
                  <ul className="nested-list list-disc list-inside pr-4 mt-2">
                    <li>מערכת ההפעלה במכשיר שגלשתם ממנו</li>
                    <li>סוג הדפדפן וגרסתו</li>
                    <li>
                      סוג הטכנולוגיה המסייעת שבה השתמשתם, במידה והדבר רלוונטי
                    </li>
                  </ul>
                </li>
                <li>
                  התאמות נגישות פיזיות: לעסק ולחברה אין קבלת קהל ו/או משרדים.
                  הפעילות מקוונת בלבד
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    יצירת קשר
                  </h2>
                  <p>פניות בנושאי נגישות, שאלות או בקשות יש להפנות אל:</p>
                  <p>שם: יותם זכאי</p>
                  <div className="italic [&>p]:!mb-1 mt-2 pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                    <span>באמצעות דרכי יצירת הקשר הבאות:</span>
                    <p>
                      דואר אלקטרוני:
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}.
                      </Link>
                    </p>
                    <p>
                      טלפון:
                      <Link
                        dir="ltr"
                        to={`tel:${socials.phone}`}
                        className="mx-2 text-primaryBlue"
                      >
                        {socials.phone}
                      </Link>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AccessibilityHE;
