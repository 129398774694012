import { useNavigate } from "react-router-dom";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import * as Actions from "./types";
import {
  DeleteMyAccount,
  GetProfile,
  GetSubscription,
  GoogleLogIn,
  MSLogIn,
  RefreshToken,
} from "./service";
import {
  clearToken,
  getToken,
  saveData,
  setToken,
} from "../../utils/get-token";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";
import { AuthenticationType } from "../../utils/types";
import AuthSelectors from "./selectors";
import { generateCodeVerifierAndChallenge } from "../../utils/code-challenge";

const AuthActions = () => {
  const { toastError, toastSuccess } = useAlertsContext();
  const { authenticatedUsing } = AuthSelectors();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const logout = () => {
    return async (dispatch) => {
      clearToken();
      if (authenticatedUsing === AuthenticationType.GOOGLE) {
        googleLogout();
      }
      if (authenticatedUsing === AuthenticationType.MICROSOFT) {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }
      dispatch({ type: Actions.LOGOUT_SUCCESS, payload: {} });
      navigate("/", { replace: true });
    };
  };
  const handleRefreshToken = () => {
    return async (dispatch) => {
      dispatch({ type: Actions.REFRESH_TOKEN_PENDING });
      const token = getToken() ?? "";
      await RefreshToken(token)
        .then((response) => {
          setToken(response?.token);
          dispatch({
            type: Actions.REFRESH_TOKEN_SUCCESS,
            payload: response,
          });
        })
        .catch((e) => {
          console.error("RefreshToken", e);
          toastError(e?.data || "Token Expired");
          dispatch(logout());
        });
    };
  };

  const msLogIn = (code, codeVerifier, cb = () => {}) => {
    return async (dispatch) => {
      saveData("msLogIn", code);
      dispatch({ type: Actions.SIGN_IN_REQUEST });
      await MSLogIn(code, codeVerifier)
        .then((response) => {
          setToken(response?.data?.token, response?.data?.expiresAt);
          dispatch({
            type: Actions.SIGN_IN_SUCCESS,
            payload: {
              data: response?.data,
              authenticatedUsing: AuthenticationType.MICROSOFT,
            },
          });
          cb && cb();
        })
        .catch((e) => {
          console.error("LogIn", e);
          toastError(e?.data?.message);
          dispatch({ type: Actions.SIGN_IN_ERROR });
          navigate("/get-started/Login", { replace: true });
        });
    };
  };
  const googleLogIn = (data, cb = () => {}) => {
    return async (dispatch) => {
      dispatch({ type: Actions.SIGN_IN_REQUEST });
      await GoogleLogIn(data)
        .then((response) => {
          setToken(response?.data?.token, response?.data?.expiresAt);
          dispatch({
            type: Actions.SIGN_IN_SUCCESS,
            payload: {
              data: response?.data,
              authenticatedUsing: AuthenticationType.GOOGLE,
            },
          });
          cb && cb();
        })
        .catch((e) => {
          console.error("LogIn", e);
          toastError(e?.data?.message);
          dispatch({ type: Actions.SIGN_IN_ERROR });
          navigate("/get-started/Login", { replace: true });
        });
    };
  };

  const googleLoginAction = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      toastSuccess(JSON.stringify(tokenResponse));
    },
    onError: (errorResponse) => {
      toastError(errorResponse?.message);
    },
    onNonOAuthError: (nonOAuthError) => {
      toastError(nonOAuthError?.message);
    },
    callback: (response) => {
      navigate(`/google-callback/${response?.code}`);
    },
    flow: "credential",
    scope: "https://www.googleapis.com/auth/gmail.metadata",
    ux_mode: "redirect",
    redirect_uri: `${process.env.REACT_APP_SITE_URL}google-callback`,
  });

  const msLoginAction = async () => {
    const { codeVerifier, codeChallenge } =
      await generateCodeVerifierAndChallenge();
    const authorizationUrl = `https://login.microsoftonline.com/${process.env.REACT_APP_MS_AUTHORITY}/oauth2/v2.0/authorize
    ?client_id=${process.env.REACT_APP_MS_CLIENT_ID}
    &response_type=code
    &redirect_uri=${process.env.REACT_APP_SITE_URL}ms-callback
    &scope=MailboxSettings.Read%20openid%20profile%20offline_access%20Mail.ReadBasic%20User.Read
    &code_challenge=${codeChallenge}
    &code_challenge_method=S256`;
    saveData("codeVerifier", codeVerifier);
    window.location.href = authorizationUrl;
    // await instance
    //   .loginRedirect(tokenRequest)
    //   .then(async (response) => {
    //     console.log("msLoginAction", { response });
    //     saveData("_msLoginAction", response);
    //   })
    //   .catch((error) => {
    //     saveData("_loginRedirect2", error);
    //     toastError(error);
    //   });
  };
  const deleteAccount = (cb = () => {}) => {
    return async (dispatch) => {
      await DeleteMyAccount()
        .then((response) => {
          cb && cb();
          dispatch(logout());
        })
        .catch((e) => {
          console.error("deleteAccount", e);
          toastError(e?.data?.message);
        });
    };
  };
  const fetchProfile = (cb = () => {}) => {
    return async (dispatch) => {
      await GetProfile()
        .then((response) => {
          dispatch({
            type: Actions.FETCH_PROFILE_SUCCESS,
            payload: response?.data,
          });
          cb && cb();
        })
        .catch((e) => {
          console.error("fetchProfile", e);
          toastError(e?.data || "Error fetching profile");
        });
    };
  };
  const fetchSubscriptionPermission = (cb = () => {}) => {
    return async (dispatch) => {
      await GetSubscription()
        .then((response) => {
          dispatch({
            type: "SAVE_SUBSCRIPTIONS",
            payload: response?.data,
          });
          cb && cb();
        })
        .catch((e) => {
          console.error("fetchProfile", e);
          toastError(e?.data || "Error fetching profile");
        });
    };
  };
  return {
    msLogIn,
    googleLogout,
    googleLoginAction,
    googleLogIn,
    msLoginAction,
    logout,
    handleRefreshToken,
    deleteAccount,
    fetchProfile,
    fetchSubscriptionPermission,
  };
};

export default AuthActions;
