import { InviteStatus } from "../../../../utils/types";

const Status = ({
  status,
  small,
}: {
  status: { id: InviteStatus; name: string };
  small?: boolean;
}) => {
  const defaultClass = `font-medium px-4 py-1 rounded-${
    small ? "md !px-2" : "full"
  } border`;
  let statusColor = "";
  switch (status.id) {
    case InviteStatus.PENDING:
      statusColor = "bg-yellow-100 text-yellow-600 border-yellow-100";
      break;
    case InviteStatus.INVITE_SENT:
      statusColor = "bg-blue-100 text-blue-800 border-blue-300";
      break;
    case InviteStatus.ACTIVE:
      statusColor = "bg-green-100 text-green-800 border-green-300";
      break;
    case InviteStatus.NOT_ACTIVE:
      statusColor = "bg-red-100 bg-red-100 text-red-600 border-red-300";
      break;

    default:
      break;
  }
  return (
    <span className={`${defaultClass} ${statusColor}`}>{status.name}</span>
  );
};

export default Status;
