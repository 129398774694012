import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import Blogs from "./Blogs";
import CaseStudies from "./CaseStudies";
import GetStart from "../../components/GetStart";

const Resources = () => {
  return (
    <Section id="Resources">
      <SEO title={"Resources | InterSight"} />
      <section className="px-5 lg:px-20 mt-10 lg:mt-16 w-full">
        <Blogs />
        <CaseStudies />
        <GetStart />
      </section>
    </Section>
  );
};

export default Resources;
