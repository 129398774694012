
import React from "react";
import { ProfileDT } from "../../../../utils/types";
import EditProfile from "./EditProfile";
import ActivateProfile from "./ActivateProfile";
import DeleteProfile from "./DeleteProfile";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, MenuProps } from "antd";
import Icon from "../../../../components/ui/Buttons/Icon";
import { ActivityStatus as ActivityStatusEnum } from "../../../../utils/types";

const Actions = ({
  profile,
  fetchProfiles,
}: {
  profile: ProfileDT;
  fetchProfiles: Function;
}) => {
  const items: MenuProps["items"] =
    profile.activityStatus.id === ActivityStatusEnum.Deactive
      ? [
          {
            key: "1",
            label: (
              <ActivateProfile
                profile={profile}
                fetchProfiles={fetchProfiles}
              />
            ),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <EditProfile profile={profile} fetchProfiles={fetchProfiles} />
            ),
          },
          {
            key: "2",
            label: (
              <DeleteProfile teamId={profile.id} fetchTeams={fetchProfiles} />
            ),
          },
        ];
  return (
    <Dropdown menu={{ items }} placement="bottomLeft" destroyPopupOnHide>
      <div>
        <Icon>
          <BsThreeDotsVertical />
        </Icon>
      </div>
    </Dropdown>
  );
};

export default Actions;
