import React from "react";
import Modal from "../../../components/Modal";
import { FcUnlock } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import PermissionsSelectors from "../../../modules/permissions/selectors";
import PermissionActions from "../../../modules/permissions/actions";
import { useDispatch } from "react-redux";
import images from "../../../data/images";

const SubscriptionModal = () => {
  const { closeModal } = PermissionActions();
  const { isOpen, message } = PermissionsSelectors();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(closeModal());
  };
  if (!isOpen) return null;

  return (
    <Modal
      showModal={isOpen}
      dismiss={handleClose}
      className="w-[30dvw] max-md:min-w-full"
    >
      <div className="flex justify-center items-center rounded-lg">
        <div className="gap-2 flex flex-col justify-center items-center rounded-lg">
          <img
            className="h-[20rem]"
            src={images.upgradePlan}
            alt="Upgrade InterSight Plan Illustration"
          />
          <FcUnlock size={32} />
          <span className="text-placeholder text-center">
            <strong>{message}</strong> Unlock by upgrading InterSight Plan
          </span>
          <div className="flex gap-2 w-full items-center justify-center">
            <Button
              text="Go back"
              className="w-[45%] text-sm"
              variant="secondary"
              onClick={handleClose}
            />
            <Button
              text="Upgrade"
              className="w-[45%] text-sm"
              onClick={() => navigate("/Dashboard/plans")}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
