import React, { useRef } from "react";
import Section from "../../layouts/Section";
import { TiStarFullOutline } from "react-icons/ti";
import images from "../../data/images";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
// import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation } from "swiper/modules";

const testimonials = [
  {
    id: 1,
    image: images.testimonials1,
    role: "CFO",
    text: "Feels like it was made for us—simple, powerful, and their support is just fantastic!",
  },
  {
    id: 2,
    image: images.testimonials2,
    role: "CEO",
    text: "We redefined how we work—this tool simplifies workflows and keeps everything moving smoothly.",
  },
  {
    id: 3,
    image: images.testimonials3,
    role: "CFO",
    text: "Seriously, I love how easy this tool makes everything. The support team is Super friendly and quick to help!",
  },
  {
    id: 4,
    image: images.testimonials4,
    role: "CEO",
    text: "We now have control over our operations. This tool makes managing tasks and processes a smooth ride.",
  },
  {
    id: 5,
    image: images.testimonials5,
    role: "CTO",
    text: "Our responsiveness has skyrocketed—this tool helps us stay sharp and address things immediately.",
  },
  {
    id: 6,
    image: images.testimonials6,
    role: "COO",
    text: "I love how fast I can pull everything together now—data, insights, and reports, all in one place!",
  },
  {
    id: 7,
    image: images.testimonials7,
    role: "CFO",
    text: "This tool speeds up everything—what used to take hours now gets done in minutes with incredible accuracy.",
  },
  {
    id: 8,
    image: images.testimonials8,
    role: "CEO",
    text: "This boosted my productivity! Super easy to use, and the support team was awesome!",
  },
  {
    id: 9,
    image: images.testimonials9,
    role: "CTO",
    text: "It’s like having a control center for everything we do. Management is so much smoother now!",
  },
  {
    id: 10,
    image: images.testimonials10,
    role: "CFO",
    text: "Our team’s finally in sync! This tool makes collaborating easy, and their support team is so nice!",
  },
  {
    id: 11,
    image: images.testimonials11,
    role: "COO",
    text: "We finally make decisions backed by solid data. The insights are clear, reliable, and actionable.",
  },
];
const TestimonialsCarousel = () => {
  const swiperRef = useRef<any>(null);

  return (
    <Section badge="Testimonials" title="What Our Users Experiencing" className="!pb-0">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 mt-8">
        <Swiper
          effect="fade"
          slidesPerView={"auto"}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          spaceBetween={30}
          modules={[Navigation, Autoplay, EffectFade]}
          // modules={[Navigation, Autoplay, Pagination]}
          onSwiper={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          shortSwipes={false}
          speed={2000}
          // pagination={{ clickable: true }}
          className="mySwiper mt-10"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center p-4 text-center gap-4 rounded-lg bg-gray-50">
                <img
                  // loading="lazy"
                  src={testimonial.image}
                  alt={`User ${index + 1}`}
                  className="h-16 w-16 rounded-full object-cover"
                />
                <span className="text-xs text-gray-800 font-semibold">
                  {testimonial.role}
                </span>
                <div className="flex items-center">
                  {[...Array(5)].map((_, i) => (
                    <TiStarFullOutline key={i} size={15} color={"#E8B903"} />
                  ))}
                </div>
                <p className="mt-2 text-gray-700 lg:max-w-[40%]">{testimonial.text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
};

export default TestimonialsCarousel;
