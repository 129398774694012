import React, { useState } from "react";
import { ITeammateRoleType, TeammateType } from "../../../../utils/types";
import { Select, SelectProps } from "antd";
import useActions from "../hooks/useActions";

const typeOptions: SelectProps["options"] = [
  {
    label: "Manager",
    value: TeammateType.MANAGER,
  },
  {
    label: "Member",
    value: TeammateType.MEMBER,
  },
];
const TypeInput = ({
  domainId,
  type,
}: {
  domainId: string;
  type: ITeammateRoleType;
}) => {
  const defaultType = type ? { label: type.name, value: type.id } : undefined;
  const [selectedType, setSelectedType] = useState(defaultType);
  const { EditRole } = useActions();
  const handleEdit = (option: { label: string; value: TeammateType }) => {
    setSelectedType(option);
    EditRole({
      id: domainId,
      role: option.value,
    });
  };

  return (
    <div>
      <Select
        // showSearch
        style={{ width: "100%" }}
        placeholder={"Select type"}
        value={selectedType}
        onChange={(value) => handleEdit(value)}
        options={typeOptions}
        className={"h-[2.5rem]"}
        size={"large"}
        allowClear={false}
      />
    </div>
  );
};

export default TypeInput;
