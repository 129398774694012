import BaseService from "../../../../services/BaseService";
import { API_ENDPOINTS } from "../../../../utils/api-endpoints";
import {
  AllDTFilter,
  CreateTeamForm,
  RequestMethod,
  TeamForm,
  TeammateRole,
} from "../../../../utils/types";

export const GetTeams = async (requestBody: AllDTFilter) =>
  await BaseService(API_ENDPOINTS.GET_TEAMS, RequestMethod.POST, requestBody);

export const GetTeamById = async (id: string) =>
  await BaseService(`${API_ENDPOINTS.GET_TEAM_BY_ID}/${id}`, RequestMethod.GET);

export const DeleteTeam = async (id: string) =>
  await BaseService(`${API_ENDPOINTS.DELETE_TEAM}/${id}`, RequestMethod.DELETE);

export const UpdateRole = async (requestBody: TeammateRole) =>
  await BaseService(
    API_ENDPOINTS.UPDATE_TEAMMATES_ROLE,
    RequestMethod.POST,
    requestBody
  );
export const EditTeam = async (requestBody: TeamForm) =>
  await BaseService(API_ENDPOINTS.EDIT_TEAM, RequestMethod.POST, requestBody);

export const CreateTeam = async (requestBody: CreateTeamForm) =>
  await BaseService(API_ENDPOINTS.CREATE_TEAM, RequestMethod.POST, requestBody);

export const GetTeammates = async (requestBody: AllDTFilter) =>
  await BaseService(
    API_ENDPOINTS.GET_TEAMMATES,
    RequestMethod.POST,
    requestBody
  );
export const GetTeammatesById = async (id: string) =>
  await BaseService(
    `${API_ENDPOINTS.GET_TEAMMATES_BY_ID}/${id}`,
    RequestMethod.GET
  );
export const RevokeInvitationTeammate = async (id: string) =>
  await BaseService(
    `${API_ENDPOINTS.DELETE_TEAMMATES}/${id}`,
    RequestMethod.DELETE
  );

export const InviteTeammate = async (email: string, teamId?: string) =>
  await BaseService(API_ENDPOINTS.INVITE_TEAMMATES, RequestMethod.POST, {
    email,
    teamId,
  });
export const ResendInvitationTeammate = async (id: string) =>
  await BaseService(
    `${API_ENDPOINTS.RESEND_INVITATION_TEAMMATES}/${id}`,
    RequestMethod.POST
  );
export const FetchInvite = async (id: string) =>
  await BaseService(`${API_ENDPOINTS.FETCH_INVITE}/${id}`, RequestMethod.GET);
