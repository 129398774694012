
import {
  ICardContent,
  FlowEnum,
  GraphTypeEnum,
  ReportTypeEnum,
  ViewEnum,
} from "../../../../utils/types";

type ParameterCardContent = {
  [key in string]?: {
    [key in ViewEnum]?: {
      [key in FlowEnum]?: { tooltip: string };
    };
  };
};
const parameterContent: ParameterCardContent = {
  Messages: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Volume of messages",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received messages",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent messages",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal messages",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received messages internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent messages internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External messages",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received messages externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent messages externally",
      },
    },
  },
  "Frequency Time": {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Messages pace",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received pace",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent pace",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal pace",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received pace internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent pace internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External pace",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received pace externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent pace externally",
      },
    },
  },
  Participants: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Participants counts",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received participants",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent participants",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal participants",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received participants internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent participants internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External participants",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received participants externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent participants externally",
      },
    },
  },
  Involvement: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Appearance of  CC",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received CC",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent CC",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal CC",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received CC internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent CC internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External CC",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received CC externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent CC externally",
      },
    },
  },
  Subjects: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Number of subjects",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received subjects",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent subjects",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal subjects",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received subjects internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent subjects internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External subjects",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received subjects externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent subjects externally",
      },
    },
  },
};
type ReportCardContent = {
  [graphType in GraphTypeEnum]?: {
    [view in ViewEnum]?: {
      [flow in FlowEnum]?: ICardContent;
    };
  };
};
const cardContent: ReportCardContent = {
  [GraphTypeEnum.VOLUME]: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        title: "Volume of messages",
        description: "Analyze quantities of messages",
        tooltip: "Sent or received messages",
      },
      [FlowEnum.INFLOW]: {
        title: "Volume of messages",
        description: "Analyze quantities of received messages",
        tooltip: "Received messages",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Volume of messages",
        description: "Analyze quantities of sent messages",
        tooltip: "Sent messages",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Volume of messages",
        description: "Analyze quantities of internal messages",
        tooltip: "Internal messages",
      },
      [FlowEnum.INFLOW]: {
        title: "Volume of messages",
        description: "Analyze quantities of received messages internally",
        tooltip: "Receiving messages internally",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Volume of messages",
        description: "Analyze quantities of sent messages internally",
        tooltip: "Sent messages internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Volume of messages",
        description: "Analyze quantities of external messages",
        tooltip: "External messages",
      },
      [FlowEnum.INFLOW]: {
        title: "Volume of messages",
        description: "Analyze quantities of received messages externally",
        tooltip: "Received messages externally",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Volume of messages",
        description: "Analyze quantities of sent messages externally",
        tooltip: "Sent messages externally",
      },
    },
  },
  [GraphTypeEnum.FREQUENCY_TIME]: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        title: "Interactions pace",
        description: "Explore time pace of messages",
        tooltip: "Average pace of sent or received messages",
      },
      [FlowEnum.INFLOW]: {
        title: "Interactions pace",
        description: "Explore time pace of received messages",
        tooltip: "Average pace of received messages",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Interactions pace",
        description: "Explore time pace of sent messages",
        tooltip: "Average pace of sent messages",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Interactions pace",
        description: "Explore time pace of internal messages",
        tooltip: "Average pace of internal messages",
      },
      [FlowEnum.INFLOW]: {
        title: "Interactions pace",
        description: "Explore time pace of received messages internally",
        tooltip: "Average pace of received messages internally",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Interactions pace",
        description: "Explore time pace of sent message internally",
        tooltip: "Average pace of sent messages internally ",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Interactions pace",
        description: "Explore time pace of external messages",
        tooltip: "Average pace of external messages",
      },
      [FlowEnum.INFLOW]: {
        title: "Interactions pace",
        description: "Explore time pace of received messages externally",
        tooltip: "Average pace of received messages externally",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Interactions pace",
        description: "Explore time pace of sent messages externally",
        tooltip: "Average pace of sent messages externally",
      },
    },
  },
  [GraphTypeEnum.INVOLVEMENT_RECIPIENTS]: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        title: "Involvement level",
        description: "Measure appearances of CC",
        tooltip: "Sent CC or received CC",
      },
      [FlowEnum.INFLOW]: {
        title: "Involvement level",
        description: "Measure appearances of received CC ",
        tooltip: "Received CC",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Involvement level",
        description: "Measure appearances of sent CC",
        tooltip: "Sent CC",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Involvement level",
        description: "Measure appearances of internal CC",
        tooltip: "Internal CC (sent or received)",
      },
      [FlowEnum.INFLOW]: {
        title: "Involvement level",
        description: "Measure appearances of received internal CC",
        tooltip: "Recived internal CC",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Involvement level",
        description: "Measure appearances of sent internal CC",
        tooltip: "Internal CC",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Involvement level",
        description: "Measure appearances of external CC",
        tooltip: "External CC (sent or received)",
      },
      [FlowEnum.INFLOW]: {
        title: "Involvement level",
        description: "Measure appearances of received external CC",
        tooltip: "Received external CC",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Involvement level",
        description: "Measure appearances of sent external CC",
        tooltip: "Sent external CC",
      },
    },
  },
  [GraphTypeEnum.CONNECTIVITY_SCOPE]: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        title: "Connectivity scope",
        description: "Discover active participants",
        tooltip: "Sent to or received from",
      },
      [FlowEnum.INFLOW]: {
        title: "Connectivity scope",
        description: "Discover active participants of received domains",
        tooltip: "Received domains",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Connectivity scope",
        description: "Discover active participants of sent messages",
        tooltip: "Sent domains",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Connectivity scope",
        description: "Discover active participants internally",
        tooltip: "Sent to or received from internal domains",
      },
      [FlowEnum.INFLOW]: {
        title: "Connectivity scope",
        description:
          "Discover active participants of received domains internally",
        tooltip: "Received domains internally",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Connectivity scope",
        description: "Discover active participants of sent domains internally",
        tooltip: "Sent domains internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        title: "Connectivity scope",
        description: "Discover active participants externally",
        tooltip: "Sent to or received from external domains",
      },
      [FlowEnum.INFLOW]: {
        title: "Connectivity scope",
        description:
          "Discover active participants of received domains externally",
        tooltip: "Received domains externally",
      },
      [FlowEnum.OUTFLOW]: {
        title: "Connectivity scope",
        description: "Discover active participants of sent domains externally",
        tooltip: "Sent domains externally",
      },
    },
  },
};

// Handler function to get card content based on filters
export const getCardContent = (
  report: ReportTypeEnum,
  graphType: GraphTypeEnum,
  view: ViewEnum,
  flow: FlowEnum
) => {
  return (
    cardContent?.[graphType]?.[view]?.[flow] || {
      title: "-",
      description: "-",
      tooltip: "-",
    }
  );
};
export const getCardParameter = (
  parameterType: string,
  view: ViewEnum,
  flow: FlowEnum
) => {
  return (
    parameterContent?.[parameterType]?.[view]?.[flow] || {
      tooltip: "-",
    }
  );
};
