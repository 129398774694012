
import { useState } from "react";
import Modal from "../../../../components/Modal";
import { IoCloseOutline } from "react-icons/io5";
import Icon from "../../../../components/ui/Buttons/Icon";
import Button from "../../../../components/Button";
import useActions from "../hooks/useActions";
import { ProfileDT } from "../../../../utils/types";
import { FiEdit } from "react-icons/fi";

const ActivateProfileModal = ({
  showModal,
  dismiss,
  submit,
  loading,
}: {
  showModal: boolean;
  dismiss: Function;
  submit: Function;
  loading: boolean;
}) => {
  return (
    <Modal
      showModal={showModal}
      dismiss={() => dismiss()}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold">{"Activate Profile"}</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        Customize and change the outsider's status.
      </p>
      <div className="mt-8">
        <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
          <Button
            text={"Cancel"}
            disabled={loading}
            variant="secondary"
            onClick={() => dismiss()}
            className="justify-center items-center !py-2"
          />
          <Button
            text={"Activate"}
            onClick={() => submit()}
            loading={loading}
            className="justify-center items-center !px-12 !py-2 text-white bg-primaryBlue !max-md:px-5"
          />
        </div>
      </div>
    </Modal>
  );
};

const ActivateProfile = ({
  fetchProfiles,
  profile,
}: {
  fetchProfiles: Function;
  profile: ProfileDT;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { ActivateProfile } = useActions();
  const Active = () => {
    setIsSubmitting(true);
    ActivateProfile(profile.id, (close) => {
      fetchProfiles();
      setIsSubmitting(false);
      if (close) {
        setModalVisible(false);
      }
    });
  };
  return (
    <>
      <div
        className="flex gap-2 items-center"
        onClick={(e) => {
          e?.stopPropagation();
          setModalVisible(true);
        }}
      >
        <FiEdit />
        <span>Activate Domain</span>
      </div>
      {modalVisible ? (
        <ActivateProfileModal
          showModal={modalVisible}
          loading={isSubmitting}
          dismiss={() => {
            setModalVisible(false);
          }}
          submit={() => Active()}
        />
      ) : null}
    </>
  );
};

export default ActivateProfile;
