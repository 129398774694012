import { useEffect, useState } from "react";
import AuthSelectors from "../../../modules/auth/selectors";
import AuthActions from "../../../modules/auth/actions";
import { useAppDispatch } from "../../../hooks/useStore";
import PermissionActions from "../../../modules/permissions/actions";
import PermissionsSelectors from "../../../modules/permissions/selectors";

const useSubscription = () => {
  const { user, role, isLoggedIn, token } = AuthSelectors();
  const { permissions } = PermissionsSelectors();
  const { fetchProfile } = AuthActions();
  const { fetchSubscriptionPermission, closeModal } = PermissionActions();

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const fetchUserData = async () => {
    setLoading(true);
    dispatch(closeModal());
    dispatch(fetchProfile());
    dispatch(fetchSubscriptionPermission(() => setLoading(false)));
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  return {
    user,
    loading,
    isLoggedIn,
    token,
    permissions,
    role,
  };
};
export default useSubscription;
