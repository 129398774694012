import { useDispatch } from "react-redux";
import PermissionActions from "../../../modules/permissions/actions";
import { checkPermission } from "./CheckPermission";
import PermissionsSelectors from "../../../modules/permissions/selectors";
import { useEffect, useState } from "react";
import { checkVisibleElements } from "./CheckVisibleElements";

const usePermissionHandler = () => {
  const dispatch = useDispatch();
  const [hiddenElements, setHiddenElements] = useState({
    billings: true,
    plans: true,
    insidersTab: true,
    outsidersTab: true,
    insidersTable: false,
    outsidersTable: false,
    usersFilter: true,
    exportToCSV: false,
    teammateSearch: false,
    addressInfo: false,
    subscriptionPlan: true,
  });
  const { permissions } = PermissionsSelectors();
  const { openModal } = PermissionActions();
  const CheckElements = () =>
    checkVisibleElements(permissions, (elements) =>
      setHiddenElements(elements)
    );
  useEffect(() => {
    CheckElements();
  }, []);

  const handleActionWithPermission = (action, callback) => {
    const { isAllowed, message, cb } = checkPermission(
      permissions || {},
      action
    );
    if (!isAllowed) {
      dispatch(openModal(message));
    } else if (isAllowed && cb && callback) {
      callback();
    } else {
      dispatch(openModal(message));
    }
  };

  return { handleActionWithPermission, hiddenElements, permissions };
};

export default usePermissionHandler;
