
import Section from "../../../layouts/Section";
import React, { useEffect } from "react";
import ProfilesTable from "./components/ProfilesTable";
import useActions from "./hooks/useActions";
import TableHeader from "../../../components/ui/TableHeader";
import InviteProfile from "./components/InviteProfile";
import NeedSubscription from "../components/NeedSubscription";
import { Select } from "antd";
import usePermissionHandler from "../hooks/handleActionWithPermission";

const People = () => {
  const { hiddenElements } = usePermissionHandler();
  const {
    FetchPeople,
    searchTerm,
    tableParams,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
    data,
    loading,
    ReferAFriend,
    domainsOptions,
    selectedDomain,
    setSelectedDomain,
    exportingCSV,
    downloadReport,
  } = useActions();

  useEffect(() => {
    FetchPeople();
  }, [JSON.stringify(tableParams), searchTerm, selectedDomain]);

  // return isAdmin ? (
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4 bg-white">
      <div className="relative">
        <TableHeader
          searchTerm={searchTerm}
          placeholder={"Search by name, email..."}
          handler={(e: React.ChangeEvent<HTMLInputElement> | null) =>
            handleSearch(e ? e.target.value : "")
          }
          leftNode={
            <InviteProfile
              inviteFriend={ReferAFriend}
              exportingCSV={exportingCSV}
              downloadReport={downloadReport}
            />
          }
          rightNode={
            <Select
              showSearch
              disabled={!hiddenElements.teammateSearch}
              value={selectedDomain}
              placeholder={"Select domain"}
              maxTagCount={"responsive"}
              onChange={(value) => setSelectedDomain(value)}
              options={domainsOptions}
              className={"h-[3.5rem] w-[14rem]"}
              allowClear
            />
          }
        />
        <div className="relative">
          {!hiddenElements.outsidersTable ? (
            <NeedSubscription title="Outsiders" />
          ) : null}
          <ProfilesTable
            data={data}
            loading={loading}
            tableParams={tableParams}
            handleTableChange={handleTableChange}
            handlePaginationChange={handlePaginationChange}
            fetchPeople={FetchPeople}
          />
        </div>
      </div>
    </Section>
  );
  // ) : (
  //   <Navigate to={"/Dashboard"} />
  // );
};

export default People;
