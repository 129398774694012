import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuGroup,
} from "../../../../components/ui/dropdown-menu";
import { cn } from "../../../../utils/utils";
import React from "react";
import { PiBellSimpleBold } from "react-icons/pi";
import { useMediaQuery } from "react-responsive";
import NotificationList from "./NotificationList";
import { Notification as NotificationType, notifications } from "./data";
import useNotification from "./hooks/useNotification";
import { NotificationDisplay } from "./notification-display";
import { Button } from "../../../../components/ui/button";
import { MailOpen } from "lucide-react";
import { INotification } from "../../../../utils/types";

const Notification = () => {
  const isMobile = useMediaQuery({ maxWidth: "769px" });
  const { notification, closeModal, openNotification, markAsRead } =
    useNotification();
  const { unreadCount, selected, allNotifications } = notification;
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant={"ghost"}
            className="rounded-full group relative max-md:px-2"
          >
            <PiBellSimpleBold
              className={cn(
                "size-5 text-gray-400 group-hover:text-primaryBlue group-hover:scale-95",
                // isMobile && "hidden",
                unreadCount > 0 && "text-primaryBlue"
              )}
            />
            {unreadCount > 0 && (
              <span className="absolute flex select-none items-center justify-center top-0 right-0 bg-primaryBlue text-white border border-white rounded-full size-5 text-center font-bold text-[0.6rem]">
                {unreadCount}
              </span>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={cn(isMobile ? "w-dvw" : "w-[30rem]")}
          sideOffset={15}
        >
          <DropdownMenuLabel className="flex justify-between items-center">
            <span>Notification</span>
            <Button variant="ghost" className="gap-2" onClick={markAsRead}>
              <MailOpen className="h-4 w-4" />
              Mark all as read
            </Button>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <NotificationList
              notification={notification}
              openNotification={openNotification}
            />
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <NotificationDisplay
        notification={
          allNotifications &&
          allNotifications.find((item: INotification) => item?.id === selected)
        }
        showModal={!!selected}
        dismiss={closeModal}
      />
    </>
  );
};

export default Notification;
