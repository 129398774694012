import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Section from "../layouts/Section";
import SEO from "../layouts/SEO";
import images from "../data/images";
import BentoGrid from "../components/Bento/BentoGrid";
import BentoCard from "../components/Bento/BentoCard";
import { IoAnalyticsSharp } from "react-icons/io5";
import { GoTools } from "react-icons/go";
import { HiOutlineLightBulb } from "react-icons/hi2";
import BorderBeam from "../components/BorderBeam";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <Section
      id="AboutUs"
      title="The Power of Email Data for Smarter Workflows"
      badge="About Us"
      className="lg:mt-[10rem] mt-[8rem]"
    >
      <SEO title={"About Us | InterSight"} />
      <section className="flex-wrap justify-center px-5 lg:px-20 mt-16 lg:mt-20 w-full space-y-16">
        <section className="lg:p-6 p-4">
          <h2 className="text-3xl font-semibold text-primaryBlue mb-6">
            About Our Company
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="flex flex-col gap-4">
              <p className="text-gray-600 text-lg mb-6">
                At Intersight, we believe that data holds the key to better
                communication and productivity. Our platform provides actionable
                insights into email activity, empowering teams to work smarter,
                not harder. Since our founding in 2023, we’ve helped
                organizations streamline their communication processes and make
                data-driven decisions.
              </p>
              <img
                src={images.company1}
                alt="Our Story"
                className="w-full aspect-video object-cover grayscale rounded-xl"
              />
              <Button
                onClick={() => navigate("/get-started/sign-up")}
                text="Get Started"
                className="!self-start"
              />
            </div>
            <BentoGrid className="auto-rows-auto">
              <BentoCard
                type="basic"
                index={1}
                className="col-span-1"
                // descriptionStyle="!max-w-full"
                name="Our Vision"
                description="Our vision is to become the global standard for email analytics, enabling organizations to unlock the full potential of their communication data and drive smarter, more effective workflows."
              />
              <BentoCard
                type="basic"
                index={1}
                className="col-span-1"
                // descriptionStyle="!max-w-full"
                name="Our Mission and Values"
                description="Our mission is to turn email data into meaningful insights that drive productivity and foster collaboration. We value transparency, innovation, and simplicity—offering tools that are easy to use and deliver measurable results."
              />
              <BentoCard
                type="basic"
                index={1}
                className="col-span-1"
                // descriptionStyle="!max-w-full"
                name="Our Team"
                description="Behind InterSight is a team of passionate accountants and technologists, formerly at EY, and Intel, dedicated to helping individuals and teams unlock their full potential. With years of experience in data analytics and communication tools, we bring expertise and enthusiasm to everything we do."
              />
            </BentoGrid>
          </div>
        </section>
        <section className="lg:p-6 p-4 grid md:grid-cols-2 gap-6">
          <div className="flex flex-col gap-4">
            <h2 className="text-3xl font-semibold text-primaryBlue mb-6">
              What Sets Us Apart
            </h2>
            <img
              src={images.usApart}
              alt="Our Story"
              className="sm:w-[50%] object-contain"
            />
          </div>
          <BentoGrid className="auto-rows-auto">
            <BentoCard
              type="basic"
              index={1}
              Icon={HiOutlineLightBulb}
              className="col-span-1"
              name="Innovation"
              description="Innovation is at the core of what we do. Our patented technologies reflect our commitment to pushing the boundaries of email analytics. These patents ensure that we deliver unique and cutting-edge solutions that set us apart."
            />
            <BentoCard
              type="basic"
              index={1}
              Icon={IoAnalyticsSharp}
              className="col-span-1"
              name="In-depth Email Analytics"
              description="From volume to frequency or subjects' distribution, we make sense of your email data."
            />
            <BentoCard
              type="basic"
              index={1}
              Icon={GoTools}
              className="col-span-1"
              name="Tailored Solutions"
              description="We develop tools to match your team's unique goals. If you wish, we can design specific tools to your needs."
            />
          </BentoGrid>
        </section>
        {/* What Sets Us Apart Section */}
        <div className="relative flex px-6 py-12 flex-col items-center justify-center overflow-hidden rounded-2xl border">
          <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-primaryBlue to-lightBlue/40 bg-clip-text text-center text-4xl lg:text-8xl font-semibold leading-none text-transparent">
            Code of Conduct
          </span>
          <BorderBeam
            size={250}
            duration={12}
            delay={9}
            colorFrom={"#67B8E3"}
            colorTo={"#669CF4"}
          />
          <p className="lg:max-w-[80%] text-center mt-8 text-lg">
            {
              "At InterSight, we are committed to fostering a culture of integrity, respect, and accountability. Our code of conduct ensures that we operate with the highest ethical standards, valuing the diversity, inclusivity, and privacy of our clients, employees, managers, and stakeholders. We are driven by a shared responsibility to deliver solutions that are not only effective but also ethical and fair."
            }
          </p>
        </div>
      </section>
    </Section>
  );
};

export default AboutUs;
