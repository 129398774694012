import React from "react";
import { Select, Input, Form } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import images from "../../../data/images";
import usePayment from "./hooks/usePayments";
import { Link, useNavigate } from "react-router-dom";
import countries from "../../../data/countries";
import AuthSelectors from "../../../modules/auth/selectors";
import Button from "../../../components/Button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";

const { Option } = Select;

const Payment = () => {
  const navigate = useNavigate();
  const {
    planState,
    paymentURL,
    // priceWithVat,
    loading,
    billingDetails,
    getIframe,
    fetchPlanPrice,
  } = usePayment();
  const { user } = AuthSelectors();
  const { firstName, lastName, email, organization } = user;
  // const { plan, paymentType } = planState;
  const { plan } = planState;
  const [form] = Form.useForm();
  const {
    vat,
    currency,
    price: planPrice,
    originalPrice,
  } = billingDetails || {
    vat: 0,
    currency: "",
    price: 0,
    originalPrice: 0,
  };

  // const price =
  //   paymentType === SubscriptionType.ANNUAL
  //     ? plan.priceYearly * 12
  //     : plan.pricingMonthly;

  // const period =
  //   paymentType === SubscriptionType.ANNUAL ? "per year" : "per month";

  const handleProceedToPay = (values: any) => {
    // Mock API call to fetch iframe URL
    getIframe(values);
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      <div
        className="flex items-center sticky top-0 bg-white z-10 h-8 w-full gap-2 cursor-pointer transition-all duration-300 ease-in-out group"
        onClick={() => navigate(-1)}
      >
        <FaArrowLeftLong className="text-primaryBlue group-hover:mr-1 group-hover:text-black transition-all duration-300 ease-in-out" />
        <span className="hidden group-hover:opacity-1 group-hover:block transition-opacity duration-300 ease-in-out">
          back
        </span>
        <img
          src={images.logoPrimary}
          alt="InterSight logo"
          className=" object-contain w-24 py-1 group-hover:hidden transition-all duration-300 ease-in-out"
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8  ">
        <div className="border p-4 rounded-md bg-white col-span-1">
          <h1 className="font-bold text-lg text-placeholder">
            Subscribe to InterSight {plan?.title}
          </h1>
          <Accordion
            type="multiple"
            // collapsible
            defaultValue={["step-1", "step-2"]}
            // value={["step-1", "step-2"]}
            // onValueChange={setActiveStep}
            className="w-full"
          >
            {/* <AccordionItem value="step-1">
              <AccordionTrigger>Plan Details</AccordionTrigger>
              <AccordionContent>
                <div className="px-2">
                  <div className="mb-1 flex items-end">
                    <span className="text-xl font-medium text-slate-900">
                      {currency}
                    </span>
                    <span className="text-4xl font-bold text-slate-900">
                      {planPrice}
                    </span>
                    <div className="w-5 ml-2 text-xs">{period}</div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem> */}
            <AccordionItem value="step-2">
              <AccordionTrigger>Billing Information</AccordionTrigger>
              <AccordionContent>
                <p>Billing Information, which will appear on your invoices.</p>
                <div>
                  <Form
                    form={form}
                    requiredMark={false}
                    autoComplete="off"
                    layout="vertical"
                    className="flex flex-col gap-2"
                    onFinish={handleProceedToPay}
                  >
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          First Name
                        </label>
                        <Input
                          type="text"
                          value={firstName}
                          disabled
                          className="py-2 border-0 rounded-lg"
                          placeholder="First Name"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Last Name
                        </label>
                        <Input
                          type="text"
                          value={lastName}
                          disabled
                          className="py-2 border-0 rounded-lg"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <Form.Item
                      required
                      name="email"
                      label={
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Email
                        </label>
                      }
                      initialValue={email}
                      className="m-0"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: `Please enter your email address!`,
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        value={email}
                        disabled
                        className="w-full py-2 border-0 rounded-lg"
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      name="country"
                      label={
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Country
                        </label>
                      }
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: `Please select country!`,
                        },
                      ]}
                    >
                      <Select
                        className="w-full"
                        size={"large"}
                        placeholder="Select Country"
                        showSearch
                        onChange={(value) => fetchPlanPrice(value)}
                      >
                        {countries.map((country) => (
                          <Option key={country.name} value={country.name}>
                            <div className="flex gap-2">
                              <img
                                src={country.flags.png}
                                className="w-5 object-contain"
                                alt={country.name}
                              />
                              <span>{country.name}</span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      required
                      className="m-0"
                      name="address"
                      label={
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Address
                        </label>
                      }
                      rules={[
                        {
                          required: true,
                          message: `address is required!`,
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        className="w-full py-2 rounded-lg"
                        placeholder="Address"
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      className="m-0"
                      name="organization"
                      label={
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Organization
                        </label>
                      }
                      initialValue={organization}
                      rules={[
                        {
                          required: true,
                          message: `organization is required!`,
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        className="w-full py-2 rounded-lg"
                        placeholder="Organization"
                        value={organization}
                      />
                    </Form.Item>
                    {/* <Form.Item valuePropName="checked" name="saveData">
                      <Checkbox>Save Data</Checkbox>
                    </Form.Item> */}
                    <Button
                      variant={!!paymentURL ? "disabled" : "primary"}
                      type="submit"
                      text={"Proceed to Pay"}
                      loading={loading}
                      className="justify-center items-center w-full mt-6 !px-12 !py-2 text-white bg-primaryBlue !max-md:px-5"
                    />
                  </Form>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <p className="text-sm text-gray-400 ">
            Cancel anytime in account settings at least one day before each
            renewal date. Plan automatically renews until cancelled. Your
            billing date may not line up with your apprenticeship start date. By
            clicking on Confirm & Subscribe you agree to our
            <Link to="/terms-of-service" className="font-bold mx-1">
              Terms of Service
            </Link>
            and authorize this recurring charge.
          </p>
        </div>

        {/* Section 2: Illustration or Billing Iframe */}
        <div className="lg:p-4 rounded-md flex justify-center col-span-1">
          {paymentURL ? (
            <div className="flex flex-col w-full">
              <Accordion
                type="multiple"
                defaultValue={["step-1"]}
                className="w-full"
              >
                <AccordionItem value="step-1">
                  <AccordionTrigger>Billing Details</AccordionTrigger>
                  <AccordionContent>
                    {/* <div className="px-2">
                      <div className="mb-1 flex items-end">
                        <span className="text-xl font-medium text-slate-900">
                          {plan.currency || "₪"}
                        </span>
                        <span className="text-4xl font-bold text-slate-900">
                          {price}
                        </span>
                        <div className="w-5 ml-2 text-xs">{period}</div>
                      </div>
                    </div> */}
                    <div className="px-2">
                      <div className=" text-slate-500">
                        <div className="flex w-full items-end justify-between">
                          <span className="font-bold">{plan?.title} Plan</span>
                          <span>
                            {currency}
                            {originalPrice}
                          </span>
                        </div>
                      </div>
                      {vat ? (
                        <div className="text-slate-500">
                          <div className="flex w-full items-end justify-between">
                            <span>Vat</span>
                            <span>
                              {currency}
                              {vat}
                            </span>
                          </div>
                        </div>
                      ) : null}

                      <div className="flex items-end justify-between font-bold text-slate-500 pb-4 mt-2 border-t border-gray-400">
                        <span>Total</span>
                        <span className="text-lg">
                          {currency}
                          {planPrice}
                        </span>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <iframe
                src={paymentURL}
                title="Billing Iframe"
                className="h-[80dvh]"
              />
            </div>
          ) : (
            <div className="text-center">
              <img
                src={images.creditCard}
                alt="Illustration"
                className="mx-auto mb-4"
              />
              <p className="text-gray-600">
                Fill out the form to proceed with payment.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
