import { ISubscription } from "../../../utils/types";

type ActionType =
  | "CAN_SEARCH_REPORT"
  | "FREE_TRIAL"
  | "CAN_INVITE_INSIDERS"
  | "REFER_FRIEND"
  | "EXPORT_CSV";

type PermissionResult = {
  isAllowed: boolean;
  message: string;
  cb: boolean;
};

export const checkPermission = (
  permissions: ISubscription,
  action: ActionType
): PermissionResult => {
  const {
    subscription: {
      isCancelled = false,
      canUpgrade = false,
      isFreeTrial = true,
    } = {},
    searches: {
      searchesLeft = null,
      maximumSearches = null,
      searchesMade = null,
    } = {},
    users: { maximumUsers = null, usersCreated = null, usersLeft = null } = {},
    reports: { canExportToCSV = false } = {},
    role,
  } = permissions || {};

  let isAllowed = false;
  let cb = false;
  let message = "You do not have permission to perform this action.";

  switch (action) {
    case "CAN_SEARCH_REPORT":
      // Allowed if searchesLeft is null, undefined, or greater than 0
      isAllowed = searchesLeft == null || searchesLeft > 0;
      cb = true;
      break;

    case "FREE_TRIAL":
      // Allowed if isFreeTrial is true
      isAllowed = isFreeTrial;
      message = isAllowed ? "" : "Free trial actions are not allowed.";
      break;

    case "CAN_INVITE_INSIDERS":
      // Allowed if usersLeft is null, undefined, or greater than 0 and not in a free trial
      isAllowed = usersLeft == null || usersLeft > 0;
      cb = true;
      break;

    case "EXPORT_CSV":
      // Allowed if canExportToCSV is true
      isAllowed = canExportToCSV;
      cb = true;
      break;

    default:
      message = "Action not recognized.";
  }

  return { isAllowed, message, cb };
};
