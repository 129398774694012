import React, { useEffect, useState } from "react";
import { IProfileType, ProfileType } from "../../../../utils/types";
import { Select, SelectProps } from "antd";
import useActions from "../hooks/useActions";

const typeOptions: SelectProps["options"] = [
  {
    label: "None",
    value: ProfileType.NONE,
  },
  {
    label: "Customer",
    value: ProfileType.CUSTOMER,
  },
  {
    label: "Potential Customer",
    value: ProfileType.POTENTIAL_CUSTOMER,
  },
  {
    label: "Provider",
    value: ProfileType.PROVIDER,
  },
];
const TypeInput = ({
  domainId,
  type,
}: {
  domainId: string;
  type: IProfileType;
}) => {
  const defaultType = type ? { label: type.name, value: type.id } : undefined;
  const [selectedType, setSelectedType] = useState(defaultType);
  const { Edit } = useActions();
  const handleEdit = (option: { label: string; value: ProfileType }) => {
    setSelectedType(option);
    Edit({
      id: domainId,
      type: option.value,
      UpdateAllDomains: false,
    });
  };
  useEffect(() => {
    const defaultType = type ? { label: type.name, value: type.id } : undefined;
    setSelectedType(defaultType);
  }, [type]);

  return (
    <div>
      <Select
        // showSearch
        style={{ width: "100%" }}
        placeholder={"Select type"}
        value={selectedType}
        onChange={(value) => handleEdit(value)}
        options={typeOptions}
        className={"h-[2.5rem]"}
        size={"large"}
        allowClear={false}
      />
    </div>
  );
};

export default TypeInput;
