
import React from "react";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  IVolume,
} from "../../../../utils/types";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import { Bar, CartesianGrid, ComposedChart, Line, XAxis } from "recharts";
import Wrapper from "./Wrapper";
import { useMediaQuery } from "react-responsive";
import convertMinutesToHoursMinutes from "../../../../utils/hour-formatter";

const chartConfig = {
  currentPeriod: {
    label: "Current Period",
  },
  lastPeriod: {
    label: "Last Period",
  },
  yearlyAvg: {
    label: "Yearly Avg",
  },
} satisfies ChartConfig;

const Timing = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<IVolume>;
}) => {
  const isMobile = useMediaQuery({ maxWidth: "767px" });
  const lsLastPeriod = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading, needSubscription } = details;
  return (
    <Wrapper
      className={className}
      cardFilters={filters}
      loading={loading}
      error={error}
      needSubscription={needSubscription}
      content={
        <ChartContainer
          config={chartConfig}
          // className="max-md:h-[200px] max-md:w-[270px]"
          className="h-[400px] max-md:aspect-square  lg:w-[100%] max-md:size-full lg:flex-1"
        >
          <ComposedChart
            accessibilityLayer
            data={data}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              tickMargin={8}
              axisLine={false}
              // tickFormatter={(value) => value.slice(0, 3)}
            />
            {/* <YAxis tickLine={false} tickMargin={8} axisLine={false} /> */}
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  indicator="line"
                  // formatter={(value) => convertMinutesToHoursMinutes(value.toString())}
                  formatter={(value, name, item, index) => {
                    return (
                      <div className="flex gap-2 items-center" key={index}>
                        <div
                          className="h-full w-1 shrink-0 rounded-[2px] bg-[--color-bg]"
                          style={
                            {
                              "--color-bg": item.color,
                            } as React.CSSProperties
                          }
                        />
                        <span className="text-xs text-muted-foreground">
                          {chartConfig[name as keyof typeof chartConfig]
                            ?.label || name}
                        </span>
                        <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                          {convertMinutesToHoursMinutes(value.toString())}
                        </div>
                      </div>
                    );
                  }}
                />
              }
            />
            <Bar
              dataKey="currentPeriod"
              fill={`var(--chart-current-period-${lsLastPeriod})`}
              radius={4}
            />
            {/* {isMobile ? null : (
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                  // content={RenderLabelContent}
                />
              )}
            </Bar> */}

            <Bar
              dataKey="lastPeriod"
              fill={`var(--chart-last-period-${lsLastPeriod})`}
              radius={4}
            />
            {/* {isMobile ? null : (
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                  // content={RenderLabelContent}
                />
              )}
            </Bar> */}
            {/* <Bar
              dataKey="yearlyAvg"
              fill={`var(--chart-yearly-avg-${lsLastPeriod})`}
              radius={4}
            >
              {isMobile ? null : (
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                  content={RenderLabelContent}
                />
              )}
            </Bar> */}
            <Line
              type="monotone"
              dataKey="yearlyAvg"
              stroke={`var(--chart-yearly-avg-${lsLastPeriod})`}
              strokeWidth={1.5}
              // hide
              dot={false}
            />
            {/* <ReferenceLine
              y={166}
              strokeWidth={1.5}
              stroke={`var(--chart-yearly-avg-${lsLastPeriod})`}
            /> */}
            <ChartLegend content={<ChartLegendContent />} />
          </ComposedChart>
        </ChartContainer>
      }
    />
  );
};

export default Timing;
