import React, { useEffect } from "react";
import Section from "../../layouts/Section";
import { IoMdCheckmark } from "react-icons/io";
import { gsap } from "gsap";
import CommonSelectors from "../../modules/common/selectors";
import { Pricing } from "../../utils/types";
import { useNavigateToTop } from "../../hooks/useNavigateToTop";
import BorderBeam from "../../components/BorderBeam";
import AuthSelectors from "../../modules/auth/selectors";

const PricingTier: React.FC<Pricing> = ({
  id,
  title,
  pricingMonthly,
  features,
  isPopular = false,
  border,
  currency,
}) => {
  const navigateToTop = useNavigateToTop();
  const { isLoggedIn, token } = AuthSelectors();

  const handleSubscription = () => {
    // if (isForEnterprise) {
    //   navigateToTop("/get-started/request-demo");
    // } else {
    // }
    if (isLoggedIn && token) {
      navigateToTop(`/Dashboard/plans`);
    } else {
      navigateToTop(`/get-started/sign-up/${id}`);
    }
  };
  const bgColor = "bg-white";
  // const bgColor = isPopular ? "bg-black" : "bg-white";
  const textColor = "text-black";
  // const textColor = isPopular ? "text-white" : "text-black";
  const borderColor = "border-gray-150";
  // const borderColor = isPopular
  //   ? "border-white border-opacity-20"
  //   : "border-blue-100";
  return (
    <section
      className={`relative plan${id} flex flex-col px-10 py-11 w-full ${bgColor} rounded-3xl border border-solid ${borderColor} shadow-sm max-md:px-5`}
    >
      <header className="flex gap-5 justify-between px-px">
        <h2
          className={`text-lg font-bold tracking-tight text-slate-500`}
          // ${isPopular ? "text-white text-opacity-60" : "text-slate-500"}
        >
          {title}
        </h2>
        {isPopular ? (
          <div className="flex flex-col justify-center px-3.5 py-2.5 text-sm font-medium rounded-xl border border-solid border-gray-150">
            <span className="bg-clip-text">Most Popular</span>
          </div>
        ) : (
          <div className="px-3.5 py-5" />
        )}
      </header>
      <div className="flex gap-1 mt-9 font-bold whitespace-nowrap">
        <span
          className={`text-6xl tracking-tighter ${textColor} leading-[59.94px] max-md:text-4xl`}
        >
          {`${currency || "₪"}${pricingMonthly || 0}`}
        </span>
        <span
          className={`px-px lg:pt-6 pb-px my-auto text-lg tracking-tight text-neutral-400 `}
        >
          {"User/Monthly"}
          {/* {period} */}
        </span>
      </div>
      <button
        onClick={handleSubscription}
        className={` group justify-center items-center px-4 py-2.5 mt-9 text-base font-medium tracking-tight rounded-xl max-md:px-5 text-white bg-primaryBlue`}
        // ${isPopular ? "text-black bg-white" : "text-white bg-black"}
      >
        <div className="transition-all duration-300 ease-out group-hover:scale-90">
          {"Start a Free Trial"}
        </div>
        {/* {isForEnterprise ? "Contact Sales" : "Sign Up for Free"} */}
        {/* {buttonText} */}
      </button>
      <ul className="mt-9">
        {features.map((feature, index) => (
          <li
            key={index}
            className={`flex items-center gap-4 mt-5 first:mt-0 text-sm ${textColor}`}
          >
            <IoMdCheckmark color={textColor} />
            <span className="my-auto">{feature}</span>
          </li>
        ))}
      </ul>
      {border ? (
        <BorderBeam
          size={250}
          duration={8}
          delay={9}
          colorFrom={"#adffd8"}
          colorTo={border}
          className="rounded-3xl"
        />
      ) : null}
    </section>
  );
};

const Plans: React.FC = () => {
  const { pricingTiers } = CommonSelectors();
  useEffect(() => {
    pricingTiers.map((pricing: Pricing, index: number) =>
      gsap.fromTo(
        `.plan${pricing.id}`,
        { y: "70%" },
        {
          y: 0,
          duration: 1.2,
          delay: Number(`0.${index}`),
        }
      )
    );
  }, []);
  return (
    <Section
      badge="Subscribe now"
      title="Work smarter with the right plan for you"
      className="box !p-0"
    >
      <section className="flex flex-col justify-center px-5 lg:px-20 mt-16 lg:mt-24 w-full">
        <div className="flex gap-5 justify-center max-md:flex-col max-md:gap-0 w-full ">
          {pricingTiers?.map((tier: Pricing, index: number) => (
            <div
              key={index}
              className={`flex flex-col w-3/12 max-md:w-full max-md:mt-10`}
            >
              <PricingTier {...tier} />
            </div>
          ))}
        </div>
      </section>
    </Section>
  );
};

export default Plans;
