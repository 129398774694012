import React from "react";
import { cn } from "../utils/utils";
import { Link } from "react-router-dom";

const ComponentWrapper = ({
  children,
  link,
  ...rest
}: {
  children: React.ReactNode;
  link?: string;
  className?: string;
}) =>
  link ? (
    <Link to={link} className={rest.className}>
      {children}
    </Link>
  ) : (
    <div {...rest}>{children}</div>
  );
const Feature = ({
  title,
  description,
  icon,
  index,
  link,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  index: number;
  link?: string;
}) => {
  return (
    <ComponentWrapper
      link={link}
      className={cn(
        "flex flex-col col-span-4 lg:col-span-1 lg:border-r py-10 relative group/feature",
        (index === 0 || index === 4) && "lg:border-l",
        index < 4 && "lg:border-b"
      )}
    >
      {index < 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-t from-neutral-100 to-transparent pointer-events-none" />
      )}
      {index >= 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-b from-neutral-100 to-transparent pointer-events-none" />
      )}
      <div className="mb-4 relative z-10 px-10 text-neutral-600">{icon}</div>
      <div className="text-lg font-bold mb-2 relative z-10 px-10">
        <div className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-8 w-1 rounded-tr-full rounded-br-full bg-lightBlue group-hover/feature:bg-primaryBlue transition-all duration-200 origin-center" />
        <span className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-neutral-800">
          {title}
        </span>
      </div>
      <p className="text-sm text-neutral-600 max-w-xs relative z-10 px-10">
        {description}
      </p>
    </ComponentWrapper>
  );
};
export default Feature;
