import { ISubscription, RoleType } from "../../../utils/types";

export const checkVisibleElements = (
  permissions: ISubscription,
  action: (hiddenElements: Record<string, boolean>) => void
) => {
  const {
    subscription: {
      isCancelled = false,
      canUpgrade = false,
      isFreeTrial = true,
    } = {},
    searches: {
      searchesLeft = null,
      maximumSearches = null,
      searchesMade = null,
    } = {},
    users: { maximumUsers = null, usersCreated = null, usersLeft = null } = {},
    reports: { canExportToCSV = false } = {},
    role,
  } = permissions || {};

  // Default hidden elements configuration
  const baseHiddenElements: Record<string, boolean> = {
    billings: true,
    plans: true,
    insidersTab: true,
    outsidersTab: true,
    insidersTable: false,
    outsidersTable: false,
    usersFilter: true,
    exportToCSV: canExportToCSV,
    teammateSearch: true,
    addressInfo: false,
    subscriptionPlan: true,
  };

  let hiddenElements = { ...baseHiddenElements };

  // Update based on role and subscription status
  switch (role) {
    case RoleType.ADMIN:
      if (!isFreeTrial) {
        hiddenElements = {
          ...baseHiddenElements,
          insidersTable: true,
          outsidersTable: true,
          addressInfo: true,
          subscriptionPlan: false,
        };
      }
      break;

    case RoleType.MANAGER:
      hiddenElements = {
        ...baseHiddenElements,
        billings: false,
        plans: false,
        insidersTable: true,
        outsidersTable: true,
        subscriptionPlan: false,
      };
      break;

    case RoleType.MEMBER:
      hiddenElements = {
        ...baseHiddenElements,
        billings: false,
        plans: false,
        insidersTab: false,
        outsidersTab: false,
        insidersTable: false,
        outsidersTable: false,
        usersFilter: false,
        teammateSearch: false,
        subscriptionPlan: false,
      };
      break;

    default:
      if (isFreeTrial) {
        hiddenElements = {
          ...baseHiddenElements,
          insidersTable: false,
          outsidersTable: false,
          usersFilter: false,
          teammateSearch: false,
        };
      }
      break;
  }

  // Trigger the action with the updated hidden elements
  action(hiddenElements);
};
