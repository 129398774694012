import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import Plans from "../Home/Plans";
import FAQ from "../Home/FAQ";
import { FAQS } from "../../utils/types";
import TestimonialsCarousel from "../Home/TestimonialsCarousel";

const faqData: FAQS[] = [
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, Inter-Sight offers a free trial period for new users. This allows potential customers to explore the platform and its features before committing to a paid plan. Details on how to sign up for the free trial can be found on their website.",
    active: true,
  },
  {
    question: "What is your cancellation policy?",
    answer:
      "As long as the cancellation notice is provided to the company no later than 23:59 on the day the purchase transaction was made - the user will be credited with the full cost of the purchase. For more information look for terms and conditions policy.",
    active: false,
  },
  {
    question: "Can I add or remove users?",
    answer:
      "Our pricing plans are flexible, allowing you to easily add or remove users based on your needs. If your team grows, simply upgrade to a higher tier or add more seats to accommodate additional users. If your team size decreases, you can easily adjust your plan to reflect the smaller number of users, ensuring you're only paying for what you need. Once you upgrade a package, all users will be charged immediately for the upgraded package.",
    active: false,
  },
  {
    question: "How secure is my payment information?",
    answer:
      "We take the security of your payment information seriously. All transactions are processed through secure, encrypted payment gateways, PCI DSS standards power by `Hyp payments solution`, ensuring your data is safe. Your payment details are never stored on our servers, and we adhere to the highest standards of data protection. You can confidently update your payment methods or billing information at any time, knowing that your privacy and security are our top priorities.'",
    active: false,
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "Inter-Sight accepts various payment methods including major credit cards such as VISA, MASTERCARD, ISRACARD, AMERICAN EXPRESS, GOOGLE PAY, APPLE PAY, and more. Specific details about accepted payment methods and billing cycles can be reviewed during the subscription process or by contacting their support team.",
    active: false,
  },
];
const Pricing = () => {
  return (
    <Section id="Pricing">
      <SEO title={"Pricing | InterSight"} />
      <Plans />
      {/* <ComparePlans /> */}
      {/* <EnterpriseQuote /> */}
      {/* <Testimonials /> */}
      <TestimonialsCarousel />
      <FAQ faqs={faqData} />
    </Section>
  );
};

export default Pricing;
