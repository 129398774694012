import React, { useRef } from "react";
import Section from "../../layouts/Section";
import SectionBadge from "../../components/SectionBadge";
import { BlogProps, posts } from "./data";
import ArticleCard from "./components/ArticleCard";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

const Blogs = () => {
  const swiperRef = useRef<any>(null);
  const isMobile = useMediaQuery({ maxWidth: "767px" });

  return (
    <Section className="!pb-0">
      <div className="mx-auto max-w-7xl">
        <SectionBadge text={"KNOWLEDGE TREE"} className="!px-8" />
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Latest Articles
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Discover fresh perspectives and actionable insights to power your
            workflows.
          </p>
        </div>
        <Swiper
          slidesPerView={isMobile ? "auto" : 3}
          spaceBetween={30}
          loop
          onSwiper={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation]}
          className="mySwiper mt-8 gap-8"
        >
          {posts.map((post: BlogProps, index: number) => (
            <SwiperSlide className="col-span-1" key={index}>
              <ArticleCard post={post} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex gap-2 mt-4 text-primaryBlue">
          <div
            className="border rounded-lg p-2 cursor-pointer"
            onClick={() => swiperRef?.current?.slidePrev()}
          >
            <MdArrowBackIosNew />
          </div>
          <div
            className="border rounded-lg p-2 cursor-pointer"
            onClick={() => swiperRef?.current?.slideNext()}
          >
            <MdArrowForwardIos />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Blogs;
