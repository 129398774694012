import { formatDistanceToNow } from "date-fns";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { cn } from "../../../../utils/utils";
import { DropdownMenuItem } from "../../../../components/ui/dropdown-menu";
import { INotification } from "../../../../utils/types";
interface NotificationListProps {
  notification: {
    selected: INotification["id"] | null;
    allNotifications: INotification[];
    unreadCount: number;
  };
  openNotification: Function;
}

const NotificationList = ({
  openNotification,
  notification,
}: NotificationListProps) => {
  const { selected, allNotifications } = notification;
  return (
    <ScrollArea className="h-[50dvh]">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {allNotifications.map((item) => (
          <DropdownMenuItem
            key={item.id}
            className={cn(
              "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
              selected === item.id && "bg-muted"
            )}
            onSelect={() => openNotification(item.id)}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{item.name}</div>
                  {!item.read && (
                    <span className="flex h-2 w-2 rounded-full bg-primaryBlue" />
                  )}
                </div>
                <div
                  className={cn(
                    "ml-auto text-xs",
                    selected === item.id
                      ? "text-foreground"
                      : "text-muted-foreground"
                  )}
                >
                  {item.createdAt ? formatDistanceToNow(item.createdAt) : ""}
                </div>
              </div>
              <div className="text-xs font-medium">{item.subject}</div>
            </div>
            <div className="line-clamp-2 text-xs text-muted-foreground">
              {item?.text?.substring(0, 300)}
            </div>
            {/* {item.labels.length ? (
              <div className="flex items-center gap-2">
                {item.labels.map((label: string) => (
                  <Badge key={label} variant={getBadgeVariantFromLabel(label)}>
                    {label}
                  </Badge>
                ))}
              </div>
            ) : null} */}
          </DropdownMenuItem>
        ))}
      </div>
    </ScrollArea>
  );
};

export default NotificationList;
// function getBadgeVariantFromLabel(
//   label: string
// ): ComponentProps<typeof Badge>["variant"] {
//   if (["work"].includes(label.toLowerCase())) {
//     return "default";
//   }

//   if (["personal"].includes(label.toLowerCase())) {
//     return "outline";
//   }

//   return "secondary";
// }
