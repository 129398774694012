import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { Link } from "react-router-dom";
import socials from "../../data/configs";

const PrivacyHE = () => {
  return (
    <Section id="Policy" badge={"Privacy Policy"} title="מדיניות פרטיות">
      <SEO title={"Policy | InterSight"} />
      <div className="items-center justify-center flex flex-col">
        <div className="my-16 w-[90%] lg:w-[50%]">
          <p className="font-bold text-primaryBlue text-center">
            תאריך עדכון אחרון: {socials.updateAt}
          </p>
          <div className="items-center justify-center mt-8">
            <div className="mb-10">
              <div className="my-8 italic pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                <span className="block">
                  הבעלים – הינתרסאיט בע"מ מספר מזהה
                  <strong>{" 517017349 "}</strong>
                </span>
                <span className="block">
                  טלפון –
                  <Link
                    to={`tel:${socials.phone}`}
                    className="mx-2 text-primaryBlue"
                    dir="ltr"
                  >
                    {socials.phone}
                  </Link>
                </span>
                <span className="block">כתובת: {socials.addressHE}</span>
              </div>
            </div>
            <div className="mb-10">
              <ul className="start-custom space-y-4 list-inside list-decimal">
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    {"אחריות המשתמש"}
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      בעל המערכת
                      <Link
                        to="https://www.inter-sight.com"
                        target="_blank"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        www.inter-sight.com
                      </Link>
                      (להלן <strong>"המערכת"</strong>) מכבד את פרטיות המשתמשים
                      במערכת שהוא מנהל ומפעיל. על כן החליט בעל המערכת לפרסם את
                      מדיניותו ביחס להגנת הפרטיות, והוא מתחייב כלפי המשתמש לקיים
                      מדיניות זו.
                    </li>
                    <li>
                      מטרת מסמך המדיניות היא להסביר מהם נוהגי בעל המערכת ביחס
                      לפרטיות המשתמשים ובין היתר: איזה מידע נאסף על אודות
                      המשתמשים וכיצד משתמש בעל המערכת במידע, שנמסר לו על-ידי
                      המשתמשים במערכת או נאסף בעת השימוש בו.
                    </li>
                    <li>
                      מדיניות הפרטיות הינה בהתאם לאמור בסעיף 11 לחוק הגנת
                      הפרטיות התשמ"א 1981.
                    </li>
                    <li>
                      מדיניות הפרטיות מנוסחת בלשון זכר ומכוונת לשני המינים.
                    </li>
                    <li>
                      <span>אנא קרא והבן את הוראות מדיניות הפרטיות.</span>
                      <span className="block">
                        אם בחרת להמשיך ולהשתמש בשירותי המערכת, יש לראות זאת
                        כהסכמה למלוא הוראות מדיניות הפרטיות ולקבלתן.
                      </span>
                      <span className="block">
                        אם אינך מעוניין כי ייאסף עליך מידע כאמור במדיניות
                        הפרטיות, אנא הימנע משימוש במערכת.
                      </span>
                    </li>
                    <li>
                      יובהר כי לא חלה על המשתמש כל חובה שבדין למסור את המידע.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    {"סוג המידע הנאסף"}
                  </h2>
                  <ul className="nested-list list-decimal list-inside pr-4 mt-2">
                    <li>
                      <strong>
                        בעת השימוש בשירותי המערכת נאסף מידע על אודותיך, חלקו
                        מזהה אותך אישית:
                      </strong>
                      <ul className="nested-list list-disc list-inside pr-4">
                        <li>
                          פרטי יצירת קשר, כגון שמך, כתובת דואר אלקטרוני, מספר
                          טלפון;
                        </li>
                        <li>
                          מידע שהעברת במסגרת התכתבויות ו/או תקשורת עם המערכת,
                          לרבות מידע שהעברת על ההצעות והשירותים שעניינו אותך;
                        </li>
                        <li>תפקיד בארגון</li>
                        <li>שם מקום העבודה</li>
                      </ul>
                      <strong className="mt-2">
                        חלק מהמידע שנאסף אינו מזהה אישית, למשל:
                      </strong>
                      <ul className="nested-list list-disc list-inside pr-4">
                        <li>סוג הדפדפן שממנו אתה צופה במערכת,</li>
                        <li>סוג המכשיר שממנו אתה צופה במערכת,</li>
                        <li>אופן השימוש במערכת</li>
                        <li>מוצרים שהתעניינת בהם</li>
                      </ul>
                    </li>
                    <li>
                      בעת השימוש במערכת, הנך מאפשר למערכת גישה בסיסית לנתוני
                      אמייל מטא - דטה (From, To , CC ,BCC, Subject, Timestamp)
                      בגוגל או מיקרוסופט. המשתמש מצהיר כי יש לו אישור לפי כל
                      דין, לשתף את המידע האמור עם בעל המערכת. ככל שיש הגבלה ו/או
                      מניעה לעשות כן, באחריות המשתמש לוודא כי לא ניתנת למערכת
                      גישה ו/או הרשאות לכל מידע סודי ו/או פרטי ו/או מידע אחר
                      שאין לגלותו לפי כל דין.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    רישום לשירותים במערכת
                  </h2>
                  <p>
                    ככל שיידרש מידע אישי בעת הרשמה לשירותים במערכת או בעת רכישת
                    מוצרים, בעל המערכת יבקש ממך מידע הנחוץ למתן שירותי המערכת.
                  </p>
                </li>
                <li className="[&>p]:!mb-1">
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    איסוף המידע
                  </h2>
                  <p>
                    הנתונים שיאספו וכן תכנים שהמשתמש מעלה ו/או משתף ו/או יוצר
                    במערכת, יישמרו ברשות בעל המערכת. מסדי הנתונים מאוחסנים בשרתי
                    אחסון. המשתמש במערכת מצהיר כי הוא מסכים לשמירת מידע בשרתים
                    אלו, אשר עשויים להיות בארץ או בחו"ל.
                  </p>
                  <p>
                    המידע יישמר לתקופה של עד 24 חודשים ממועד האיסוף, ו/או יבוטל
                    מיידית עם ביטול המנוי של המשתמש, לפי המוקדם ביניהם.
                  </p>
                  <p>
                    יחד עם זאת, בעל המערכת רשאי לשנות מועדים אלו, ובלבד שהודיע
                    על כך למשתמש מראש.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    מטרות איסוף ושימוש במידע:
                  </h2>
                  <ul className="nested-list list-disc list-inside pr-4">
                    <strong>
                      השימוש במידע שנאסף, ייעשה רק בהתאם למדיניות פרטיות זו, או
                      בהתאם להוראות כל דין החל, על מנת:
                    </strong>
                    <li>לאפשר שימוש בשירותים שונים במערכת</li>
                    <li>לשפר את השירותים והתכנים המוצעים במערכת</li>
                    <li>לתקשר איתך ולהשיב לפניותיך.</li>
                    <li>לשנות או לבטל שירותים ותכנים קיימים</li>
                    <li>
                      לצורך אספקת מוצרים ושירותים - לרבות פרסום מידע ותכנים
                    </li>
                    <li>
                      על מנת להתאים את המודעות שיוצגו בעת ביקורך במערכת לתחומי
                      העניין שלך
                    </li>
                    <li>
                      לצורך אכיפת כללים ונהלים במערכת כפי שהם מופיעים בתנאי
                      השימוש
                    </li>
                    <li>למטרות בקרה</li>
                    <li>למטרות יצירת קשר מסחרי ו/או שיווקי, בכל מדיה.</li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    הבסיס החוקי לאיסוף המידע
                  </h2>
                  <ul className="nested-list list-disc list-inside pr-4">
                    <strong>
                      אנו נעבד את המידע האישי שלך, לרבות מידע רגיש, רק כאשר יש
                      לנו בסיס חוקי לעשות זאת. הבסיסים המשפטיים לעיבוד כוללים:
                    </strong>
                    <li>קיבלנו את הסכמתך המפורשת לעיבוד.</li>
                    <li>עיבוד המידע מותר לפי דין.</li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    דיוור ישיר אלקטרוני ויצירת קשר עם המשתמש
                  </h2>
                  <div className="[&>p]:!mb-1">
                    <p>
                      בעל המערכת מעוניין לעדכן את המשתמשים לגבי עדכונים חשובים,
                      התפתחויות ועניינים רלוונטיים אחרים הקשורים למערכת
                      ולשירותיו. על מנת להבטיח תקשורת רציפה ומעודכנת, בעל המערכת
                      רשאי לשלוח הודעות (נוטיפיקציות) למשתמשים.
                    </p>
                    <p>
                      כמו כן, בעל המערכת מעוניין לשלוח אליך, מעת לעת, ניוזלטרים
                      או הודעות אחרות במייל, ו/או במסרון SMS או בוואטסאפ, על
                      אודות השירותים והמוצרים וכן מידע שיווקי ופרסומי.
                    </p>
                    <p>
                      מידע כזה יישלח אליך רק אם נתת הסכמתך המפורשת, ובכל עת תוכל
                      לבטל את הסכמתך ולהפסיק לקבלו.
                    </p>
                    <p>
                      לא נמסור את המידע האישי שלך למפרסמים. עם זאת, אנו עשויים
                      להעביר מידע סטטיסטי על פעילות המשתמשים במערכת ובניוזלטר
                      לצדדים שלישיים.
                    </p>
                  </div>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    מסירת מידע לצד שלישי
                  </h2>
                  <ul className="nested-list list-disc list-inside pr-4">
                    <strong>
                      בעל המערכת לא יעביר לצדדים שלישיים את פרטיך האישיים והמידע
                      שנאסף על פעילותך במערכת אלא במקרים המפורטים להלן:
                    </strong>
                    <li>
                      שם וכתובת המייל מועברים לספק מערכת הדיוור של המערכת;
                    </li>
                    <li>חלק מהמידע מועבר לספק אשר מספק שירותי אחסון למערכת;</li>
                    <li>
                      מידע אודות השימוש והפעילות שלך במערכת, יעבור לפלטפורמות
                      שיווק כגון META ו-GOOGLE , ,YOU TUBE LINKEDIN ו/או
                      פלטפורמות אחרות באמצעות קבצי COOCKIES ו/או פיקסלים ;
                    </li>
                    <li>
                      במקרה של מחלוקת משפטית בינך לבין בית העסק שתחייב את חשיפת
                      פרטיך;
                    </li>
                    <li>אם תבצע במערכת פעולות המנוגדות לחוק;</li>
                    <li>
                      אם יתקבל צו בית משפט המורה למסור את פרטיך או מידע על
                      אודותיך לצד שלישי;
                    </li>
                    <li>
                      בעל המערכת רשאי להעביר את המידע לאנשי מקצוע ו/או פלטפורמות
                      ו/או שירותים אחרים בתחום השיווק והפרסום, למטרות עיבוד
                      ואופטימיזציה;
                    </li>
                    <li>
                      העברות בעלות עסקיות, מיזוגים וכיוצא בזה: במקרה של מיזוג,
                      רכישה או העברה של נכסים, המידע האישי שלך עשוי להיות מועבר
                      לצד שלישי כחלק מהעסקה, בתנאי שהתאגיד הנעבר יקבל על עצמו את
                      הוראות מדיניות פרטיות זו;
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    עוגיות (Cookies)
                  </h2>
                  <div className="[&>p]:!mb-1">
                    <p>
                      עוגייה (קובץ "Cookie") היא קוד אשר משמש לאימות, מעקב אחר
                      נתוני גלישה וכן לשמירת מידע על אודות המשתמשים. כל עוגייה
                      נוצרת על ידי שרת, ומועברת לדפדפן של המשתמש; הדפדפן שלך הוא
                      זה ששומר עוגיות בזיכרון של המחשב או המכשיר שלך.
                    </p>
                    <p>
                      המערכת משתמש בעוגיות לצורך תפעולו השוטף והתקין, ובכלל זה
                      כדי לאסוף נתונים סטטיסטיים על המשתמש ביחס לשימוש במערכת,
                      לאימות פרטים, כדי להתאים את המערכת להעדפותיך האישיות
                      ולצורכי אבטחת מידע.
                    </p>
                    <p>
                      דפדפנים מודרניים כוללים אפשרות להימנע מקבלת עוגיות וכן
                      למחוק את העוגיות הקיימות. אם אינך יודע כיצד לעשות זאת,
                      בדוק בקובץ העזרה של הדפדפן שבו אתה משתמש.
                    </p>
                  </div>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    פרסומות של צדדים שלישיים
                  </h2>
                  <p>
                    בעל המערכת מתיר לחברות אחרות לנהל את מערך הפרסומות במערכת.
                    המודעות שבהן אתה צופה בעת השימוש מגיעות ממחשביהן של אותן
                    חברות.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    אבטחת מידע
                  </h2>
                  <p>
                    במערכת מיושמות מערכות ונהלים עדכניים לאבטחת מידע. בעוד
                    שמערכות ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי-מורשית,
                    אין הם מעניקים בטחון מוחלט. לכן, בעל המערכת לא מתחייב
                    שהמערכת ושירותיו יהיו חסינים באופן מוחלט מפני גישה
                    בלתי-מורשית למידע המאוחסן בהם והמשתמש מצהיר כי הוא מודע
                    ומסכים לכך.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    זכויות נשוא המידע
                  </h2>
                  <div className="[&>p]:!mb-1">
                    <p>
                      על-פי חוק הגנת הפרטיות, התשמ"א - 1981, כל אדם זכאי לעיין
                      במידע על אודותיו, המוחזק במאגר מידע. אדם שעיין במידע שעליו
                      ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל
                      מאגר המידע בבקשה לתקן את המידע או למוחקו.
                    </p>
                    <div className="my-8 italic pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                      פנייה כזאת יש להפנות אל בעל המערכת בדואר אלקטרוני:
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                    </div>
                    <p>
                      בנוסף, אם המידע שבמאגרי החברה משמש לצורך פניה אישית אליך,
                      אתה זכאי על-פי חוק הגנת הפרטיות, התשמ"א- 1981 לדרוש בכתב
                      שהמידע המתייחס אליך יימחק ממאגר המידע.
                    </p>
                  </div>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    שינויים במדיניות הפרטיות
                  </h2>
                  <p>
                    אנו נסקור ונעדכן באופן קבוע את מדיניות הפרטיות שלנו כדי
                    להבטיח שהיא משקפת את נהלי הטיפול הנוכחיים שלנו בנתונים. כל
                    העדכונים יימסרו לך בבירור, ואנו נקבל את הסכמתך ככל ויידרש על
                    פי דין.
                  </p>
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    דיווח על פגיעה בפרטיות
                  </h2>
                  <div className="[&>p]:!mb-1">
                    <p>
                      אם אתה סבור כי פרטיותך נפגעה במסגרת או בזיקה לפעילות
                      המערכת, אנא פנה לבעל המערכת ופרט בפנייתך את נסיבות הפגיעה
                      כפי שאתה רואה אותה.
                    </p>
                    <p>
                      כמו כן, אם יש לך שאלות או חששות בנוגע לעיבוד המידע האישי
                      שלך או שתרצה לממש את זכויותיך, בעל המערכת יענה תוך זמן
                      סביר לפניותיך ויספק לך את המידע הדרוש לך.
                    </p>
                    <div className="my-8 italic pr-4 text-lg font-medium border-r-2 border-r-primaryBlue">
                      אתה מוזמן לפנות לבעל המערכת באמצעות כתובת הדואר האלקטרוני:
                      <Link
                        to={`mailto:${socials.email}`}
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        {socials.email}
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PrivacyHE;
