import * as Actions from "./types";

const initialState = {
  isLoading: false,
  isOpen: false,
  message: "",
  subscription: {},
};
const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SAVE_SUBSCRIPTIONS: {
      const subscription = action.payload;
      // const subscription = { ...action.payload, role: "Manager" };
      return {
        ...state,
        subscription,
      };
    }
    case Actions.OPEN_MODAL:
      return { ...state, isOpen: true, ...action.payload };
    case Actions.CLOSE_MODAL:
      return { ...state, isOpen: false, message: "" };
    default:
      return state;
  }
};

export default permissionReducer;
