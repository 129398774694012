import React from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import Section from "../../../layouts/Section";
import useProfile from "./hook/useProfile";
import { Input, Select } from "antd";
import countries from "../../../data/countries";
import usePermissionHandler from "../hooks/handleActionWithPermission";
const { Option } = Select;

const Profile = () => {
  const { submitProfile, user, loading, form, formHandler } = useProfile();
  const { hiddenElements } = usePermissionHandler();
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle title={"Profile"} subTitle={"Manage your profile"} />
        {user.isAdmin ? (
          <Button
            text="Save changes"
            disabled={!user.isAdmin}
            loading={loading}
            onClick={submitProfile}
            className="!px-4 !max-md:px-5"
          />
        ) : null}
      </div>
      <form>
        <div className="space-y-4">
          <div className="pb-4 mb-4 border-b">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>
            <p className="text-sm leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>
          <div className="border-b border-gray-900/10 pb-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    disabled
                    value={user?.fullName}
                    className="block w-full rounded-md cursor-not-allowed px-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryBlue sm:text-sm sm:leading-6"
                  />
                </div>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Your name is automatically synced from your email account
                </span>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    disabled
                    value={user?.email}
                    className="block w-full rounded-md cursor-not-allowed border-0 py-1.5 text-gray-900 shadow-sm ring-1 px-2 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryBlue sm:text-sm sm:leading-6"
                  />
                </div>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Your email address cannot be changed directly. If you need to
                  update it, please contact support for assistance.
                </span>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="organization"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Organization
                </label>
                <div className="mt-2">
                  <Input
                    id="organization"
                    name="organization"
                    type="organization"
                    disabled={!user.isAdmin}
                    value={form.organization}
                    onChange={(e) =>
                      formHandler("organization", e.target.value)
                    }
                    required={user.isAdmin}
                    className="w-full rounded-lg py-2"
                  />
                </div>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  You can enter your organization's name, which will appear on
                  your future invoices.
                </span>
              </div>
              {hiddenElements.addressInfo ? (
                <>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Country
                    </label>
                    <div className="mt-2">
                      <Select
                        className="w-full"
                        size={"large"}
                        placeholder="Select Country"
                        showSearch
                        value={form.country}
                        onChange={(value) => formHandler("country", value)}
                      >
                        {countries.map((country) => (
                          <Option key={country.name} value={country.name}>
                            <div className="flex gap-2">
                              <img
                                src={country.flags.png}
                                className="w-5 object-contain"
                                alt={country.name}
                              />
                              <span>{country.name}</span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <div className="mt-2">
                      <Input
                        id="address"
                        name="address"
                        type="address"
                        value={form.address}
                        onChange={(e) => formHandler("address", e.target.value)}
                        required={user.isAdmin}
                        className="w-full rounded-lg py-2"
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </form>
    </Section>
  );
};

export default Profile;
