
import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { IoCloseOutline } from "react-icons/io5";
import Icon from "../../../../components/ui/Buttons/Icon";
import Button from "../../../../components/Button";
import { MdDeleteOutline } from "react-icons/md";
import useActions from "../hooks/useActions";
import { CreateTeamForm } from "../../../../utils/types";

const DeleteProfileModal = ({
  showModal,
  dismiss,
  submit,
  loading,
}: {
  showModal: boolean;
  dismiss: () => void;
  submit: Function;
  loading: boolean;
}) => {
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-lg">{"Deactivate Profile"}</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        All values associated with this field will be lost.
      </p>
      <div className="flex gap-2 items-center justify-end mt-8 text-xl text-center whitespace-nowrap">
        <Button
          text={"Cancel"}
          disabled={loading}
          variant="secondary"
          onClick={() => dismiss()}
          className="justify-center items-center !py-2"
        />
        <Button
          text={"Deactivate"}
          type={"submit"}
          loading={loading}
          variant={"danger"}
          onClick={() => submit()}
          className="justify-center items-center !px-12 !py-2 !max-md:px-5"
        />
      </div>
    </Modal>
  );
};

const DeleteProfile = ({
  teamId,
  fetchTeams,
}: {
  teamId: string;
  fetchTeams: Function;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { Delete } = useActions();
  const RemoveTeam = (form: CreateTeamForm) => {
    setIsSubmitting(true);
    Delete(teamId, (close) => {
      fetchTeams();
      setIsSubmitting(false);
      if (close) {
        setModalVisible(false);
      }
    });
  };
  return (
    <>
      {/* <Tooltip title="Delete Profile"> */}
      <div
        className="flex gap-2 items-center"
        onClick={(e) => {
          e?.stopPropagation();
          setModalVisible(true);
        }}
      >
        {/* <Icon> */}
        <MdDeleteOutline color={"red"} size={18} />
        <span>Deactivate Domain</span>
      </div>
      {/* </Tooltip> */}
      <DeleteProfileModal
        showModal={modalVisible}
        loading={isSubmitting}
        dismiss={() => {
          setModalVisible(false);
        }}
        submit={RemoveTeam}
      />
    </>
  );
};

export default DeleteProfile;
