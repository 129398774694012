import { useEffect } from "react";
import Event from "../../../utils/event";

const useUnauthorizedHandler = (
  logoutAction: () => (dispatch: any) => Promise<void>
) => {
  useEffect(() => {
    const handleUnauthorizedError = () => {
      logoutAction();
    };
    // TODO
    // const lockOverlay = document.getElementById("Dashboard");
    // if (lockOverlay) {
    //   document.addEventListener("contextmenu", (e) => e.preventDefault());
    //   document.addEventListener("keydown", (e) => {
    //     if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
    //       e.preventDefault();
    //     }
    //   });
    // }
    Event.on("unauthorizedError", handleUnauthorizedError);
    return () => {
      Event.removeListener("unauthorizedError", handleUnauthorizedError);
    };
  }, [logoutAction]);
};

export default useUnauthorizedHandler;
