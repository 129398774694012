import React from "react";
import Section from "../layouts/Section";
import ArrowIcon from "../components/ui/Buttons/ArrowIcon";
import { GrHome } from "react-icons/gr";
import Icon from "../components/ui/Buttons/Icon";
import Button from "../components/Button";
import { Link, useNavigate } from "react-router-dom";
import SEO from "../layouts/SEO";
import { LuLogIn } from "react-icons/lu";
import Footer from "../layouts/Footer";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Section id="notfound">
        <SEO title={"Not Found | InterSight"} />
        <div className="flex flex-col px-8 lg:px-20 items-center justify-center">
          {/* <!-- Error Container --> */}
          <div className="flex flex-col items-center text-center">
            <div className="text-primaryBlue font-bold text-7xl">404</div>
            <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
              This page does not exist
            </div>
            <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
              The page you are looking for could not be found.
            </div>
            <Button
              text={"Go Home"}
              onClick={() => navigate("/")}
              className="justify-center items-center !px-12 !py-3 mt-8 !font-black text-white bg-primaryBlue !max-md:px-5"
            />
          </div>
          {/* <!-- Continue With --> */}
          <div className="flex flex-col mt-24 w-full lg:w-[80%]">
            <div className="text-gray-400 font-bold uppercase">
              Continue With
            </div>
            <div className="flex flex-col items-stretch mt-5">
              {/* <!-- Nav Item #1 --> */}
              <Link
                to="/"
                replace={true}
                className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
              >
                {/* <!-- Nav Icon --> */}
                <Icon>
                  <GrHome />
                </Icon>
                {/* <!-- Text --> */}
                <div className="grow flex flex-col pl-5">
                  <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                    Home Page
                  </div>
                  <div className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                    Everything starts here
                  </div>
                </div>
                {/* <!-- Chevron --> */}
                <ArrowIcon />
              </Link>
              {/* <!-- Nav Item #3 --> */}
              <Link
                to="/get-started/Login"
                replace={true}
                className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
              >
                {/* <!-- Nav Icon --> */}
                <Icon>
                  <LuLogIn />
                </Icon>
                {/* <!-- Text --> */}
                <div className="grow flex flex-col pl-5">
                  <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                    Connect
                  </div>
                  <div className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                    Connect to your account
                  </div>
                </div>
                {/* <!-- Chevron --> */}
                <ArrowIcon />
              </Link>
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
};

export default NotFound;
