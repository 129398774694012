import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { posts } from "../Resources/data";
import { FaArrowLeftLong } from "react-icons/fa6";
import GetStart from "../../components/GetStart";

const Article = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
  const articleData = posts.find((article) => article.id === id);
  const { htmlData, title, image } = articleData || {};
  return (
    <Section
      id={`${title} - Intersight` || "article Intersight"}
      className="lg:mt-[6rem] mt-[4rem]"
    >
      <SEO title={`${title} - Intersight` || "article Intersight"} />
      <div className="prose max-w-4xl mx-auto p-6 flex flex-col gap-8">
        <div
          className="flex items-center  h-8 w-full gap-2 cursor-pointer transition-all duration-300 ease-in-out group"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeftLong className="text-primaryBlue group-hover:mr-1 group-hover:text-black transition-all duration-300 ease-in-out" />
          <span className="hidden group-hover:opacity-1 group-hover:block transition-opacity duration-300 ease-in-out">
            back
          </span>
        </div>
        <img
          src={image}
          className="w-full aspect-video object-cover rounded-xl"
          alt={title}
        />
        {/* <div className="flex flex-wrap items-center justify-between">
          <div className="relative mt-8 flex items-center gap-x-4">
            <img
              loading="lazy"
              alt={author?.name}
              src={author?.imageUrl}
              className="h-10 w-10 rounded-full bg-gray-50"
            />
            <div className="text-sm leading-6 flex flex-col">
              <span className="font-semibold text-gray-900">
                {author?.name}
              </span>
              <span className="text-gray-600">{author?.role}</span>
            </div>
          </div>
          <div className="flex items-center gap-x-4 text-xs">
            <time dateTime={datetime}>{date}</time>
            <span className="rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
              {category?.title}
            </span>
          </div>
        </div> */}
        <h1 className="text-2xl font-semibold leading-6">
          {title}
          {/* <a href={href}>{title}</a> */}
        </h1>
        {htmlData ? (
          <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        ) : null}
        <GetStart className="w-full" />
      </div>
    </Section>
  );
};

export default Article;
